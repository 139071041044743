import {useApolloClient} from "@apollo/client";

const Profile = () => {
    const cache = useApolloClient().cache

    console.log(cache)

    return(
        <div>
            <button onClick={() => {
                cache.cache.clear()
            }}>clean cache</button>
            <button onClick={() => {
                cache.cache.clear()
                localStorage.clear()
            }}>log out</button>
        </div>
    )
}

export default Profile