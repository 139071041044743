import {CCButton} from "./CreateConnectGroup";
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import {IconSquare} from "../../components/Base/IconComponents";


const Header = styled('h1', {name: 'modalHeader'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#0C0C13'
}))

const SubHeaderText = styled('p', {name: 'dialog-text'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',

    color: 'rgba(22, 22, 51, 0.54)'
}))

const TmpItem = styled(({className, name, sub, children, style, action}) => (
    <div className={className} onClick={action}>
        <IconSquare style={{
            ...style,
            padding: 8,
            borderRadius: 12,
        }}>
            {children}
        </IconSquare>
        <div>
            <p className={'name'}>{name}</p>
            <p className={'sub-name'}>{sub}</p>
        </div>
        <div className={'next-icon'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 13L16.2 13L10.6 18.6L12 20L20 12L12 4L10.6 5.4L16.2 11L4 11L4 13Z" fill="#161633" fillOpacity="0.82"/>
            </svg>
        </div>
    </div>
), {name: 'tmp-item'})(() => ({
    padding: '16px 12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    background: 'rgba(72, 72, 141, 0.06)',
    borderRadius: '16px',
    justifyContent: 'space-between',

    '& .name': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '16px',

        color: '#0C0C13',
        margin: 0,
    },
    '& .sub-name': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',

        color: 'rgba(22, 22, 51, 0.54)',
        margin: 0,
        marginTop: '5px',
    },
    '& .next-icon': {}
}))

const CreateDialog = () => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <CCButton className={`btn-login color`}
                      onClick={handleClickOpen}
            >
                <span className="btn-login--txt">{t('Создать рабочую группу')}</span>
            </CCButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    background: 'rgba(0, 0, 0, 0.6)'
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '18px',
                        width: '416px',
                    }
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        background: 'rgba(233, 233, 255, 0.6)',
                    }}
                >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.70089 0.874956C1.47308 0.64715 1.10374 0.64715 0.875932 0.874956C0.648127 1.10276 0.648127 1.47211 0.875933 1.69991L4.17576 4.99975L0.875932 8.29958C0.648127 8.52738 0.648127 8.89673 0.875933 9.12454C1.10374 9.35234 1.47308 9.35234 1.70089 9.12454L5.00072 5.8247L8.30055 9.12454C8.52836 9.35234 8.89771 9.35234 9.12551 9.12454C9.35332 8.89673 9.35332 8.52738 9.12551 8.29958L5.82568 4.99975L9.12551 1.69991C9.35332 1.47211 9.35332 1.10276 9.12551 0.874957C8.89771 0.647151 8.52836 0.647151 8.30055 0.874957L5.00072 4.17479L1.70089 0.874956Z"
                              fill="#161633" fillOpacity="0.82"/>
                    </svg>
                </IconButton>
                <DialogContent sx={{padding: '8px'}}>
                    <Header>Создайте пустую группу или выберите один из шаблонов</Header>
                    <SubHeaderText>Вы сможете отредактировать выбранный шаблон вручную под ваши потребности в любое время.</SubHeaderText>
                    <br/>
                    <br/>
                    <div style={{ display: 'flex', gap: '8px', flexDirection: 'column'}}>
                        <TmpItem name={'Создать пустую рабочую группу'}
                                 sub={'Новая группа с базовыми настройками'}
                                 style={{
                                     background: 'rgba(96, 102, 255, 0.6)',
                                 }}
                                 action={() => {
                                 }}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M8 16C8.55228 16 9 15.5523 9 15L9 9L15 9C15.5523 9 16 8.55228 16 8C16 7.44771 15.5523 7 15 7L9 7L9 0.999999C9 0.447714 8.55229 -6.511e-07 8 -6.99382e-07C7.44772 -7.47665e-07 7 0.447714 7 0.999999L7 7L1 7C0.447716 7 7.47665e-07 7.44771 6.99382e-07 8C6.511e-07 8.55228 0.447715 9 1 9L7 9L7 15C7 15.5523 7.44771 16 8 16Z"
                                      fill="white"/>
                            </svg>
                        </TmpItem>
                        <div style={{margin: 0, height: 8}}/>
                        <TmpItem name={'Автосервис'}
                                 sub={'Пресет с колонками, тегами и статусами'}
                                 style={{
                                     background: 'background: rgba(0, 0, 0, 0.04);',
                                     border: '1px solid rgba(0, 0, 0, 0.04)',
                                 }}
                                 action={() => {
                                 }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_i_856_126576)">
                                    <path d="M11.2311 7.5H18.4761C19.4961 7.5 20.4111 8.1075 20.8161 9.03C20.8172 9.0334 20.8183 9.03679 20.8195 9.04018C20.8362 9.07703 20.8594 9.12993 20.8686 9.1575L22.0986 12.435C22.2248 12.7766 22.3217 13.133 22.3818 13.4895C22.3849 13.493 22.388 13.4965 22.3911 13.5C22.4586 13.8675 22.4961 14.25 22.4961 14.6325V18H1.49609L1.5936 15C1.87109 14.13 2.68861 13.5 3.6561 13.5H5.98108L9.25858 8.6175C9.7011 7.9575 10.4361 7.53 11.2311 7.5Z" fill="url(#paint0_linear_856_126576)"/>
                                </g>
                                <path d="M11.2311 7.5H18.4761C19.4961 7.5 20.4111 8.1075 20.8161 9.03C20.8172 9.0334 20.8183 9.03679 20.8195 9.04018C20.8362 9.07703 20.8594 9.12993 20.8686 9.1575L22.0986 12.435C22.2248 12.7766 22.3217 13.133 22.3818 13.4895C22.3849 13.493 22.388 13.4965 22.3911 13.5C22.4586 13.8675 22.4961 14.25 22.4961 14.6325V18H1.49609L1.5936 15C1.87109 14.13 2.68861 13.5 3.6561 13.5H5.98108L9.25858 8.6175C9.7011 7.9575 10.4361 7.53 11.2311 7.5Z" fill="url(#paint1_radial_856_126576)"/>
                                <g filter="url(#filter1_f_856_126576)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.51758 13.3836C7.52114 13.1645 7.57027 12.6714 7.88638 12.4106C8.09041 12.2423 8.41431 12.2049 8.67978 12.2261C8.90112 12.2438 9.03481 12.4407 9.03481 12.6559V13.3836V14.1113C9.03481 14.3266 8.90112 14.5235 8.67978 14.5412C8.41431 14.5624 8.09041 14.525 7.88638 14.3566C7.57027 14.0958 7.52114 13.6027 7.51758 13.3836Z" fill="#94154B"/>
                                </g>
                                <path d="M8.99694 9L5.97461 13.5H11.2694C11.6669 13.5 11.9894 13.1775 11.9894 12.78V9.72C11.9894 9.3225 11.6669 9 11.2694 9H8.99694Z" fill="url(#paint2_linear_856_126576)"/>
                                <path d="M8.99694 9L5.97461 13.5H11.2694C11.6669 13.5 11.9894 13.1775 11.9894 12.78V9.72C11.9894 9.3225 11.6669 9 11.2694 9H8.99694Z" fill="url(#paint3_linear_856_126576)"/>
                                <path d="M8.99694 9L5.97461 13.5H11.2694C11.6669 13.5 11.9894 13.1775 11.9894 12.78V9.72C11.9894 9.3225 11.6669 9 11.2694 9H8.99694Z" fill="url(#paint4_linear_856_126576)"/>
                                <path d="M8.99694 9L5.97461 13.5H11.2694C11.6669 13.5 11.9894 13.1775 11.9894 12.78V9.72C11.9894 9.3225 11.6669 9 11.2694 9H8.99694Z" fill="url(#paint5_linear_856_126576)"/>
                                <path d="M21.4536 19.5H2.53859C1.96109 19.5 1.49609 19.035 1.49609 18.4575V18H22.4961V18.4575C22.4961 19.035 22.0311 19.5 21.4536 19.5Z" fill="#9989AA"/>
                                <path d="M21.4536 19.5H2.53859C1.96109 19.5 1.49609 19.035 1.49609 18.4575V18H22.4961V18.4575C22.4961 19.035 22.0311 19.5 21.4536 19.5Z" fill="url(#paint6_linear_856_126576)"/>
                                <path d="M21.4536 19.5H2.53859C1.96109 19.5 1.49609 19.035 1.49609 18.4575V18H22.4961V18.4575C22.4961 19.035 22.0311 19.5 21.4536 19.5Z" fill="url(#paint7_linear_856_126576)"/>
                                <path d="M21.4536 19.5H2.53859C1.96109 19.5 1.49609 19.035 1.49609 18.4575V18H22.4961V18.4575C22.4961 19.035 22.0311 19.5 21.4536 19.5Z" fill="url(#paint8_linear_856_126576)"/>
                                <g filter="url(#filter2_i_856_126576)">
                                    <path d="M17.2539 13.5H14.2239C13.8264 13.5 13.5039 13.1775 13.5039 12.78V9.72C13.5039 9.3225 13.8264 9 14.2239 9H17.2539C17.6514 9 17.9739 9.3225 17.9739 9.72V12.78C17.9739 13.1775 17.6514 13.5 17.2539 13.5Z" fill="url(#paint9_linear_856_126576)"/>
                                </g>
                                <path d="M22.098 12.435L20.868 9.1575C20.853 9.1125 20.8005 9 20.8005 9H20.2605C19.833 9 19.4805 9.3525 19.4805 9.78V12.78C19.4805 13.1775 19.803 13.5 20.2005 13.5H22.383C22.323 13.14 22.2255 12.78 22.098 12.435Z" fill="url(#paint10_linear_856_126576)"/>
                                <path d="M22.098 12.435L20.868 9.1575C20.853 9.1125 20.8005 9 20.8005 9H20.2605C19.833 9 19.4805 9.3525 19.4805 9.78V12.78C19.4805 13.1775 19.803 13.5 20.2005 13.5H22.383C22.323 13.14 22.2255 12.78 22.098 12.435Z" fill="url(#paint11_linear_856_126576)"/>
                                <path d="M22.098 12.435L20.868 9.1575C20.853 9.1125 20.8005 9 20.8005 9H20.2605C19.833 9 19.4805 9.3525 19.4805 9.78V12.78C19.4805 13.1775 19.803 13.5 20.2005 13.5H22.383C22.323 13.14 22.2255 12.78 22.098 12.435Z" fill="url(#paint12_linear_856_126576)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.2366 19.5C9.25957 19.353 9.27148 19.2023 9.27148 19.0488C9.27148 17.447 7.97294 16.1484 6.37109 16.1484C4.76925 16.1484 3.4707 17.447 3.4707 19.0488C3.4707 19.2023 3.48262 19.353 3.50559 19.5H9.2366Z" fill="url(#paint13_linear_856_126576)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.4866 19.5C20.5096 19.353 20.5215 19.2023 20.5215 19.0488C20.5215 17.447 19.2229 16.1484 17.6211 16.1484C16.0193 16.1484 14.7207 17.447 14.7207 19.0488C14.7207 19.2023 14.7326 19.353 14.7556 19.5H20.4866Z" fill="url(#paint14_linear_856_126576)"/>
                                <path d="M2.98859 16.5V15.75C2.98859 15.3375 2.65109 15 2.23859 15H1.60109C1.52609 15.2175 1.49609 15.45 1.49609 15.9525V17.25H2.23859C2.65109 17.25 2.98859 16.9125 2.98859 16.5Z" fill="#F7DA4C"/>
                                <g filter="url(#filter3_ii_856_126576)">
                                    <path d="M17.6211 21.75C19.0708 21.75 20.2461 20.5747 20.2461 19.125C20.2461 17.6753 19.0708 16.5 17.6211 16.5C16.1713 16.5 14.9961 17.6753 14.9961 19.125C14.9961 20.5747 16.1713 21.75 17.6211 21.75Z" fill="url(#paint15_linear_856_126576)"/>
                                    <path d="M17.6211 21.75C19.0708 21.75 20.2461 20.5747 20.2461 19.125C20.2461 17.6753 19.0708 16.5 17.6211 16.5C16.1713 16.5 14.9961 17.6753 14.9961 19.125C14.9961 20.5747 16.1713 21.75 17.6211 21.75Z" fill="url(#paint16_radial_856_126576)"/>
                                </g>
                                <g filter="url(#filter4_ii_856_126576)">
                                    <path d="M17.6211 20.25C18.2424 20.25 18.7461 19.7463 18.7461 19.125C18.7461 18.5037 18.2424 18 17.6211 18C16.9998 18 16.4961 18.5037 16.4961 19.125C16.4961 19.7463 16.9998 20.25 17.6211 20.25Z" fill="#D9C3F0"/>
                                </g>
                                <path d="M22.4895 15H22.1145C21.477 15 20.9595 15.5325 20.9895 16.1775C21.0195 16.785 21.5445 17.25 22.152 17.25H22.4895V15Z" fill="url(#paint17_radial_856_126576)"/>
                                <g filter="url(#filter5_f_856_126576)">
                                    <circle cx="17.8184" cy="18.8945" r="1.85156" stroke="url(#paint18_linear_856_126576)" strokeWidth="0.85"/>
                                </g>
                                <g filter="url(#filter6_ii_856_126576)">
                                    <path d="M6.38672 21.75C7.83647 21.75 9.01172 20.5747 9.01172 19.125C9.01172 17.6753 7.83647 16.5 6.38672 16.5C4.93697 16.5 3.76172 17.6753 3.76172 19.125C3.76172 20.5747 4.93697 21.75 6.38672 21.75Z" fill="url(#paint19_linear_856_126576)"/>
                                    <path d="M6.38672 21.75C7.83647 21.75 9.01172 20.5747 9.01172 19.125C9.01172 17.6753 7.83647 16.5 6.38672 16.5C4.93697 16.5 3.76172 17.6753 3.76172 19.125C3.76172 20.5747 4.93697 21.75 6.38672 21.75Z" fill="url(#paint20_radial_856_126576)"/>
                                </g>
                                <g filter="url(#filter7_ii_856_126576)">
                                    <path d="M6.37109 20.25C6.99241 20.25 7.49609 19.7463 7.49609 19.125C7.49609 18.5037 6.99241 18 6.37109 18C5.74977 18 5.24609 18.5037 5.24609 19.125C5.24609 19.7463 5.74977 20.25 6.37109 20.25Z" fill="#D9C3F0"/>
                                </g>
                                <g filter="url(#filter8_f_856_126576)">
                                    <circle cx="6.52148" cy="18.8945" r="1.85156" stroke="url(#paint21_linear_856_126576)" strokeWidth="0.85"/>
                                </g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.61328 13.12C7.61685 12.9009 7.66597 12.4078 7.98208 12.1469C8.18611 11.9786 8.51001 11.9412 8.77548 11.9624C8.99683 11.9801 9.13052 12.177 9.13052 12.3923V13.12V13.8476C9.13052 14.0629 8.99683 14.2598 8.77548 14.2775C8.51001 14.2987 8.18611 14.2613 7.98208 14.093C7.66597 13.8321 7.61685 13.339 7.61328 13.12Z" fill="url(#paint22_radial_856_126576)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.61328 13.12C7.61685 12.9009 7.66597 12.4078 7.98208 12.1469C8.18611 11.9786 8.51001 11.9412 8.77548 11.9624C8.99683 11.9801 9.13052 12.177 9.13052 12.3923V13.12V13.8476C9.13052 14.0629 8.99683 14.2598 8.77548 14.2775C8.51001 14.2987 8.18611 14.2613 7.98208 14.093C7.66597 13.8321 7.61685 13.339 7.61328 13.12Z" fill="url(#paint23_linear_856_126576)"/>
                                <g filter="url(#filter9_f_856_126576)">
                                    <path d="M8.91541 12.1851V13.9605C8.37637 13.5387 8.26111 12.5797 8.27673 12.2262C8.49705 12.1465 8.79431 12.1656 8.91541 12.1851Z" fill="url(#paint24_radial_856_126576)"/>
                                </g>
                                <defs>
                                    <filter id="filter0_i_856_126576" x="0.996094" y="7.5" width="21.5" height="10.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.5"/>
                                        <feGaussianBlur stdDeviation="0.25"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.137255 0 0 0 0 0.192157 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter1_f_856_126576" x="7.11758" y="11.8207" width="2.31758" height="3.12617" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_856_126576"/>
                                    </filter>
                                    <filter id="filter2_i_856_126576" x="12.7539" y="9" width="5.2207" height="5.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.75" dy="1"/>
                                        <feGaussianBlur stdDeviation="0.5"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.168627 0 0 0 0 0.45098 0 0 0 0 0.964706 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter3_ii_856_126576" x="14.9961" y="16.5" width="5.25" height="5.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="0.5"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.0823529 0 0 0 0 0.215686 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="0.15"/>
                                        <feGaussianBlur stdDeviation="0.125"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126576" result="effect2_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter4_ii_856_126576" x="16.4461" y="17.95" width="2.35" height="2.35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.05" dy="-0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.478431 0 0 0 0 0.364706 0 0 0 0 0.584314 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.05" dy="0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.807843 0 0 0 0 0.764706 0 0 0 0 0.858824 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126576" result="effect2_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter5_f_856_126576" x="14.791" y="15.8682" width="6.05469" height="6.05273" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_856_126576"/>
                                    </filter>
                                    <filter id="filter6_ii_856_126576" x="3.76172" y="16.5" width="5.25" height="5.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="0.5"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.0823529 0 0 0 0 0.215686 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="0.15"/>
                                        <feGaussianBlur stdDeviation="0.125"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126576" result="effect2_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter7_ii_856_126576" x="5.19609" y="17.95" width="2.35" height="2.35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.05" dy="-0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.478431 0 0 0 0 0.364706 0 0 0 0 0.584314 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126576"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.05" dy="0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.807843 0 0 0 0 0.764706 0 0 0 0 0.858824 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126576" result="effect2_innerShadow_856_126576"/>
                                    </filter>
                                    <filter id="filter8_f_856_126576" x="3.49414" y="15.8682" width="6.05469" height="6.05273" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_856_126576"/>
                                    </filter>
                                    <filter id="filter9_f_856_126576" x="8.07539" y="11.968" width="1.04063" height="2.19297" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur_856_126576"/>
                                    </filter>
                                    <linearGradient id="paint0_linear_856_126576" x1="1.93665" y1="13.879" x2="12.9523" y2="13.879" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#DF378A"/>
                                        <stop offset="1" stopColor="#ED334E"/>
                                    </linearGradient>
                                    <radialGradient id="paint1_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5222 8.10402) rotate(136.332) scale(1.63604 2.87677)">
                                        <stop stopColor="#FF4D5A"/>
                                        <stop offset="1" stopColor="#FF4D5A" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint2_linear_856_126576" x1="11.0312" y1="10.0821" x2="6.85938" y2="13.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#3398F0"/>
                                        <stop offset="1" stopColor="#57E8FF"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_856_126576" x1="11.9894" y1="12.8946" x2="10.6094" y2="12.8946" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#2372D7"/>
                                        <stop offset="1" stopColor="#2372D7" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_856_126576" x1="10.6094" y1="8.58211" x2="10.6094" y2="12.9415" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.225806" stopColor="#2372D7"/>
                                        <stop offset="1" stopColor="#2372D7" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint5_linear_856_126576" x1="5.97461" y1="13.5" x2="8.98203" y2="13.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#5EEEFF"/>
                                        <stop offset="0.652683" stopColor="#40ACF4"/>
                                        <stop offset="1" stopColor="#40ACF4" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint6_linear_856_126576" x1="1.49609" y1="18.75" x2="2.16346" y2="18.75" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#847F89"/>
                                        <stop offset="1" stopColor="#847F89" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint7_linear_856_126576" x1="22.4961" y1="18.75" x2="21.3225" y2="18.75" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#847F89"/>
                                        <stop offset="1" stopColor="#847F89" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint8_linear_856_126576" x1="11.9961" y1="19.5" x2="11.9961" y2="18.8712" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#734995"/>
                                        <stop offset="1" stopColor="#734995" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint9_linear_856_126576" x1="16.4682" y1="10.4571" x2="13.5039" y2="13.9259" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#40A4FF"/>
                                        <stop offset="1" stopColor="#5BF6FF"/>
                                    </linearGradient>
                                    <linearGradient id="paint10_linear_856_126576" x1="21.5298" y1="11.25" x2="19.2329" y2="13.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#3DA7FF"/>
                                        <stop offset="1" stopColor="#4CF0F8"/>
                                    </linearGradient>
                                    <linearGradient id="paint11_linear_856_126576" x1="19.8071" y1="9.41414" x2="20.3228" y2="9.98836" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#2588DA"/>
                                        <stop offset="1" stopColor="#2588DA" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint12_linear_856_126576" x1="22.6782" y1="13.5" x2="21.811" y2="12.6837" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#2588DA"/>
                                        <stop offset="1" stopColor="#2588DA" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint13_linear_856_126576" x1="6.37109" y1="16.1484" x2="6.37109" y2="19.4034" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#64023B"/>
                                        <stop offset="1" stopColor="#7E0B55"/>
                                    </linearGradient>
                                    <linearGradient id="paint14_linear_856_126576" x1="17.6211" y1="16.1484" x2="17.6211" y2="19.4034" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#64023B"/>
                                        <stop offset="1" stopColor="#7E0B55"/>
                                    </linearGradient>
                                    <linearGradient id="paint15_linear_856_126576" x1="16.4107" y1="18.4649" x2="20.3535" y2="18.4649" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.239044" stopColor="#34214F"/>
                                        <stop offset="1" stopColor="#241337"/>
                                    </linearGradient>
                                    <radialGradient id="paint16_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.6211 18.8901) rotate(-65.5366) scale(1.43227 1.49596)">
                                        <stop offset="0.523134" stopColor="#2D114D"/>
                                        <stop offset="1" stopColor="#2B1249" stopOpacity="0"/>
                                    </radialGradient>
                                    <radialGradient id="paint17_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.4895 17.002) rotate(-120.532) scale(1.95898)">
                                        <stop offset="0.418213" stopColor="#D32374"/>
                                        <stop offset="1" stopColor="#B60160"/>
                                    </radialGradient>
                                    <linearGradient id="paint18_linear_856_126576" x1="18.8272" y1="17.4255" x2="16.5777" y2="20.5319" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.0830122" stopColor="#615B6B"/>
                                        <stop offset="0.390771" stopColor="#615B6B" stopOpacity="0"/>
                                        <stop offset="0.692394" stopColor="#615B6B" stopOpacity="0"/>
                                        <stop offset="0.934982" stopColor="#615B6B"/>
                                    </linearGradient>
                                    <linearGradient id="paint19_linear_856_126576" x1="5.17629" y1="18.4649" x2="9.11911" y2="18.4649" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.239044" stopColor="#34214F"/>
                                        <stop offset="1" stopColor="#241337"/>
                                    </linearGradient>
                                    <radialGradient id="paint20_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.38672 18.8901) rotate(-65.5366) scale(1.43227 1.49596)">
                                        <stop offset="0.523134" stopColor="#2D114D"/>
                                        <stop offset="1" stopColor="#2B1249" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint21_linear_856_126576" x1="7.53028" y1="17.4255" x2="5.28086" y2="20.5319" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.0830122" stopColor="#615B6B"/>
                                        <stop offset="0.390771" stopColor="#615B6B" stopOpacity="0"/>
                                        <stop offset="0.692394" stopColor="#615B6B" stopOpacity="0"/>
                                        <stop offset="0.934982" stopColor="#615B6B"/>
                                    </linearGradient>
                                    <radialGradient id="paint22_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.23511 13.82) rotate(-46.2263) scale(1.35514 3.61697)">
                                        <stop stopColor="#3A2557"/>
                                        <stop offset="1" stopColor="#281F4A"/>
                                    </radialGradient>
                                    <linearGradient id="paint23_linear_856_126576" x1="8.8933" y1="13.3028" x2="9.13052" y2="13.3028" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#281F4A" stopOpacity="0"/>
                                        <stop offset="1" stopColor="#281F4A"/>
                                    </linearGradient>
                                    <radialGradient id="paint24_radial_856_126576" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.91541 12.2789) rotate(90) scale(1.68164 0.618167)">
                                        <stop stopColor="#5E5866"/>
                                        <stop offset="1" stopColor="#5E5866" stopOpacity="0"/>
                                    </radialGradient>
                                </defs>
                            </svg>
                        </TmpItem>
                        <TmpItem name={'Агенство недвижимости'}
                                 sub={'Пресет с колонками, тегами и статусами'}
                                 style={{
                                     background: 'background: rgba(0, 0, 0, 0.04);',
                                     border: '1px solid rgba(0, 0, 0, 0.04)',
                                 }}
                                 action={() => {
                                 }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.56836 3.40859C4.56836 3.21529 4.72506 3.05859 4.91836 3.05859H7.25488C7.44818 3.05859 7.60488 3.21529 7.60488 3.40859V11.0481H4.56836V3.40859Z" fill="url(#paint0_radial_856_126584)"/>
                                <path d="M4.56836 3.40859C4.56836 3.21529 4.72506 3.05859 4.91836 3.05859H7.25488C7.44818 3.05859 7.60488 3.21529 7.60488 3.40859V11.0481H4.56836V3.40859Z" fill="url(#paint1_linear_856_126584)"/>
                                <path d="M4.56836 3.40859C4.56836 3.21529 4.72506 3.05859 4.91836 3.05859H7.25488C7.44818 3.05859 7.60488 3.21529 7.60488 3.40859V11.0481H4.56836V3.40859Z" fill="url(#paint2_linear_856_126584)"/>
                                <path d="M4.56836 3.40859C4.56836 3.21529 4.72506 3.05859 4.91836 3.05859H7.25488C7.44818 3.05859 7.60488 3.21529 7.60488 3.40859V11.0481H4.56836V3.40859Z" fill="url(#paint3_linear_856_126584)"/>
                                <path d="M4.56836 3.40859C4.56836 3.21529 4.72506 3.05859 4.91836 3.05859H7.25488C7.44818 3.05859 7.60488 3.21529 7.60488 3.40859V11.0481H4.56836V3.40859Z" fill="url(#paint4_radial_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint5_linear_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint6_linear_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint7_radial_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint8_linear_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint9_radial_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint10_linear_856_126584)"/>
                                <path d="M20.7422 21.8691H3.48047V11.7883L12.1113 3.72363L20.7422 11.7883V21.8691Z" fill="url(#paint11_linear_856_126584)"/>
                                <g filter="url(#filter0_f_856_126584)">
                                    <path d="M11.2989 21.8696H6.0918V13.6144C6.0918 12.9854 6.491 12.4688 6.977 12.4688H10.4137C10.8996 12.4688 11.2989 12.9854 11.2989 13.6144V21.8696V21.8696Z" fill="url(#paint12_linear_856_126584)"/>
                                </g>
                                <g filter="url(#filter1_i_856_126584)">
                                    <path d="M17.7363 16.398H14.595C14.2423 16.398 13.9668 16.1115 13.9668 15.7698V12.6283C13.9668 12.2756 14.2534 12 14.595 12H17.7363C18.089 12 18.3645 12.2866 18.3645 12.6283V15.7698C18.3645 16.1225 18.089 16.398 17.7363 16.398Z" fill="url(#paint13_linear_856_126584)"/>
                                    <path d="M17.7363 16.398H14.595C14.2423 16.398 13.9668 16.1115 13.9668 15.7698V12.6283C13.9668 12.2756 14.2534 12 14.595 12H17.7363C18.089 12 18.3645 12.2866 18.3645 12.6283V15.7698C18.3645 16.1225 18.089 16.398 17.7363 16.398Z" fill="url(#paint14_linear_856_126584)"/>
                                </g>
                                <path d="M17.7363 16.398H14.595C14.2423 16.398 13.9668 16.1115 13.9668 15.7698V12.6283C13.9668 12.2756 14.2534 12 14.595 12H17.7363C18.089 12 18.3645 12.2866 18.3645 12.6283V15.7698C18.3645 16.1225 18.089 16.398 17.7363 16.398Z" fill="url(#paint15_linear_856_126584)"/>
                                <path d="M17.7363 16.398H14.595C14.2423 16.398 13.9668 16.1115 13.9668 15.7698V12.6283C13.9668 12.2756 14.2534 12 14.595 12H17.7363C18.089 12 18.3645 12.2866 18.3645 12.6283V15.7698C18.3645 16.1225 18.089 16.398 17.7363 16.398Z" fill="url(#paint16_linear_856_126584)"/>
                                <g filter="url(#filter2_ii_856_126584)">
                                    <path d="M12.1922 20.9234H6.38477V13.0977C6.38477 12.5015 6.83 12.0117 7.37203 12.0117H11.205C11.747 12.0117 12.1922 12.5015 12.1922 13.0977V20.9234V20.9234Z" fill="url(#paint17_linear_856_126584)"/>
                                </g>
                                <g filter="url(#filter3_f_856_126584)">
                                    <path d="M10.7384 16.7043C10.9693 16.7043 11.1565 16.5172 11.1565 16.2863C11.1565 16.0553 10.9693 15.8682 10.7384 15.8682C10.5075 15.8682 10.3203 16.0553 10.3203 16.2863C10.3203 16.5172 10.5075 16.7043 10.7384 16.7043Z" fill="#62393D"/>
                                </g>
                                <g filter="url(#filter4_ii_856_126584)">
                                    <path d="M10.8462 16.6416C11.0795 16.6416 11.2686 16.4525 11.2686 16.2192C11.2686 15.986 11.0795 15.7969 10.8462 15.7969C10.6129 15.7969 10.4238 15.986 10.4238 16.2192C10.4238 16.4525 10.6129 16.6416 10.8462 16.6416Z" fill="#895D56"/>
                                </g>
                                <path d="M22.3037 11.8855L12.7483 2.47176C12.3941 2.11749 11.8171 2.11749 11.4628 2.47176C11.4628 2.47176 11.4527 2.47176 11.4527 2.48189L1.87704 11.9057C1.52276 12.26 1.52276 12.8268 1.87704 13.171C2.23132 13.5151 2.80828 13.5253 3.16256 13.171L11.9251 4.5375C12.0224 4.44161 12.1786 4.44161 12.276 4.53749L21.0081 13.1406C21.3624 13.4949 21.9393 13.4949 22.2936 13.1406C22.6479 12.7863 22.658 12.2296 22.3037 11.8855Z" fill="url(#paint18_linear_856_126584)"/>
                                <path d="M22.3037 11.8855L12.7483 2.47176C12.3941 2.11749 11.8171 2.11749 11.4628 2.47176C11.4628 2.47176 11.4527 2.47176 11.4527 2.48189L1.87704 11.9057C1.52276 12.26 1.52276 12.8268 1.87704 13.171C2.23132 13.5151 2.80828 13.5253 3.16256 13.171L11.9251 4.5375C12.0224 4.44161 12.1786 4.44161 12.276 4.53749L21.0081 13.1406C21.3624 13.4949 21.9393 13.4949 22.2936 13.1406C22.6479 12.7863 22.658 12.2296 22.3037 11.8855Z" fill="url(#paint19_radial_856_126584)"/>
                                <g filter="url(#filter5_f_856_126584)">
                                    <path d="M5.51953 20.459H12.0008V21.308H5.51953V20.459Z" fill="#68552B"/>
                                </g>
                                <g filter="url(#filter6_i_856_126584)">
                                    <path d="M1.61133 22.4492C1.61133 21.6116 2.29032 20.9326 3.12791 20.9326H21.0479C21.8854 20.9326 22.5644 21.6116 22.5644 22.4492V22.4492H1.61133V22.4492Z" fill="url(#paint20_linear_856_126584)"/>
                                </g>
                                <path d="M1.61133 22.4492C1.61133 21.6116 2.29032 20.9326 3.12791 20.9326H21.0479C21.8854 20.9326 22.5644 21.6116 22.5644 22.4492V22.4492H1.61133V22.4492Z" fill="url(#paint21_linear_856_126584)"/>
                                <path d="M1.61133 22.4492C1.61133 21.6116 2.29032 20.9326 3.12791 20.9326H21.0479C21.8854 20.9326 22.5644 21.6116 22.5644 22.4492V22.4492H1.61133V22.4492Z" fill="url(#paint22_radial_856_126584)"/>
                                <path d="M5.67188 20.9321C5.67188 20.5667 5.96806 20.2705 6.33342 20.2705H12.2442C12.6095 20.2705 12.9057 20.5667 12.9057 20.9321V20.9321H5.67188V20.9321Z" fill="url(#paint23_linear_856_126584)"/>
                                <path d="M5.67188 20.9321C5.67188 20.5667 5.96806 20.2705 6.33342 20.2705H12.2442C12.6095 20.2705 12.9057 20.5667 12.9057 20.9321V20.9321H5.67188V20.9321Z" fill="url(#paint24_linear_856_126584)"/>
                                <path d="M5.67188 20.9321C5.67188 20.5667 5.96806 20.2705 6.33342 20.2705H12.2442C12.6095 20.2705 12.9057 20.5667 12.9057 20.9321V20.9321H5.67188V20.9321Z" fill="url(#paint25_linear_856_126584)"/>
                                <g filter="url(#filter7_f_856_126584)">
                                    <path d="M12.2539 2.92285L21.836 12.358" stroke="url(#paint26_linear_856_126584)" strokeWidth="0.3" strokeLinecap="round"/>
                                </g>
                                <g filter="url(#filter8_f_856_126584)">
                                    <path d="M12.2539 2.92285L2.67178 12.358" stroke="url(#paint27_linear_856_126584)" strokeOpacity="0.5" strokeWidth="0.3" strokeLinecap="round"/>
                                </g>
                                <defs>
                                    <filter id="filter0_f_856_126584" x="5.5918" y="11.9688" width="6.20703" height="10.4004" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126584"/>
                                    </filter>
                                    <filter id="filter1_i_856_126584" x="13.4668" y="12" width="4.89844" height="4.89844" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.5" dy="0.5"/>
                                        <feGaussianBlur stdDeviation="0.25"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0470588 0 0 0 0 0.47451 0 0 0 0 0.792157 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126584"/>
                                    </filter>
                                    <filter id="filter2_ii_856_126584" x="6.18477" y="12.0117" width="6.25664" height="8.91211" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.25"/>
                                        <feGaussianBlur stdDeviation="0.25"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.188235 0 0 0 0 0.141176 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126584"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.2"/>
                                        <feGaussianBlur stdDeviation="0.15"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.694118 0 0 0 0 0.470588 0 0 0 0 0.407843 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126584" result="effect2_innerShadow_856_126584"/>
                                    </filter>
                                    <filter id="filter3_f_856_126584" x="10.1703" y="15.7182" width="1.13594" height="1.13594" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.075" result="effect1_foregroundBlur_856_126584"/>
                                    </filter>
                                    <filter id="filter4_ii_856_126584" x="10.3238" y="15.7969" width="1.0457" height="0.844727" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.1"/>
                                        <feGaussianBlur stdDeviation="0.1"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.694118 0 0 0 0 0.482353 0 0 0 0 0.419608 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126584"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.1"/>
                                        <feGaussianBlur stdDeviation="0.1"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.184314 0 0 0 0 0.164706 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126584" result="effect2_innerShadow_856_126584"/>
                                    </filter>
                                    <filter id="filter5_f_856_126584" x="5.01953" y="19.959" width="7.48047" height="1.84863" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126584"/>
                                    </filter>
                                    <filter id="filter6_i_856_126584" x="1.61133" y="20.9326" width="21.4531" height="1.5166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.5"/>
                                        <feGaussianBlur stdDeviation="0.5"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.545098 0 0 0 0 0.352941 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126584"/>
                                    </filter>
                                    <filter id="filter7_f_856_126584" x="11.5035" y="2.17246" width="11.0828" height="10.9354" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_856_126584"/>
                                    </filter>
                                    <filter id="filter8_f_856_126584" x="1.92148" y="2.17246" width="11.0828" height="10.9354" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_856_126584"/>
                                    </filter>
                                    <radialGradient id="paint0_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.19421 3.50581) rotate(90) scale(3.54752 1.93635)">
                                        <stop stopColor="#F1B379"/>
                                        <stop offset="1" stopColor="#CD915F"/>
                                    </radialGradient>
                                    <linearGradient id="paint1_linear_856_126584" x1="4.35863" y1="6.14441" x2="5.62876" y2="6.14441" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#8E694B"/>
                                        <stop offset="1" stopColor="#8E694B" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_856_126584" x1="7.21816" y1="7.92188" x2="6.27484" y2="5.91167" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#9F6A42"/>
                                        <stop offset="1" stopColor="#9F6A42" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_856_126584" x1="6.08662" y1="3.01521" x2="6.08662" y2="3.14536" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#D5AD80"/>
                                        <stop offset="1" stopColor="#D5AD80" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint4_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.961 3.20179) rotate(-180) scale(2.10377 0.411326)">
                                        <stop stopColor="#FFCB8C"/>
                                        <stop offset="1" stopColor="#FFCB8C" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint5_linear_856_126584" x1="4.10824" y1="15.5825" x2="20.2659" y2="15.5825" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#D7905F"/>
                                        <stop offset="1" stopColor="#E8BC97"/>
                                    </linearGradient>
                                    <linearGradient id="paint6_linear_856_126584" x1="4.56248" y1="22.2908" x2="10.343" y2="13.3912" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#BA8951"/>
                                        <stop offset="1" stopColor="#BA8951" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint7_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.4227 21.2841) rotate(-118.804) scale(2.87846 3.61268)">
                                        <stop stopColor="#BEAB75"/>
                                        <stop offset="1" stopColor="#BEAB75" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint8_linear_856_126584" x1="20.7422" y1="18.057" x2="20.1876" y2="18.057" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FFE6B1"/>
                                        <stop offset="1" stopColor="#FFE6B1" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint9_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.9462 11.1866) rotate(90) scale(3.53782 0.799475)">
                                        <stop offset="0.35" stopColor="#9E413E"/>
                                        <stop offset="1" stopColor="#9E413E" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint10_linear_856_126584" x1="15.1694" y1="6.75373" x2="14.3081" y2="7.63437" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#B55852"/>
                                        <stop offset="1" stopColor="#B55852" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint11_linear_856_126584" x1="3.37399" y1="19.7883" x2="3.96339" y2="19.7883" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#845F3D"/>
                                        <stop offset="1" stopColor="#845F3D" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint12_linear_856_126584" x1="8.69533" y1="12.4688" x2="8.69533" y2="21.8696" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#805139"/>
                                        <stop offset="1" stopColor="#6D4D2F"/>
                                    </linearGradient>
                                    <linearGradient id="paint13_linear_856_126584" x1="13.9668" y1="16.7308" x2="18.7124" y2="12" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#4CCCFF"/>
                                        <stop offset="1" stopColor="#3A9EE6"/>
                                    </linearGradient>
                                    <linearGradient id="paint14_linear_856_126584" x1="16.1657" y1="16.674" x2="16.1657" y2="15.8089" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#1E9FE4"/>
                                        <stop offset="1" stopColor="#1E9FE4" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint15_linear_856_126584" x1="13.9668" y1="15.2803" x2="14.8501" y2="15.2803" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#48E0FF"/>
                                        <stop offset="1" stopColor="#48E0FF" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint16_linear_856_126584" x1="14.632" y1="11.2304" x2="15.2239" y2="13.0061" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#43B3F2"/>
                                        <stop offset="1" stopColor="#43B3F2" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint17_linear_856_126584" x1="6.38477" y1="17.6009" x2="12.192" y2="17.5674" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#70504D"/>
                                        <stop offset="1" stopColor="#9B665E"/>
                                    </linearGradient>
                                    <linearGradient id="paint18_linear_856_126584" x1="12.0879" y1="2.20605" x2="12.0879" y2="13.4329" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#EA6D2E"/>
                                        <stop offset="1" stopColor="#CA222B"/>
                                    </linearGradient>
                                    <radialGradient id="paint19_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.7174 1.44818) rotate(97.3264) scale(2.70396 5.04651)">
                                        <stop stopColor="#FF853F"/>
                                        <stop offset="1" stopColor="#FF853F" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint20_linear_856_126584" x1="21.9845" y1="22.4492" x2="1.07823" y2="22.4492" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#3FD47B"/>
                                        <stop offset="1" stopColor="#40DC7F"/>
                                    </linearGradient>
                                    <linearGradient id="paint21_linear_856_126584" x1="12.0879" y1="22.8517" x2="12.0879" y2="21.6909" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.107097" stopColor="#3FD17B"/>
                                        <stop offset="1" stopColor="#3FD17B" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint22_radial_856_126584" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.5644 20.7144) rotate(147.147) scale(2.51384 4.12207)">
                                        <stop stopColor="#5CEE92"/>
                                        <stop offset="1" stopColor="#5CEE92" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint23_linear_856_126584" x1="5.86704" y1="20.9321" x2="12.6419" y2="20.9321" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#9FA1A3"/>
                                        <stop offset="1" stopColor="#C4C1C7"/>
                                    </linearGradient>
                                    <linearGradient id="paint24_linear_856_126584" x1="5.5507" y1="20.9321" x2="6.41352" y2="20.9321" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#59675C"/>
                                        <stop offset="1" stopColor="#59675C" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint25_linear_856_126584" x1="12.9057" y1="20.6013" x2="11.772" y2="21.3067" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E3DADD"/>
                                        <stop offset="1" stopColor="#E3DADD" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint26_linear_856_126584" x1="11.9595" y1="2.77654" x2="22.2373" y2="13.358" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF8337"/>
                                        <stop offset="1" stopColor="#F24747"/>
                                    </linearGradient>
                                    <linearGradient id="paint27_linear_856_126584" x1="12.5483" y1="2.77654" x2="2.27052" y2="13.358" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF8337"/>
                                        <stop offset="1" stopColor="#F24747"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </TmpItem>
                        <TmpItem name={'Отдел продаж'}
                                 sub={'Пресет с колонками, тегами и статусами'}
                                 style={{
                                     background: 'background: rgba(0, 0, 0, 0.04);',
                                     border: '1px solid rgba(0, 0, 0, 0.04)',
                                 }}
                                 action={() => {
                                 }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_ii_856_126592)">
                                    <path d="M18.1894 11.4389L19.6369 7.67387C19.7869 7.28387 19.6969 6.83387 19.3969 6.53387L15.8569 2.99387C15.5569 2.69387 15.1144 2.60387 14.7169 2.75387L10.9519 4.20137C10.6669 4.31387 10.4044 4.47887 10.1869 4.69637L1.86187 13.0214C1.44188 13.4414 1.44188 14.1164 1.86187 14.5289L7.86188 20.5289C8.28188 20.9489 8.95687 20.9489 9.36938 20.5289L17.6944 12.2039C17.9119 11.9864 18.0769 11.7314 18.1894 11.4389ZM14.4544 7.94387C13.9369 7.42637 13.9369 6.59387 14.4544 6.07637C14.9719 5.55887 15.8044 5.55887 16.3219 6.07637C16.8394 6.59387 16.8394 7.42637 16.3219 7.94387C15.8044 8.45387 14.9644 8.45387 14.4544 7.94387Z" fill="url(#paint0_linear_856_126592)"/>
                                </g>
                                <path d="M18.1894 11.4389L19.6369 7.67387C19.7869 7.28387 19.6969 6.83387 19.3969 6.53387L15.8569 2.99387C15.5569 2.69387 15.1144 2.60387 14.7169 2.75387L10.9519 4.20137C10.6669 4.31387 10.4044 4.47887 10.1869 4.69637L1.86187 13.0214C1.44188 13.4414 1.44188 14.1164 1.86187 14.5289L7.86188 20.5289C8.28188 20.9489 8.95687 20.9489 9.36938 20.5289L17.6944 12.2039C17.9119 11.9864 18.0769 11.7314 18.1894 11.4389ZM14.4544 7.94387C13.9369 7.42637 13.9369 6.59387 14.4544 6.07637C14.9719 5.55887 15.8044 5.55887 16.3219 6.07637C16.8394 6.59387 16.8394 7.42637 16.3219 7.94387C15.8044 8.45387 14.9644 8.45387 14.4544 7.94387Z" fill="url(#paint1_linear_856_126592)"/>
                                <path d="M18.1894 11.4389L19.6369 7.67387C19.7869 7.28387 19.6969 6.83387 19.3969 6.53387L15.8569 2.99387C15.5569 2.69387 15.1144 2.60387 14.7169 2.75387L10.9519 4.20137C10.6669 4.31387 10.4044 4.47887 10.1869 4.69637L1.86187 13.0214C1.44188 13.4414 1.44188 14.1164 1.86187 14.5289L7.86188 20.5289C8.28188 20.9489 8.95687 20.9489 9.36938 20.5289L17.6944 12.2039C17.9119 11.9864 18.0769 11.7314 18.1894 11.4389ZM14.4544 7.94387C13.9369 7.42637 13.9369 6.59387 14.4544 6.07637C14.9719 5.55887 15.8044 5.55887 16.3219 6.07637C16.8394 6.59387 16.8394 7.42637 16.3219 7.94387C15.8044 8.45387 14.9644 8.45387 14.4544 7.94387Z" fill="url(#paint2_radial_856_126592)"/>
                                <g filter="url(#filter1_f_856_126592)">
                                    <path d="M18.5252 10.2527C18.5067 10.2531 18.4881 10.2533 18.4695 10.2533C17.3315 10.2533 16.3717 9.49047 16.0735 8.44824C15.8565 8.54381 15.6166 8.57209 15.3867 8.53308C15.7155 9.85081 16.867 10.8429 18.264 10.9321L18.5252 10.2527Z" fill="#E3A242"/>
                                </g>
                                <g filter="url(#filter2_ii_856_126592)">
                                    <path d="M15.3864 5.68547C15.7239 5.68547 16.0614 5.81297 16.3164 6.07547C16.8339 6.59297 16.8339 7.42547 16.3164 7.94297C16.0614 8.19797 15.7239 8.33297 15.3864 8.33297C15.0489 8.33297 14.7114 8.20547 14.4564 7.94297C13.9389 7.42547 13.9389 6.59297 14.4564 6.07547C14.7114 5.81297 15.0489 5.68547 15.3864 5.68547ZM15.3864 4.98047C14.8464 4.98047 14.3364 5.19047 13.9539 5.57297C13.1664 6.36047 13.1664 7.64297 13.9539 8.43797C14.3364 8.82047 14.8464 9.03047 15.3864 9.03047C15.9264 9.03047 16.4364 8.82047 16.8189 8.43797C17.6064 7.65047 17.6064 6.36797 16.8189 5.57297C16.4364 5.19047 15.9264 4.98047 15.3864 4.98047Z" fill="#F6424A"/>
                                    <path d="M15.3864 5.68547C15.7239 5.68547 16.0614 5.81297 16.3164 6.07547C16.8339 6.59297 16.8339 7.42547 16.3164 7.94297C16.0614 8.19797 15.7239 8.33297 15.3864 8.33297C15.0489 8.33297 14.7114 8.20547 14.4564 7.94297C13.9389 7.42547 13.9389 6.59297 14.4564 6.07547C14.7114 5.81297 15.0489 5.68547 15.3864 5.68547ZM15.3864 4.98047C14.8464 4.98047 14.3364 5.19047 13.9539 5.57297C13.1664 6.36047 13.1664 7.64297 13.9539 8.43797C14.3364 8.82047 14.8464 9.03047 15.3864 9.03047C15.9264 9.03047 16.4364 8.82047 16.8189 8.43797C17.6064 7.65047 17.6064 6.36797 16.8189 5.57297C16.4364 5.19047 15.9264 4.98047 15.3864 4.98047Z" fill="url(#paint3_radial_856_126592)"/>
                                    <path d="M15.3864 5.68547C15.7239 5.68547 16.0614 5.81297 16.3164 6.07547C16.8339 6.59297 16.8339 7.42547 16.3164 7.94297C16.0614 8.19797 15.7239 8.33297 15.3864 8.33297C15.0489 8.33297 14.7114 8.20547 14.4564 7.94297C13.9389 7.42547 13.9389 6.59297 14.4564 6.07547C14.7114 5.81297 15.0489 5.68547 15.3864 5.68547ZM15.3864 4.98047C14.8464 4.98047 14.3364 5.19047 13.9539 5.57297C13.1664 6.36047 13.1664 7.64297 13.9539 8.43797C14.3364 8.82047 14.8464 9.03047 15.3864 9.03047C15.9264 9.03047 16.4364 8.82047 16.8189 8.43797C17.6064 7.65047 17.6064 6.36797 16.8189 5.57297C16.4364 5.19047 15.9264 4.98047 15.3864 4.98047Z" fill="url(#paint4_radial_856_126592)"/>
                                </g>
                                <g filter="url(#filter3_i_856_126592)">
                                    <path d="M17.3725 4.50985C17.8386 4.15544 18.4202 3.94507 19.0509 3.94507C20.584 3.94507 21.8267 5.18783 21.8267 6.72085C21.8267 8.25388 20.584 9.49664 19.0509 9.49664C17.5179 9.49664 16.2751 8.25388 16.2751 6.72085C16.2751 6.50751 16.2992 6.29979 16.3448 6.10026C16.337 6.09204 16.329 6.0839 16.321 6.07583C16.1314 5.8863 15.8996 5.76619 15.6565 5.7155C15.5623 6.03418 15.5117 6.37161 15.5117 6.72085C15.5117 8.67551 17.0963 10.2601 19.0509 10.2601C21.0056 10.2601 22.5901 8.67551 22.5901 6.72085C22.5901 4.7662 21.0056 3.18164 19.0509 3.18164C18.2092 3.18164 17.4362 3.47545 16.8287 3.9661L17.3725 4.50985Z" fill="#AAA2B3"/>
                                    <path d="M17.3725 4.50985C17.8386 4.15544 18.4202 3.94507 19.0509 3.94507C20.584 3.94507 21.8267 5.18783 21.8267 6.72085C21.8267 8.25388 20.584 9.49664 19.0509 9.49664C17.5179 9.49664 16.2751 8.25388 16.2751 6.72085C16.2751 6.50751 16.2992 6.29979 16.3448 6.10026C16.337 6.09204 16.329 6.0839 16.321 6.07583C16.1314 5.8863 15.8996 5.76619 15.6565 5.7155C15.5623 6.03418 15.5117 6.37161 15.5117 6.72085C15.5117 8.67551 17.0963 10.2601 19.0509 10.2601C21.0056 10.2601 22.5901 8.67551 22.5901 6.72085C22.5901 4.7662 21.0056 3.18164 19.0509 3.18164C18.2092 3.18164 17.4362 3.47545 16.8287 3.9661L17.3725 4.50985Z" fill="url(#paint5_radial_856_126592)"/>
                                </g>
                                <g filter="url(#filter4_f_856_126592)">
                                    <circle cx="19.0516" cy="6.70008" r="3.24891" stroke="url(#paint6_linear_856_126592)" strokeWidth="0.15"/>
                                    <circle cx="19.0516" cy="6.70008" r="3.24891" stroke="url(#paint7_radial_856_126592)" strokeWidth="0.15"/>
                                </g>
                                <defs>
                                    <filter id="filter0_ii_856_126592" x="1.54688" y="2.38262" width="18.1621" height="18.7611" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="-0.3"/>
                                        <feGaussianBlur stdDeviation="0.2"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.564706 0 0 0 0 0.372549 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126592"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="0.3"/>
                                        <feGaussianBlur stdDeviation="0.15"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.941176 0 0 0 0 0.262745 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126592" result="effect2_innerShadow_856_126592"/>
                                    </filter>
                                    <filter id="filter1_f_856_126592" x="14.7367" y="7.79824" width="4.43867" height="3.7834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.325" result="effect1_foregroundBlur_856_126592"/>
                                    </filter>
                                    <filter id="filter2_ii_856_126592" x="13.2633" y="4.88047" width="4.24688" height="4.2498" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.1" dy="0.1"/>
                                        <feGaussianBlur stdDeviation="0.15"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0.223529 0 0 0 0 0.211765 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126592"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.1" dy="-0.1"/>
                                        <feGaussianBlur stdDeviation="0.15"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.952941 0 0 0 0 0.176471 0 0 0 0 0.380392 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126592" result="effect2_innerShadow_856_126592"/>
                                    </filter>
                                    <filter id="filter3_i_856_126592" x="15.5117" y="3.03164" width="7.07812" height="7.22813" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="-0.15"/>
                                        <feGaussianBlur stdDeviation="0.25"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.631373 0 0 0 0 0.380392 0 0 0 0 0.815686 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126592"/>
                                    </filter>
                                    <filter id="filter4_f_856_126592" x="15.5785" y="3.22598" width="6.94648" height="6.94844" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.075" result="effect1_foregroundBlur_856_126592"/>
                                    </filter>
                                    <linearGradient id="paint0_linear_856_126592" x1="16.4766" y1="8.35449" x2="2.57812" y2="13.3467" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FFD846"/>
                                        <stop offset="1" stopColor="#FFB349"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_856_126592" x1="2.78906" y1="11.2842" x2="4.38281" y2="12.8779" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.232565" stopColor="#FFBD42"/>
                                        <stop offset="1" stopColor="#FFBD42" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint2_radial_856_126592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.2695 7.41699) rotate(92.6291) scale(2.2993 2.4776)">
                                        <stop offset="0.588488" stopColor="#FCA643"/>
                                        <stop offset="1" stopColor="#FFCE46" stopOpacity="0"/>
                                    </radialGradient>
                                    <radialGradient id="paint3_radial_856_126592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.0294 7.00547) rotate(-3.09587) scale(3.38511 3.83239)">
                                        <stop offset="0.883561" stopColor="#FF5856" stopOpacity="0"/>
                                        <stop offset="0.967272" stopColor="#FF5856"/>
                                    </radialGradient>
                                    <radialGradient id="paint4_radial_856_126592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0333 8.65077) rotate(149.588) scale(0.750077 0.824841)">
                                        <stop offset="0.187536" stopColor="#CA313A"/>
                                        <stop offset="1" stopColor="#CA313A" stopOpacity="0"/>
                                    </radialGradient>
                                    <radialGradient id="paint5_radial_856_126592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0303 5.58833) rotate(120.816) scale(0.777776 1.18388)">
                                        <stop offset="0.184563" stopColor="#8A4172"/>
                                        <stop offset="1" stopColor="#8A4172" stopOpacity="0"/>
                                    </radialGradient>
                                    <linearGradient id="paint6_linear_856_126592" x1="21.7732" y1="8.91646" x2="19.7224" y2="7.55708" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.251492" stopColor="#B6AAC2"/>
                                        <stop offset="1" stopColor="#B6AAC2" stopOpacity="0"/>
                                    </linearGradient>
                                    <radialGradient id="paint7_radial_856_126592" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.2029 3.51411) rotate(17.6711) scale(1.3898 0.735362)">
                                        <stop offset="0.46086" stopColor="#B6AAC2"/>
                                        <stop offset="1" stopColor="#B6AAC2" stopOpacity="0"/>
                                    </radialGradient>
                                </defs>
                            </svg>
                        </TmpItem>
                        <TmpItem name={'Парикмахерская'}
                                 sub={'Пресет с колонками, тегами и статусами'}
                                 style={{
                                     background: 'background: rgba(0, 0, 0, 0.04);',
                                     border: '1px solid rgba(0, 0, 0, 0.04)',
                                 }}
                                 action={() => {
                                 }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_i_856_126600)">
                                    <path d="M4.51578 22.2751L15.9308 10.8601L13.6283 8.55762L3.99078 18.1876C3.00828 19.1701 3.00828 20.7676 3.99078 21.7501L4.51578 22.2751Z" fill="url(#paint0_linear_856_126600)"/>
                                    <path d="M4.51578 22.2751L15.9308 10.8601L13.6283 8.55762L3.99078 18.1876C3.00828 19.1701 3.00828 20.7676 3.99078 21.7501L4.51578 22.2751Z" fill="url(#paint1_linear_856_126600)"/>
                                </g>
                                <g filter="url(#filter1_i_856_126600)">
                                    <path d="M11.9158 10.1686C11.7577 10.3245 11.7568 10.5793 11.9138 10.7363L13.878 12.7004C14.0352 12.8577 14.2906 12.8565 14.4464 12.6977L15.1764 11.9534C15.297 11.8305 15.4823 11.8005 15.6419 11.865C18.6555 13.0829 22.1856 10.3192 22.1856 7.17027C22.1856 4.47027 20.0106 2.28027 17.3106 2.28027C14.6106 2.28027 12.4206 4.47027 12.4206 7.17027C12.4206 7.81714 12.5328 8.40361 12.7609 8.95687C12.8264 9.1157 12.7965 9.30034 12.6741 9.42096L11.9158 10.1686ZM15.0306 7.17027C15.0306 5.91777 16.0506 4.89777 17.3031 4.89777C18.5556 4.89777 19.5756 5.91777 19.5756 7.17027C19.5756 8.42277 18.5556 9.44277 17.3031 9.44277C16.0506 9.44277 15.0306 8.43027 15.0306 7.17027Z" fill="url(#paint2_linear_856_126600)"/>
                                </g>
                                <g filter="url(#filter2_ii_856_126600)">
                                    <path d="M18.8701 22.2752L7.45508 10.8602L9.70508 8.5127L19.3351 18.1427C20.3251 19.1327 20.3326 20.8202 19.3501 21.8027L18.8701 22.2752Z" fill="url(#paint3_linear_856_126600)"/>
                                </g>
                                <g filter="url(#filter3_d_856_126600)">
                                    <path d="M11.6697 13.605C12.1129 13.605 12.4722 13.2457 12.4722 12.8025C12.4722 12.3593 12.1129 12 11.6697 12C11.2265 12 10.8672 12.3593 10.8672 12.8025C10.8672 13.2457 11.2265 13.605 11.6697 13.605Z" fill="#A79AAC"/>
                                    <path d="M11.6697 13.605C12.1129 13.605 12.4722 13.2457 12.4722 12.8025C12.4722 12.3593 12.1129 12 11.6697 12C11.2265 12 10.8672 12.3593 10.8672 12.8025C10.8672 13.2457 11.2265 13.605 11.6697 13.605Z" fill="url(#paint4_radial_856_126600)"/>
                                    <path d="M11.6697 13.605C12.1129 13.605 12.4722 13.2457 12.4722 12.8025C12.4722 12.3593 12.1129 12 11.6697 12C11.2265 12 10.8672 12.3593 10.8672 12.8025C10.8672 13.2457 11.2265 13.605 11.6697 13.605Z" fill="url(#paint5_radial_856_126600)"/>
                                    <path d="M11.6697 13.605C12.1129 13.605 12.4722 13.2457 12.4722 12.8025C12.4722 12.3593 12.1129 12 11.6697 12C11.2265 12 10.8672 12.3593 10.8672 12.8025C10.8672 13.2457 11.2265 13.605 11.6697 13.605Z" fill="url(#paint6_radial_856_126600)"/>
                                    <path d="M11.6697 13.605C12.1129 13.605 12.4722 13.2457 12.4722 12.8025C12.4722 12.3593 12.1129 12 11.6697 12C11.2265 12 10.8672 12.3593 10.8672 12.8025C10.8672 13.2457 11.2265 13.605 11.6697 13.605Z" fill="url(#paint7_radial_856_126600)"/>
                                </g>
                                <g filter="url(#filter4_i_856_126600)">
                                    <path d="M10.8388 9.32624C10.723 9.21044 10.6912 9.03507 10.749 8.88181C12.09 5.32259 9.23207 2.28027 6.06055 2.28027C3.36055 2.28027 1.18555 4.47027 1.18555 7.17027C1.18555 9.87027 3.36055 12.0603 6.06055 12.0603C6.705 12.0603 7.17805 12.0571 7.69812 11.8612C7.85709 11.8013 8.03929 11.8284 8.16013 11.9478L8.92884 12.7073C9.08502 12.8616 9.33637 12.8612 9.49204 12.7064L11.5776 10.6323C11.7346 10.4762 11.7349 10.2224 11.5784 10.0658L10.8388 9.32624ZM6.06805 9.44277C4.81555 9.44277 3.79555 8.42277 3.79555 7.17027C3.79555 5.91777 4.81555 4.89777 6.06805 4.89777C7.32055 4.89777 8.34055 5.91777 8.34055 7.17027C8.34055 8.43027 7.32055 9.44277 6.06805 9.44277Z" fill="url(#paint8_linear_856_126600)"/>
                                </g>
                                <g filter="url(#filter5_f_856_126600)">
                                    <circle cx="6.75641" cy="6.95075" r="3.69977" stroke="url(#paint9_linear_856_126600)" strokeWidth="0.75"/>
                                    <circle cx="6.75641" cy="6.95075" r="3.69977" stroke="url(#paint10_linear_856_126600)" strokeWidth="0.75"/>
                                </g>
                                <g filter="url(#filter6_f_856_126600)">
                                    <circle cx="17.7693" cy="6.99066" r="3.73651" transform="rotate(-4.45472 17.7693 6.99066)" stroke="url(#paint11_linear_856_126600)" strokeWidth="0.75"/>
                                    <circle cx="17.7693" cy="6.99066" r="3.73651" transform="rotate(-4.45472 17.7693 6.99066)" stroke="url(#paint12_linear_856_126600)" strokeWidth="0.75"/>
                                </g>
                                <g filter="url(#filter7_f_856_126600)">
                                    <path d="M8.1765 11.1544C8.54997 10.9091 9.46157 10.2132 10.1203 9.39298" stroke="#FF683C" strokeWidth="0.75"/>
                                </g>
                                <g filter="url(#filter8_f_856_126600)">
                                    <path d="M15.2136 11.3561C14.8548 11.0897 13.9846 10.3428 13.374 9.48607" stroke="url(#paint13_linear_856_126600)" strokeWidth="0.75"/>
                                </g>
                                <defs>
                                    <filter id="filter0_i_856_126600" x="3.25391" y="8.50762" width="12.7277" height="13.7678" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.05" dy="-0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.631373 0 0 0 0 0.521569 0 0 0 0 0.733333 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126600"/>
                                    </filter>
                                    <filter id="filter1_i_856_126600" x="11.7969" y="2.28027" width="10.7887" height="10.5371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4"/>
                                        <feGaussianBlur stdDeviation="0.4"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.690196 0 0 0 0 0.211765 0 0 0 0 0.341176 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126600"/>
                                    </filter>
                                    <filter id="filter2_ii_856_126600" x="7.40508" y="8.4627" width="12.727" height="13.8627" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.05" dy="0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.627451 0 0 0 0 0.592157 0 0 0 0 0.658824 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126600"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.05" dy="-0.05"/>
                                        <feGaussianBlur stdDeviation="0.05"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.643137 0 0 0 0 0.52549 0 0 0 0 0.74902 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="effect1_innerShadow_856_126600" result="effect2_innerShadow_856_126600"/>
                                    </filter>
                                    <filter id="filter3_d_856_126600" x="10.5672" y="11.9" width="2.00547" height="2.00547" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="-0.1" dy="0.1"/>
                                        <feGaussianBlur stdDeviation="0.1"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.54902 0 0 0 0 0.419608 0 0 0 0 0.658824 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_856_126600"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_856_126600" result="shape"/>
                                    </filter>
                                    <filter id="filter4_i_856_126600" x="1.18555" y="2.28027" width="10.9098" height="10.543" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dx="0.4"/>
                                        <feGaussianBlur stdDeviation="0.4"/>
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.690196 0 0 0 0 0.211765 0 0 0 0 0.341176 0 0 0 1 0"/>
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_856_126600"/>
                                    </filter>
                                    <filter id="filter5_f_856_126600" x="2.18164" y="2.37598" width="9.15039" height="9.14941" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126600"/>
                                    </filter>
                                    <filter id="filter6_f_856_126600" x="13.1582" y="2.37891" width="9.22266" height="9.22363" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126600"/>
                                    </filter>
                                    <filter id="filter7_f_856_126600" x="7.4707" y="8.6582" width="3.44141" height="3.30957" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126600"/>
                                    </filter>
                                    <filter id="filter8_f_856_126600" x="12.5684" y="8.76855" width="3.36914" height="3.38867" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_856_126600"/>
                                    </filter>
                                    <linearGradient id="paint0_linear_856_126600" x1="6.4827" y1="15.7567" x2="8.94363" y2="18.1004" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#877697"/>
                                        <stop offset="1" stopColor="#BDA7D4"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_856_126600" x1="11.1819" y1="14.3153" x2="10.7366" y2="14.7254" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.606588" stopColor="#7C549C"/>
                                        <stop offset="1" stopColor="#7C549C" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_856_126600" x1="22.1856" y1="7.63315" x2="14.1781" y2="7.63315" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF7415"/>
                                        <stop offset="1" stopColor="#F63178"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_856_126600" x1="13.5659" y1="16.7878" x2="15.6926" y2="14.4572" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#B59FCA"/>
                                        <stop offset="1" stopColor="#887799"/>
                                    </linearGradient>
                                    <radialGradient id="paint4_radial_856_126600" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.7315 12.7097) rotate(134.569) scale(1.10212 1.01128)">
                                        <stop offset="0.517871" stopColor="#988098" stopOpacity="0"/>
                                        <stop offset="0.860007" stopColor="#988098"/>
                                    </radialGradient>
                                    <radialGradient id="paint5_radial_856_126600" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.2979 13.7292) rotate(-68.142) scale(0.998502 0.890646)">
                                        <stop stopColor="#926BB4"/>
                                        <stop offset="1" stopColor="#926BB4" stopOpacity="0"/>
                                    </radialGradient>
                                    <radialGradient id="paint6_radial_856_126600" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.9659 12.6863) rotate(139.617) scale(0.388878)">
                                        <stop offset="0.258961" stopColor="#B9AEC4"/>
                                        <stop offset="1" stopColor="#B9AEC4" stopOpacity="0"/>
                                    </radialGradient>
                                    <radialGradient id="paint7_radial_856_126600" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.5909 13.3073) rotate(-65.8586) scale(1.43264)">
                                        <stop offset="0.699835" stopColor="#A49EA6" stopOpacity="0"/>
                                        <stop offset="1" stopColor="#A49EA6"/>
                                    </radialGradient>
                                    <linearGradient id="paint8_linear_856_126600" x1="11.8621" y1="7.63315" x2="3.76367" y2="7.63315" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF7415"/>
                                        <stop offset="1" stopColor="#F63178"/>
                                    </linearGradient>
                                    <linearGradient id="paint9_linear_856_126600" x1="10.6772" y1="4.39203" x2="7.46149" y2="6.67413" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF8C72"/>
                                        <stop offset="1" stopColor="#FF8C72" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint10_linear_856_126600" x1="3.05664" y1="8.43757" x2="4.79904" y2="7.7806" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF638F"/>
                                        <stop offset="1" stopColor="#FF638F" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint11_linear_856_126600" x1="21.729" y1="4.40654" x2="18.4814" y2="6.7113" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF8C72"/>
                                        <stop offset="1" stopColor="#FF8C72" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint12_linear_856_126600" x1="14.0328" y1="8.49225" x2="15.7925" y2="7.82876" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FF638F"/>
                                        <stop offset="1" stopColor="#FF638F" stopOpacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint13_linear_856_126600" x1="13.5368" y1="9.49542" x2="15.2465" y2="12.8067" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#DB236E"/>
                                        <stop offset="1" stopColor="#DB236E" stopOpacity="0"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </TmpItem>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateDialog