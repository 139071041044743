import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Container} from "@mui/material";

const Step = styled('div', {name: 'step'})(() => ({
    height: '10px',
    width: '10px',
    left: '0px',
    top: '0px',
    borderRadius: '50%',

    background: 'none',
    border: '1px solid #E9E9FF66',

    '&.active': {
        border: '1px solid #FFFFFF',
        boxShadow: '0px 1px 8px 1px #BDC0FF66',
        background: '#FFFFFF',
    }

}))

const Connector = styled('div', {name: 'step-connector'})(() => ({
    height: '1px',
    width: '32px',
    left: '10px',
    top: '5px',
    background: 'linear-gradient(90deg, rgba(233, 233, 255, 0.4) 0%, rgba(233, 233, 255, 0.24) 26.08%, rgba(233, 233, 255, 0.24) 70.94%, rgba(233, 233, 255, 0.4) 94.83%)',
    borderImageSource: 'linear-gradient(90deg, rgba(233, 233, 255, 0.4) 0%, rgba(233, 233, 255, 0.24) 26.08%, rgba(233, 233, 255, 0.24) 70.94%, rgba(233, 233, 255, 0.4) 94.83%)',
}))

export default function CustomizedSteppers({step = 0}) {
    return (
        <Container
            sx={{
                position: 'absolute',
                width: '352px',
                left: 'calc(50% - 352px/2)',
                top: '36px',
                height: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 200,
            }}>
            <Step className={step>= 1 ? 'active' : ''}/>
            <Connector/>
            <Step className={step>= 2 ? 'active' : ''}/>
            <Connector/>
            <Step className={step>= 3 ? 'active' : ''}/>
        </Container>
    );
}
