import {gql} from "@apollo/client";
import {COLOR_FIELDS} from "./Color";
import {PROFILE_FIELDS} from "./Profile";

export const CUSTOMERS_FIELDS = gql`
    ${COLOR_FIELDS}
    ${PROFILE_FIELDS}
    fragment CustomersFields on clients {
        id
        description
        created_at
        is_client
        main_number
        name
        clients_tags {
            tags_list {
                id
                value
                color {
                    ...ColorFields
                }
            }
        }
        clients_statuses(where: {status_option_id: {_is_null: false}}) {
            statuses_option {
                id
                value
                color {
                    ...ColorFields
                }
            }
        }
        clients_assignees {
            profile {
                ...ProfileFields
            }
        }
        clients_assignees {
            profile {
                ...ProfileFields
            }
        }
    }
`;