import {gql} from "@apollo/client";

export const ALL_GROUP_QUERY = gql`
    query my_groups {
        groups {
            id
            name
            created_at
        }
    }
`;

export const GROUP_QUERY = gql`
    query group_by_pk($groupId: Int!) {
        groups_by_pk(id: $groupId) {
            id
            name
        }
    }
`;