import {resources} from "./resources";

export const config = {
    resources: process.env.REACT_APP_LOCATION === 'global' ? resources : {
        'ru-RU': resources['ru-RU']
    },
    lng: process.env.REACT_APP_LOCATION === 'global' ? 'en-US' : 'ru-RU',
    fallbackLng: process.env.REACT_APP_LOCATION === 'global' ? "en-US" : 'ru-RU',
    defaultNS: 'common',
    keySeparator: '_',
    pluralSeparator: '.',
    interpolation: {
        escapeValue: false,
        prefix: '%',
        suffix: '%'
    },
    debug: false,
};
