import {gql} from "@apollo/client";
import {PROFILE_FIELDS} from "../Fragments/Profile";

export const BOARD_CLIENTS_COLUMNS_LIST = gql`
    ${PROFILE_FIELDS}
    query ClientsColumnsList {
        boards_columns_clients(order_by: {position: asc}) {
            id: column_client_id
            column_client_id
            position
            columns_client {
                id
                title
                columns_clients_assignees(where: {profile_id: {_gt: 0}}) {
                    id: profile_id
                    profile_id
                }
                columns_clients_statuses(where: {status_option_id: {_gt: 0}}) {
                    id: status_list_id
                    status_list_id
                    status_option_id
                    statuses_list {
                        id
                    }
                    statuses_option {
                        id
                    }
                }
                columns_clients_tags(where:{ tag_id: {_gt: 0}}) {
                    id: tag_id
                    tag_id
                }
                #colors_column {
                #    id
                #    weight
                #    code
                #}
                profile {
                    ...ProfileFields
                }
            }
        }
    }
`