import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const authGetApi = createApi({
    reducerPath: 'authApiGet',
    baseQuery: fetchBaseQuery({baseUrl: 'https://auth-get.v202207.api.evernum.ru/'}),
    endpoints: (builder) => ({
        getAuthMethod: builder.query({
            query: () => `get?platform=web&version=100&lang=ru`,
        }),
    }),
})

export const {useLazyGetAuthMethodQuery} = authGetApi
