import React, {useEffect, useState} from "react";
import ColumnHeader from "./headers/columnHeader";
import CallCard from "./callCard";
import {useLazyQuery} from "@apollo/client";
import {BoardComponent} from "../../../components/Board/boardComponent";
import {useSelector} from "react-redux";
import {selectorGroupId} from "../../../redux/auth/Reducer";
import {CallsColumn} from "./callsColumn";
import {BOARDS_COLUMNS_TASKS_QUERY} from "../../../components/GQL/Query/BoardsColumnsCalls";

const CallsBoard = () => {
    const [board, setBoard] = useState({columns: []});

    const lastGroupID = localStorage.getItem('lastG')
    const groupId = useSelector(selectorGroupId)

    const [getBoardsColumns, {loading, error, data}] = useLazyQuery(BOARDS_COLUMNS_TASKS_QUERY);

    useEffect(() => {
        if (lastGroupID === groupId) {
            getBoardsColumns({
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-only'
            })
        } else {
            localStorage.setItem('lastG', groupId)
            getBoardsColumns({
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network'
            })
        }
        // eslint-disable-next-line
    }, [groupId])

    useEffect(() => {
        if (data) {
            let columns = data?.boards_columns_tasks?.map((item) => {
                return {
                    ...item.columns_task,
                    position: item.position,
                    cards: [],
                }
            })
            setBoard({columns})
        }
    }, [data, loading, error])

    if (loading) {
        return <p>Loading ...</p>;
    }
    if (error) {
        return (
            <pre>
              Error in TASKS
                {JSON.stringify(error, null, 2)}
            </pre>
        );
    }

    return (
        <BoardComponent board={board} Column={CallsColumn} ColumnHeader={ColumnHeader} Card={CallCard}/>
    )
}

export default CallsBoard