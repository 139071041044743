import './App.css';
import {Provider} from "react-redux";
import store from "./redux/store";
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {config as i18nextConfig} from './translations'
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {ApolloClientComponent} from "./components/GQL/apolloClient";

const i18nInstance = i18next.createInstance();

i18nInstance
    .use(intervalPlural)
    .use(initReactI18next)
    .init(i18nextConfig, () => {
        console.log('i18next init complete')
    })
    .then(() => {
    }).finally()

export function toggleGtagState(lng) {
    window['ga-disable-G-'] = !(lng !== 'ru-RU' && process.env.NODE_ENV === 'production' && process.env.REACT_APP_LOCATION === 'global');
    console.log({'ga-disable': window['ga-disable-G-']})
}

i18nInstance.on('languageChanged', function (lng) {
    console.log({languageChanged: lng})
    toggleGtagState(lng)
})

function App() {
    return (
        <Provider store={store}>
            <ApolloClientComponent>
                <I18nextProvider i18n={i18nInstance}>
                    <RouterProvider router={router}/>
                </I18nextProvider>
            </ApolloClientComponent>
        </Provider>
    );
}

export default App;
