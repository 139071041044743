import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    accessToken: localStorage.getItem('token'),
    isNewUser: !!(localStorage.getItem('isN')),
    phoneNumber: localStorage.getItem('pn'),
    profileId: parseInt(localStorage.getItem('pId')),
    groupId: localStorage.getItem('groupId'),
}

export const selectorIsAuthorized = (state) => (!!state.auth.accessToken);
export const selectorAccessToken = (state) => (state.auth.accessToken);
export const selectorIsNewUser = (state) => (state.auth.isNewUser);
export const selectorPhoneNumber = (state) => (state.auth.phoneNumber);
export const selectorProfileId = (state) => (state.auth.profileId);
export const selectorGroupId = (state) => (state.auth.groupId);

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            localStorage.setItem('token', action.payload)
            state.accessToken = action.payload
        },
        setIsNewUser: (state, action) => {
            localStorage.setItem('isN', action.payload)
            state.isNewUser = action.payload
        },
        setPhoneNumber: (state, action) => {
            localStorage.setItem('pn', action.payload)
            state.phoneNumber = action.payload
        },
        setProfileId: (state, action) => {
            localStorage.setItem('pId', action.payload)
            state.profileId = action.payload
        },
        setGroupId: (state, action) => {
            localStorage.setItem('groupId', action.payload)
            state.groupId = action?.payload?.toString()
        },
    },
})

// Action creators are generated for each case reducer function
export const {setAccessToken, setIsNewUser, setPhoneNumber, setProfileId, setGroupId} = auth.actions

export default auth.reducer
