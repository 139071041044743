import {ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, split} from '@apollo/client';
import {createClient} from "graphql-ws";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {getMainDefinition} from "@apollo/client/utilities";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectorAccessToken, selectorGroupId} from "../../redux/auth/Reducer";
import ApolloCache from '@wora/apollo-cache';
import {IDBStorage} from '@wora/cache-persist/lib/idbstorage';

const idbStorages = IDBStorage.create({
    name: "cache",
    storeNames: ["persist"]
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    },
};

export const fetchPolicyNetworkOnly = {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
}

export const ApolloClientComponent = ({children}) => {
    const groupId = useSelector(selectorGroupId)
    const token = useSelector(selectorAccessToken)

    const [client, setClient] = useState();
    const [apolloCache, setApolloCache] = useState();

    const httpLink = new HttpLink({
        uri: 'https://has.evernum.ru/v1/graphql',
        headers: {
            ...(groupId ? {'X-Hasura-Group-Id': groupId} : {}),
            'X-Hasura-access-token': token,
        }
    });

    // eslint-disable-next-line
    const activityMiddleware = new ApolloLink((operation, forward) => {
        // add the recent-activity custom header to the headers
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                'recent-activity': localStorage.getItem('lastOnlineTime') || null,
            }
        }));

        return forward(operation);
    })

    const wsLink = new GraphQLWsLink(createClient({
        url: "wss://has.evernum.ru/v1/graphql",
        connectionParams: {
            headers: {
                ...(groupId ? {'X-Hasura-Group-Id': groupId} : {}),
                'X-Hasura-access-token': token,
                'content-type': "application/json",
            },
            lazy: true,
        },
    }),);

    const splitLink = split(
        ({query}) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    useEffect(() => {
        async function init() {
            const cache = new ApolloCache({
                dataIdFromObject: (o) => {
                    // console.log({
                    //     __typename: o.__typename,
                    //     dataIdFromObject: o
                    // })
                    if (o.__typename === 'columns_tasks_statuses') {
                        if (o.status_option_id !== undefined && o.status_option_id !== null) {
                            return `${o.__typename}:${o.id}|${o.status_option_id}`
                        }
                    }
                    return `${o.__typename}:${o.id}`
                }
            }, {
                storage: idbStorages[0],
                serialize: false,
            });
            await cache.hydrate();

            setApolloCache(cache)
            const client = new ApolloClient({
                cache: cache,
                link: from([
                    activityMiddleware,
                    splitLink,
                ]),

                name: 'evernum-web-client',
                version: '0.1.0',
                queryDeduplication: false,
                defaultOptions,

                connectToDevTools: true,
            });
            setClient(client);
        }

        init().catch(console.error);
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (groupId !== undefined && groupId !== '' && client) {
            const client = new ApolloClient({
                cache: apolloCache,
                link: from([
                    activityMiddleware,
                    splitLink,
                ]),

                name: 'evernum-web-client',
                version: '0.1.0',
                queryDeduplication: false,
                defaultOptions,

                connectToDevTools: true,
            });
            setClient(client);
        }
        // eslint-disable-next-line
    }, [groupId])

    if (!client) {
        return <h2>Initializing app...</h2>;
    }

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}