import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Button, ButtonGroup, Divider} from "@mui/material";
import moment from 'moment';
import 'moment/locale/ru';
import {Draggable} from "react-beautiful-dnd";

moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'L'
    }
})

moment.updateLocale('ru', {
    calendar: {
        lastDay: '[Вчера] HH:mm',
        sameDay: '[Сегодня] HH:mm',
        nextDay: '[Завтра] HH:mm',
        lastWeek: 'DD MMMM HH:mm',
        nextWeek: 'DD MMMM HH:mm',
        sameElse: 'L'
    }
})

const CardBody = styled('div', {name: 'card-body'})(() => ({
    width: '100%'
}));

const ContactAvatar = styled(Avatar, {name: 'contact-avatar'})(() => ({
    width: 28,
    height: 28,
    color: '#6066FF',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px',

    background: 'rgba(96, 102, 255, 0.1)',
    border: '1px solid rgba(0, 0, 0, 0.04)',
}))

const ContactRow = styled(Stack, {name: 'contact-row'})(() => ({
    display: 'flex',
    float: 'left',
    gap: '8px',
    '& .name': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',

        display: 'flex',
        alignItems: 'center',

        /* Main/40LightTheme */
        color: '#0C0C13',

        flex: 'none',
        order: 0,
        flexGrow: '0',
    },
    '& .number': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',

        display: 'flex',
        alignItems: 'center',

        /* Main/40LightTheme */
        color: 'rgba(22, 22, 51, 0.82)',

        flex: 'none',
        order: 0,
        flexGrow: '0',
    }
}))

const TagsRow = styled('div', {name: 'tags-row'})(() => ({
    display: 'flex',
    float: 'left',
    gap: '4px',
}))

const TagItem = styled('div', {name: 'tag-item'})(({bgColor, isMax}) => ({
    backgroundColor: bgColor === '#undefined' ? 'rgba(72,72,141, 0.06)' : bgColor,
    borderRadius: 13,

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',

    color: '#FFFFFF',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px',
    gap: '5px',

    height: '22px',
    maxWidth: isMax ? '110px' : '',

    '& span': {
        maxWidth: 121,
        display: 'inline',

        whiteSpace: 'nowrap', /* Отменяем перенос текста */
        overflow: 'hidden', /* Обрезаем содержимое */
        textOverflow: 'ellipsis',
    }
}))

const ClientCardStyled = styled("div", {name: 'customer-card'})(({expanded, isDragging, draggableStyle, hidden}) => ({
    width: 336,

    opacity: hidden ? 0 : 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 10,
    gap: '4px',
    background: expanded ? 'rgba(255, 255, 255, 0.6)' : '#FFFFFF',
    borderRadius: '16px',

    /* Inside auto layout */

    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',

    marginBottom: 8,

    userSelect: "none",

    border: isDragging ? '1px solid #6066FF' : '',

    // styles we need to apply on draggables
    ...draggableStyle
}))

const StatusItem = styled('div', {name: 'status-item'})(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '2px',

    height: '16px',

    background: 'rgba(72, 72, 141, 0.06)',
    borderRadius: '10px',

    '& span': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '12px',

        color: 'rgba(22, 22, 51, 0.54)',
    }
}))

const AccordionStyled = styled(Accordion, {name: "accordion-CustomerCard"})(() => ({
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const AccordionSummaryStyled = styled(AccordionSummary, {name: 'accordionSummary-CustomerCard'})(() => ({
    padding: 0,
    background: 'none',
    '& .MuiAccordionSummary-content': {
        marginBottom: 12,
        background: 'none',
    },
}))

const AccordionDetailsStyled = styled(AccordionDetails, {name: 'AccordionDetails-CustomerCard'})(() => ({
    padding: 0,
    background: 'none',
}))

const List = styled('ul')(() => ({
    listStyleType: "*",
    listStyle: 'none',

    margin: 0,
    padding: 0,

    display: 'flex',
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 24,

    '& li': {
        listStyle: 'none',

        justifyContent: 'flex-start',
        display: 'flex',

        '& span': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '16px',

            color: '#6066FF',

            paddingLeft: 10,
        }
    }
}))

const HiddenInSystem = styled('div')(() => ({
    justifyContent: 'flex-start',
    display: 'flex',

    '& span': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '16px',

        color: '#6066FF',

        paddingLeft: 10,
        '&.closed': {
            color: 'rgba(22, 22, 51, 0.54)',
        }
    }
}))

const CustomerCard = ({content, index, columnsClientId}) => {
    const allTags = content?.clients_tags ?? []
    const mainTags = allTags?.slice(0, 2) // content?.clients_tags?.slice(0, 2)
    const tags = allTags?.slice(2) // content?.clients_tags?.slice(2)

    const clients_statuses = content?.clients_statuses ?? [] // statusesMock // content.clients_statuses
    const has_comments = true // content.has_comments
    const description = 'dfsdfsdf' // content.description
    const rec = true
    const attach = true
    const is_pinned = true
    const tasks = true

    const [expanded, setExpanded] = useState(false);

    return (
        <Draggable
            draggableId={`${columnsClientId}|${content?.id}`}
            index={index}
        >
            {(provided, snapshot) => (
                <ClientCardStyled
                    hidden={content === null}
                    expanded={expanded}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    draggableStyle={provided.draggableProps.style}
                >
                    <CardBody>
                        <Stack direction="column"
                               justifyContent="flex-start"
                               alignItems="stretch"
                               spacing={2}
                        >
                            <div style={{
                                display: 'flex',
                                float: 'left',
                                gap: '4px',
                                alignItems: 'center'
                            }}>
                                {
                                    clients_statuses.length > 0 ? (
                                        <StatusItem sx={{marginLeft: 0}}>
                                            {
                                                clients_statuses?.slice(0, 2)?.map((item, index) => {
                                                    return (
                                                        <svg key={index} width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="3.5" cy="4" r="3.5" fill={`#${item?.statuses_option?.color?.background ?? 'cccccc'}`}/>
                                                        </svg>
                                                    )
                                                })
                                            }
                                            {
                                                clients_statuses?.length > 2 ? (
                                                    <span>{`+ ${clients_statuses?.slice(2).length}`}</span>
                                                ) : ''
                                            }
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M7.5 1H2.5C1.67157 1 1 1.67157 1 2.5V7.5C1 8.32843 1.67157 9 2.5 9H7.5C8.32843 9 9 8.32843 9 7.5V2.5C9 1.67157 8.32843 1 7.5 1ZM2.5 0C1.11929 0 0 1.11929 0 2.5V7.5C0 8.88071 1.11929 10 2.5 10H7.5C8.88071 10 10 8.88071 10 7.5V2.5C10 1.11929 8.88071 0 7.5 0H2.5ZM2.5 5C2.5 5.27614 2.72386 5.5 3 5.5C3.27614 5.5 3.5 5.27614 3.5 5C3.5 4.72386 3.27614 4.5 3 4.5C2.72386 4.5 2.5 4.72386 2.5 5ZM3 3.5C2.17157 3.5 1.5 4.17157 1.5 5C1.5 5.82843 2.17157 6.5 3 6.5C3.82843 6.5 4.5 5.82843 4.5 5C4.5 4.17157 3.82843 3.5 3 3.5ZM5.5 4.5C5.22386 4.5 5 4.72386 5 5C5 5.27614 5.22386 5.5 5.5 5.5H7.75C8.02614 5.5 8.25 5.27614 8.25 5C8.25 4.72386 8.02614 4.5 7.75 4.5H5.5Z"
                                                      fill="#6066FF" fillOpacity="0.6"/>
                                            </svg>
                                        </StatusItem>
                                    ) : ''
                                }
                                <div style={{
                                    marginRight: '0px',
                                    marginLeft: 'auto',
                                }}/>
                                {
                                    rec ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.25 7.5C3.94036 7.5 4.5 6.94036 4.5 6.25C4.5 5.55964 3.94036 5 3.25 5C2.55964 5 2 5.55964 2 6.25C2 6.94036 2.55964 7.5 3.25 7.5ZM5.12111 7.5C5.36043 7.14248 5.5 6.71254 5.5 6.25C5.5 5.00736 4.49264 4 3.25 4C2.00736 4 1 5.00736 1 6.25C1 7.40814 1.87501 8.36191 3 8.48627V8.5H3.25H8.75H9V8.48627C10.125 8.36191 11 7.40814 11 6.25C11 5.00736 9.99264 4 8.75 4C7.50736 4 6.5 5.00736 6.5 6.25C6.5 6.71254 6.63957 7.14248 6.87889 7.5H5.12111ZM8.75 7.5C9.44036 7.5 10 6.94036 10 6.25C10 5.55964 9.44036 5 8.75 5C8.05964 5 7.5 5.55964 7.5 6.25C7.5 6.94036 8.05964 7.5 8.75 7.5Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                        </svg>
                                    ) : ''
                                }
                                {
                                    has_comments ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M8.59256 7.8439L9.52564 8.52925V3.99225C9.52564 3.16639 8.87129 2.4969 8.0641 2.4969H3.4359C2.62871 2.4969 1.97436 3.16639 1.97436 3.99225V6.34855C1.97436 7.17441 2.62871 7.8439 3.4359 7.8439H8.59256ZM8.27903 8.8408L9.72871 9.90558C10.051 10.1423 10.5 9.90664 10.5 9.50067V3.99225C10.5 2.61582 9.40941 1.5 8.0641 1.5H3.4359C2.09059 1.5 1 2.61582 1 3.99225V6.34855C1 7.72499 2.09059 8.8408 3.4359 8.8408H8.27903Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                        </svg>
                                    ) : ''
                                }
                                {
                                    description ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M7.5 2H5C3.89543 2 3 2.89543 3 4V8C3 9.10457 3.89543 10 5 10H7.5C8.60457 10 9.5 9.10457 9.5 8V4C9.5 2.89543 8.60457 2 7.5 2ZM5 1C3.34315 1 2 2.34315 2 4V8C2 9.65685 3.34315 11 5 11H7.5C9.15685 11 10.5 9.65685 10.5 8V4C10.5 2.34315 9.15685 1 7.5 1H5Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M4 5C4 4.72386 4.22386 4.5 4.5 4.5H7.5C7.77614 4.5 8 4.72386 8 5C8 5.27614 7.77614 5.5 7.5 5.5H4.5C4.22386 5.5 4 5.27614 4 5Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M4 7C4 6.72386 4.22386 6.5 4.5 6.5H6C6.27614 6.5 6.5 6.72386 6.5 7C6.5 7.27614 6.27614 7.5 6 7.5H4.5C4.22386 7.5 4 7.27614 4 7Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                        </svg>
                                    ) : ''
                                }
                                {
                                    tasks ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M3.6464 5.8055C3.84166 5.61024 4.15824 5.61024 4.35351 5.8055L5.59094 7.04294L7.8964 4.73748C8.09166 4.54222 8.40824 4.54222 8.60351 4.73748C8.79877 4.93275 8.79877 5.24933 8.60351 5.44459L6.29805 7.75005C5.90753 8.14057 5.27436 8.14057 4.88384 7.75005L3.6464 6.51261C3.45114 6.31735 3.45114 6.00077 3.6464 5.8055Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                        </svg>
                                    ) : ''
                                }
                                {
                                    attach ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M6.25 10.08C4.73122 10.08 3.5 8.89807 3.5 7.44004L3.5 3.72004C3.5 2.72593 4.33947 1.92004 5.375 1.92004C6.41053 1.92004 7.25 2.72593 7.25 3.72004L7.25 7.20004C7.25 7.73023 6.80228 8.16004 6.25 8.16004C5.69772 8.16004 5.25 7.73023 5.25 7.20004L5.25 3.84004C5.25 3.57494 5.02614 3.36004 4.75 3.36004C4.47386 3.36004 4.25 3.57494 4.25 3.84004L4.25 7.20004C4.25 8.26043 5.14543 9.12004 6.25 9.12004C7.35457 9.12004 8.25 8.26043 8.25 7.20004L8.25 3.72004C8.25 2.19573 6.96282 0.960039 5.375 0.960039C3.78718 0.960039 2.5 2.19573 2.5 3.72004L2.5 7.44004C2.5 9.42826 4.17893 11.04 6.25 11.04C8.32107 11.04 10 9.42826 10 7.44004L10 5.04004C10 4.77494 9.77614 4.56004 9.5 4.56004C9.22386 4.56004 9 4.77494 9 5.04004L9 7.44004C9 8.89807 7.76878 10.08 6.25 10.08Z"
                                                  fill="#6066FF" fillOpacity="0.6"/>
                                        </svg>
                                    ) : ''
                                }
                                {
                                    is_pinned ? (
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_64_10213)">
                                                <path d="M1.22694 10.8828L5 6L6.24997 7.24998L1.36721 11.0231C1.27437 11.0948 1.1552 10.9756 1.22694 10.8828Z"
                                                      fill="url(#paint0_linear_64_10213)"/>
                                                <path d="M1.22694 10.8828L5 6L6.24997 7.24998L1.36721 11.0231C1.27437 11.0948 1.1552 10.9756 1.22694 10.8828Z"
                                                      fill="url(#paint1_linear_64_10213)"/>
                                                <g filter="url(#filter0_f_64_10213)">
                                                    <path d="M1.32412 11.0597L10.4499 7.5498L18.45 10.0498L1.36704 11.2528C1.25245 11.2609 1.2169 11.101 1.32412 11.0597Z"
                                                          fill="url(#paint2_linear_64_10213)"/>
                                                </g>
                                                <path d="M1.32412 11.0597L10.4499 7.5498L18.45 10.0498L1.36704 11.2528C1.25245 11.2609 1.2169 11.101 1.32412 11.0597Z"
                                                      fill="url(#paint3_linear_64_10213)"/>
                                                <path
                                                    d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664L7.12491 8.125L6.24991 7.25L6.24991 6.7571C6.24991 6.64605 6.22331 6.53281 6.17924 6.43087C6.11254 6.27655 5.195 5.80491 5.07612 5.92379L4.99991 6L4.12491 5.125L7.39327 1.85664L7.66149 0.809088C7.83395 0.540812 8.21011 0.500397 8.43563 0.725914L11.5626 3.85285Z"
                                                    fill="url(#paint4_linear_64_10213)"/>
                                                <path
                                                    d="M8.0105 9.11011C8.31556 9.41517 8.83786 9.2166 8.86319 8.78592L9.0142 6.2188C7.74988 5.74992 6.24988 4.49992 6.06959 3.27418L3.50246 3.42519C3.07178 3.45053 2.87321 3.97282 3.17827 4.27788L8.0105 9.11011Z"
                                                    fill="url(#paint5_linear_64_10213)"/>
                                                <path
                                                    d="M8.0105 9.11011C8.31556 9.41517 8.83786 9.2166 8.86319 8.78592L9.0142 6.2188C7.74988 5.74992 6.24988 4.49992 6.06959 3.27418L3.50246 3.42519C3.07178 3.45053 2.87321 3.97282 3.17827 4.27788L8.0105 9.11011Z"
                                                    fill="url(#paint6_linear_64_10213)"/>
                                                <path
                                                    d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664C9.24991 3.85285 8.24991 3 7.39327 1.85664L7.66149 0.809088C7.83395 0.540811 8.21011 0.500396 8.43563 0.725914L11.5626 3.85285Z"
                                                    fill="url(#paint7_linear_64_10213)"/>
                                                <path
                                                    d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664C9.24991 3.85285 8.24991 3 7.39327 1.85664L7.66149 0.809088C7.83395 0.540811 8.21011 0.500396 8.43563 0.725914L11.5626 3.85285Z"
                                                    fill="url(#paint8_linear_64_10213)"/>
                                            </g>
                                            <defs>
                                                <filter id="filter0_f_64_10213" x="0.259766" y="6.5498" width="19.1902" height="5.70312" filterUnits="userSpaceOnUse"
                                                        colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_10213"/>
                                                </filter>
                                                <linearGradient id="paint0_linear_64_10213" x1="2.99997" y1="7.5" x2="4.49997" y2="9" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#616A80"/>
                                                    <stop offset="0.552083" stopColor="#C9D4F0"/>
                                                    <stop offset="1" stopColor="#AEBBDC"/>
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_64_10213" x1="5.49997" y1="6.75" x2="4.24997" y2="7.75" gradientUnits="userSpaceOnUse">
                                                    <stop stopOpacity="0.37"/>
                                                    <stop offset="1" stopOpacity="0"/>
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_64_10213" x1="0.699983" y1="10.7998" x2="11.1773" y2="11.4183" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#C5CAD7"/>
                                                    <stop offset="1" stopColor="#AEBBDC" stopOpacity="0"/>
                                                </linearGradient>
                                                <linearGradient id="paint3_linear_64_10213" x1="-1.80005" y1="11.7998" x2="3.93717" y2="11.8288" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#C5CAD7"/>
                                                    <stop offset="1" stopColor="#AEBBDC" stopOpacity="0"/>
                                                </linearGradient>
                                                <linearGradient id="paint4_linear_64_10213" x1="9.73881" y1="6.384" x2="5.90449" y2="2.54967" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0.0885417" stopColor="#FF92B2"/>
                                                    <stop offset="0.3125" stopColor="#FF1F62"/>
                                                    <stop offset="0.604167" stopColor="#FF879D"/>
                                                    <stop offset="0.776042" stopColor="#FF1F62"/>
                                                    <stop offset="0.90625" stopColor="#FF7EA5"/>
                                                </linearGradient>
                                                <linearGradient id="paint5_linear_64_10213" x1="8.74988" y1="9.24992" x2="4.24988" y2="2.24992" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0.0885417" stopColor="#FF92B2"/>
                                                    <stop offset="0.3125" stopColor="#FF1F62"/>
                                                    <stop offset="0.604167" stopColor="#FF879D"/>
                                                    <stop offset="0.776042" stopColor="#FF1F62"/>
                                                    <stop offset="0.937972" stopColor="#FF7EA5"/>
                                                </linearGradient>
                                                <linearGradient id="paint6_linear_64_10213" x1="8.99988" y1="5.99992" x2="3.99988" y2="5.74992" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#CE003E"/>
                                                    <stop offset="1" stopColor="#FF4779" stopOpacity="0"/>
                                                </linearGradient>
                                                <linearGradient id="paint7_linear_64_10213" x1="9.73881" y1="6.384" x2="5.90449" y2="2.54967" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FF92B2"/>
                                                    <stop offset="0.3125" stopColor="#FF1F62"/>
                                                    <stop offset="0.604167" stopColor="#FF879D"/>
                                                    <stop offset="0.776042" stopColor="#FF1F62"/>
                                                    <stop offset="1" stopColor="#FF7EA5"/>
                                                </linearGradient>
                                                <linearGradient id="paint8_linear_64_10213" x1="8.99991" y1="3.75" x2="11.2499" y2="3" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#DB1551" stopOpacity="0.49"/>
                                                    <stop offset="1" stopColor="#FFB6C4" stopOpacity="0.22"/>
                                                </linearGradient>
                                                <clipPath id="clip0_64_10213">
                                                    <rect width="12" height="12" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    ) : ''
                                }
                                {/*<Avatar alt={content?.profile?.name} src={content?.profile?.avatar_id} sx={{width: 24, height: 24, marginLeft: '4px'}}/>*/}
                            </div>
                            <ContactRow direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}>
                                <ContactAvatar
                                    alt={content?.name ?? content?.main_number}
                                    src={''}
                                >
                                    {content?.name ?? content?.main_number}
                                </ContactAvatar>
                                <Stack>
                                    {
                                        content?.name ? <span className={'name'}>{content?.name}</span> : ''
                                    }
                                    <span className={'number'}>{content?.main_number}</span>
                                </Stack>
                            </ContactRow>
                            <AccordionStyled expanded={expanded} onChange={() => {
                                setExpanded(!expanded)
                            }} sx={{
                                padding: 0
                            }}>
                                <AccordionSummaryStyled expandIcon={(<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M7.84812 0.853553C8.05063 0.658291 8.05063 0.341709 7.84812 0.146447C7.64562 -0.0488154 7.3173 -0.0488157 7.1148 0.146446L3.99999 3.14988L0.885203 0.146461C0.6827 -0.0488008 0.354379 -0.0488008 0.151876 0.146461C-0.0506268 0.341723 -0.0506268 0.658306 0.151875 0.853568L3.5605 4.14032C3.5793 4.16759 3.60116 4.19347 3.6261 4.21751C3.72905 4.31679 3.86453 4.36559 3.99946 4.36392C4.13474 4.36587 4.27067 4.31707 4.3739 4.21753C4.39886 4.19346 4.42075 4.16755 4.43956 4.14024L7.84812 0.853553Z"
                                          fill="#161633" fillOpacity="0.82"/>
                                </svg>)}>
                                    <TagsRow>
                                        {
                                            mainTags?.slice(0, 2)?.map((tag) => {
                                                return (
                                                    <TagItem bgColor={`#${tag?.tags_list?.color?.background}`} key={tag?.tags_list?.id} isMax={tags.length > 2}>
                                                        <span style={{
                                                            color: tag?.tags_list?.color?.foreground === undefined ? 'rgba(22,22,51, 0.54)' : `#${tag?.tags_list?.color?.foreground}`
                                                        }}>{tag?.tags_list?.value}</span>
                                                    </TagItem>
                                                )
                                            })
                                        }
                                        {
                                            tags.length > 2 ? (
                                                <TagItem bgColor={'rgba(72, 72, 141, 0.06)'}>
                                                    <span style={{color: 'rgba(22, 22, 51, 0.54)'}}>+{allTags?.length - 2}</span>
                                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        {
                                                            tags?.map((tag, index) => {
                                                                if (index === 0) return (
                                                                    <circle key={index} cx="3" cy="5" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                                if (index === 1) return (
                                                                    <circle key={index} cx="3" cy="13" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                                if (index === 2) return (
                                                                    <circle key={index} cx="11" cy="5" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                                if (index === 3) return (
                                                                    <circle key={index} cx="11" cy="13" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                                return ''
                                                            })
                                                        }
                                                    </svg>
                                                </TagItem>
                                            ) : ''
                                        }
                                    </TagsRow>
                                </AccordionSummaryStyled>
                                <AccordionDetailsStyled>
                                    <Divider light sx={{marginTop: '0px', marginBottom: '16px'}}/>
                                    <ButtonGroup
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            borderRadius: '16px',
                                            '& button': {
                                                background: 'rgba(0, 209, 121, 0.1)',
                                                borderRadius: '16px',
                                                borderColor: 'rgba(255, 255, 255, 0.6) !important',

                                                '&:hover': {
                                                    background: 'rgba(0, 209, 121, 0.2)',
                                                }
                                            }
                                        }}
                                    >
                                        <Button>
                                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M4.08588 5.53255L4.85151 4.76692C5.05476 4.56368 5.20198 4.31132 5.27889 4.03437C5.49596 3.25272 5.54997 2.60843 5.44093 2.1015C5.35181 1.68715 5.1211 1.27739 4.7488 0.872229C4.43318 0.528746 3.98805 0.333252 3.52157 0.333252H2.22264C1.46496 0.333252 0.850739 0.947471 0.850739 1.70515C0.850739 1.73042 0.851437 1.75569 0.852833 1.78092C1.20447 8.13779 6.2693 13.2362 12.6119 13.6407C13.4175 13.6921 14.1123 13.0806 14.1637 12.275C14.1656 12.244 14.1666 12.213 14.1667 12.182V11.154C14.1667 10.5973 13.8887 10.0774 13.4258 9.76813C12.9542 9.45307 12.5099 9.25626 12.0929 9.1777C11.6113 9.08697 11.0946 9.10042 10.5428 9.21806C10.228 9.28516 9.93942 9.44198 9.71182 9.66957L9.15746 10.2239C8.68888 10.6925 7.95645 10.7696 7.40055 10.4089C5.99332 9.49578 4.80267 8.27721 3.92918 6.84561C3.67207 6.42422 3.73682 5.8816 4.08588 5.53255Z"
                                                      fill="#00D179"/>
                                            </svg>
                                        </Button>
                                        <Button sx={{
                                            width: '44px',
                                            height: '44px',
                                            padding: '0',
                                        }}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M4.08005 17.9037C4.8791 16.5752 4.81411 15.5765 4.25252 14.7377C4.17458 14.6212 4.0912 14.5157 4.00155 14.4156C3.94771 14.3554 3.78934 14.1922 3.80642 14.2104C2.43863 12.75 1.66666 10.8564 1.66666 8.84801C1.66666 4.41345 5.40568 0.833251 10 0.833251C14.5943 0.833252 18.3333 4.41345 18.3333 8.84801C18.3333 13.2826 14.5943 16.8628 10 16.8628C9.91193 16.8628 9.8252 16.8618 9.70508 16.8598L9.62761 16.8584C7.9765 16.8292 7.1716 17.1304 5.38406 18.9219C4.68918 19.6183 3.57298 18.7468 4.08005 17.9037ZM9.65706 15.192L9.73318 15.1933C9.84473 15.1952 9.92317 15.1961 9.99999 15.1961C13.6896 15.1961 16.6667 12.3455 16.6667 8.84801C16.6667 5.3505 13.6896 2.49992 9.99999 2.49992C6.31036 2.49992 3.33333 5.3505 3.33333 8.84801C3.33333 10.4267 3.94008 11.915 5.02289 13.0711C4.98561 13.0313 5.16271 13.2139 5.24304 13.3036C5.38212 13.4589 5.51328 13.625 5.63749 13.8105C6.07181 14.4593 6.32342 15.185 6.34192 15.9745C7.39514 15.3387 8.3644 15.1692 9.65706 15.192ZM13.75 9.99992C13.0596 9.99992 12.5 9.44027 12.5 8.74992C12.5 8.05956 13.0596 7.49992 13.75 7.49992C14.4404 7.49992 15 8.05956 15 8.74992C15 9.44027 14.4404 9.99992 13.75 9.99992ZM8.74999 8.74992C8.74999 9.44027 9.30964 9.99992 9.99999 9.99992C10.6904 9.99992 11.25 9.44027 11.25 8.74992C11.25 8.05956 10.6904 7.49992 9.99999 7.49992C9.30964 7.49992 8.74999 8.05956 8.74999 8.74992ZM6.24999 9.99992C5.55964 9.99992 4.99999 9.44027 4.99999 8.74992C4.99999 8.05956 5.55964 7.49992 6.24999 7.49992C6.94035 7.49992 7.49999 8.05956 7.49999 8.74992C7.49999 9.44027 6.94035 9.99992 6.24999 9.99992Z"
                                                      fill="#00D179"/>
                                            </svg>
                                        </Button>
                                        <Button sx={{
                                            width: '44px',
                                            height: '44px',
                                            padding: '0',
                                        }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M9.15424 0.666748C4.63003 0.666748 0.965793 4.30244 0.965793 8.7914C0.965793 10.3217 1.39578 11.7593 2.14358 12.9836L0.666672 17.3334L5.20023 15.8958C6.36867 16.5358 7.72407 16.9068 9.15424 16.9068C13.6785 16.9068 17.3427 13.2711 17.3427 8.78213C17.3333 4.30244 13.6785 0.666748 9.15424 0.666748ZM9.15424 15.5434C7.7708 15.5434 6.48084 15.1353 5.40588 14.4212L2.78856 15.2559L3.63919 12.7424C2.82595 11.6295 2.33988 10.2568 2.33988 8.78213C2.33988 5.05369 5.39653 2.02086 9.15424 2.02086C12.912 2.02086 15.9686 5.05369 15.9686 8.78213C15.9686 12.5106 12.912 15.5434 9.15424 15.5434ZM12.9867 10.6278C12.7811 10.5165 11.7716 9.97856 11.5846 9.90437C11.3976 9.83017 11.2574 9.79307 11.1079 9.98784C10.9583 10.1919 10.547 10.6371 10.4162 10.7762C10.2853 10.906 10.1638 10.9246 9.95813 10.8133C9.75248 10.702 9.08881 10.4609 8.31296 9.71887C7.72407 9.15312 7.33147 8.45751 7.2193 8.24419C7.10713 8.03087 7.2193 7.91958 7.33147 7.82683C7.42494 7.73408 7.54646 7.59496 7.65863 7.47439C7.7708 7.35382 7.80819 7.27035 7.88297 7.1405C7.95775 7.00138 7.92971 6.88081 7.88297 6.77879C7.83624 6.67677 7.45299 5.638 7.29408 5.22063C7.13517 4.80327 6.95757 4.85892 6.83605 4.85892C6.71453 4.85892 6.57432 4.8311 6.43411 4.8311C6.29389 4.82182 6.06955 4.8682 5.8639 5.07224C5.66761 5.27628 5.10675 5.74929 5.06936 6.76951C5.03197 7.78973 5.74239 8.80068 5.84521 8.94907C5.94803 9.08819 7.2006 11.3048 9.29445 12.2138C11.3883 13.1227 11.3976 12.8445 11.7809 12.8259C12.1641 12.8074 13.0335 12.3714 13.2298 11.8892C13.4261 11.4161 13.4354 10.9988 13.3887 10.906C13.3326 10.804 13.1924 10.7391 12.9867 10.6278Z"
                                                      fill="#00D179"/>
                                            </svg>
                                        </Button>
                                        <Button sx={{
                                            width: '44px',
                                            height: '44px',
                                            padding: '0',
                                        }}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.3022 1.66675C5.88234 1.66675 2.28677 5.26232 2.28677 9.68214C2.28677 11.02 2.62275 12.3366 3.27066 13.5255L1.71434 17.4177C1.61548 17.6655 1.67293 17.9487 1.86262 18.1377C1.9902 18.2653 2.1612 18.3334 2.33486 18.3334C2.41836 18.3334 2.50252 18.3181 2.58267 18.2853L6.48149 16.7257C7.64974 17.3629 8.96426 17.6975 10.3022 17.6975C14.722 17.6975 18.3176 14.102 18.3176 9.68214C18.3176 5.26232 14.722 1.66675 10.3022 1.66675ZM10.3022 16.3616C9.09184 16.3616 7.90623 16.033 6.87224 15.4111C6.68789 15.3003 6.46279 15.2856 6.26708 15.3691L3.5325 16.4672L4.54912 13.9236L4.62192 13.7626C4.70274 13.583 4.70074 13.3766 4.61591 13.1989C4.59788 13.1601 4.57383 13.1161 4.54711 13.072C3.94262 12.0473 3.62267 10.8751 3.62267 9.68214C3.62267 5.99907 6.61909 3.00265 10.3022 3.00265C13.9852 3.00265 16.9817 5.99907 16.9817 9.68214C16.9817 13.3652 13.9852 16.3616 10.3022 16.3616ZM13.4175 7.80453C13.3139 6.61024 12.2379 5.67444 10.9701 5.67444H8.29831C7.92894 5.67444 7.63037 5.97368 7.63037 6.34239V13.0219C7.63037 13.3906 7.92894 13.6898 8.29831 13.6898H10.9701C12.2379 13.6898 13.3139 12.754 13.4175 11.5597C13.4743 10.9058 13.2538 10.2546 12.8123 9.77298C12.7843 9.74225 12.7549 9.7122 12.7248 9.68281C12.7549 9.65342 12.7843 9.62336 12.8123 9.59263C13.2538 9.11037 13.4743 8.45846 13.4175 7.80453ZM10.9701 12.3539H8.96626V10.3501H11.089C11.3736 10.3501 11.6354 10.4656 11.8278 10.6754C12.0195 10.8844 12.1116 11.1576 12.0869 11.4435C12.0428 11.9458 11.5419 12.3539 10.9701 12.3539ZM11.8278 8.6889C11.6354 8.89863 11.3736 9.01419 11.089 9.01419H8.96626V7.01034H10.9701C11.5419 7.01034 12.0428 7.41846 12.0869 7.92076C12.1116 8.20664 12.0195 8.47983 11.8278 8.6889Z"
                                                    fill="#00D179"/>
                                            </svg>
                                        </Button>
                                        <Button sx={{
                                            width: '44px',
                                            height: '44px',
                                            padding: '0',
                                            background: 'rgba(72, 72, 141, 0.06) !important',
                                            '&:hover': {
                                                background: 'rgba(72, 72, 141, 0.08) !important',
                                            }
                                        }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M13 0.5H8C6.61929 0.5 5.5 1.61929 5.5 3V8C5.5 9.38071 6.61929 10.5 8 10.5H13C14.3807 10.5 15.5 9.38071 15.5 8V3C15.5 1.61929 14.3807 0.5 13 0.5ZM7.16667 2.72222C7.16667 2.4154 7.53976 2.16667 8 2.16667H13C13.4602 2.16667 13.8333 2.4154 13.8333 2.72222V8.27778C13.8333 8.5846 13.4602 8.83333 13 8.83333H8C7.53976 8.83333 7.16667 8.5846 7.16667 8.27778V2.72222Z"
                                                      fill="#161633" fillOpacity="0.24"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.5 5.5H3C1.61929 5.5 0.5 6.61929 0.5 8V13C0.5 14.3807 1.61929 15.5 3 15.5H8C9.38071 15.5 10.5 14.3807 10.5 13V10.5H8.83333V13.2778C8.83333 13.5846 8.46024 13.8333 8 13.8333H3C2.53976 13.8333 2.16667 13.5846 2.16667 13.2778V7.72222C2.16667 7.4154 2.53976 7.16667 3 7.16667H5.5V5.5Z"
                                                      fill="#161633" fillOpacity="0.24"/>
                                            </svg>
                                        </Button>
                                    </ButtonGroup>

                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={'0px'}
                                        sx={{
                                            marginTop: '24px',
                                            marginBottom: '32px',
                                        }}
                                    >
                                        <List>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M8 13.3333C10.9455 13.3333 13.3333 10.9454 13.3333 7.99992C13.3333 5.0544 10.9455 2.66659 8 2.66659C5.05448 2.66659 2.66667 5.0544 2.66667 7.99992C2.66667 10.9454 5.05448 13.3333 8 13.3333ZM8 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8 1.33325C4.3181 1.33325 1.33333 4.31802 1.33333 7.99992C1.33333 11.6818 4.3181 14.6666 8 14.6666Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M4.86193 7.74051C5.12228 7.48016 5.54439 7.48016 5.80474 7.74051L7.45465 9.39043L10.5286 6.31648C10.7889 6.05613 11.2111 6.05613 11.4714 6.31648C11.7318 6.57683 11.7318 6.99894 11.4714 7.25929L8.39746 10.3332C7.87676 10.8539 7.03254 10.8539 6.51184 10.3332L4.86193 8.68332C4.60158 8.42297 4.60158 8.00086 4.86193 7.74051Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Задача</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M11.4568 10.4585L12.7009 11.3723V5.323C12.7009 4.22185 11.8284 3.3292 10.7521 3.3292H4.5812C3.50495 3.3292 2.63248 4.22185 2.63248 5.323V8.46474C2.63248 9.56588 3.50495 10.4585 4.5812 10.4585H11.4568ZM11.0387 11.7877L12.9716 13.2074C13.4014 13.5231 14 13.2088 14 12.6676V5.323C14 3.48776 12.5459 2 10.7521 2H4.5812C2.78745 2 1.33333 3.48776 1.33333 5.323V8.46474C1.33333 10.3 2.78745 11.7877 4.5812 11.7877H11.0387Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Комментарий</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_9_73661)">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M8.20965 3.11511L2.95828 8.36648C2.41194 8.91282 2.41194 9.79861 2.95828 10.3449L5.62574 13.0124C6.17208 13.5587 7.05786 13.5587 7.6042 13.0124L12.7923 7.82429C13.0948 7.52176 13.2423 7.09742 13.1926 6.67247L12.7547 2.9305L9.28841 2.70823C8.88705 2.68249 8.49403 2.83073 8.20965 3.11511ZM1.96905 7.37725C0.876373 8.46992 0.876373 10.2415 1.96905 11.3342L4.6365 14.0016C5.72918 15.0943 7.50076 15.0943 8.59344 14.0016L13.7815 8.81353C14.3866 8.20846 14.6815 7.35977 14.5821 6.50988L14.1442 2.7679C14.0657 2.09672 13.5186 1.57762 12.8442 1.53438L9.37793 1.31211C8.57522 1.26063 7.78918 1.55711 7.22042 2.12588L1.96905 7.37725Z"
                                                              fill="#6066FF" fillOpacity="0.6"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_9_73661">
                                                            <rect width="16" height="16" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span>Новый тег</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M10 2.66659H6.66667C5.19391 2.66659 4 3.86049 4 5.33325V10.6666C4 12.1393 5.19391 13.3333 6.66667 13.3333H10C11.4728 13.3333 12.6667 12.1393 12.6667 10.6666V5.33325C12.6667 3.86049 11.4728 2.66659 10 2.66659ZM6.66667 1.33325C4.45753 1.33325 2.66667 3.12411 2.66667 5.33325V10.6666C2.66667 12.8757 4.45753 14.6666 6.66667 14.6666H10C12.2091 14.6666 14 12.8757 14 10.6666V5.33325C14 3.12411 12.2091 1.33325 10 1.33325H6.66667Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M5.33333 6.66667C5.33333 6.29848 5.63181 6 6 6H10C10.3682 6 10.6667 6.29848 10.6667 6.66667C10.6667 7.03486 10.3682 7.33333 10 7.33333H6C5.63181 7.33333 5.33333 7.03486 5.33333 6.66667Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M5.33333 9.33341C5.33333 8.96522 5.63181 8.66675 6 8.66675H8C8.36819 8.66675 8.66667 8.96522 8.66667 9.33341C8.66667 9.7016 8.36819 10.0001 8 10.0001H6C5.63181 10.0001 5.33333 9.7016 5.33333 9.33341Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Описание</span>
                                            </li>
                                        </List>
                                        <List>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M12.6667 4.00008H3.33334C2.59696 4.00008 2.00001 4.59703 2.00001 5.33341V10.6667C2.00001 11.4031 2.59696 12.0001 3.33334 12.0001H12.6667C13.4031 12.0001 14 11.4031 14 10.6667V5.33341C14 4.59703 13.4031 4.00008 12.6667 4.00008ZM3.33334 2.66675C1.86058 2.66675 0.666672 3.86066 0.666672 5.33341V10.6667C0.666672 12.1395 1.86058 13.3334 3.33334 13.3334H12.6667C14.1394 13.3334 15.3333 12.1395 15.3333 10.6667V5.33341C15.3333 3.86066 14.1394 2.66675 12.6667 2.66675H3.33334Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M8.66667 7.33341C8.66667 6.96522 8.96515 6.66675 9.33334 6.66675L12 6.66675C12.3682 6.66675 12.6667 6.96523 12.6667 7.33341C12.6667 7.7016 12.3682 8.00008 12 8.00008L9.33334 8.00008C8.96515 8.00008 8.66667 7.7016 8.66667 7.33341Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M8.66667 9.33341C8.66667 8.96522 8.96515 8.66675 9.33334 8.66675L10.6667 8.66675C11.0349 8.66675 11.3333 8.96523 11.3333 9.33341C11.3333 9.7016 11.0349 10.0001 10.6667 10.0001L9.33334 10.0001C8.96515 10.0001 8.66667 9.7016 8.66667 9.33341Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Отправить визитку</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M13.6059 6.38324L8.66561 2.79391C8.43195 2.62414 8.11556 2.62414 7.8819 2.79391L2.94161 6.38324C2.70795 6.553 2.61018 6.85391 2.69943 7.12859L4.58645 12.9363C4.6757 13.2109 4.93167 13.3969 5.22049 13.3969H11.327C11.6158 13.3969 11.8718 13.2109 11.9611 12.9363L13.8481 7.1286C13.9373 6.85391 13.8396 6.553 13.6059 6.38324ZM9.44933 1.71522C8.74835 1.20593 7.79916 1.20593 7.09819 1.71522L2.15789 5.30455C1.45692 5.81384 1.1636 6.71657 1.43135 7.54062L3.31838 13.3483C3.58612 14.1723 4.35403 14.7302 5.22049 14.7302H11.327C12.1935 14.7302 12.9614 14.1723 13.2291 13.3483L15.1162 7.54062C15.3839 6.71657 15.0906 5.81384 14.3896 5.30455L9.44933 1.71522Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M6.13568 11.0564C5.87533 10.796 5.87533 10.3739 6.13568 10.1136L9.85954 6.3897C10.1199 6.12935 10.542 6.12935 10.8023 6.3897C11.0627 6.65005 11.0627 7.07216 10.8023 7.33251L7.07849 11.0564C6.81814 11.3167 6.39603 11.3167 6.13568 11.0564Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path
                                                        d="M7.94043 7.19434C7.94043 7.74662 7.49271 8.19434 6.94043 8.19434C6.38814 8.19434 5.94043 7.74662 5.94043 7.19434C5.94043 6.64205 6.38814 6.19434 6.94043 6.19434C7.49271 6.19434 7.94043 6.64205 7.94043 7.19434Z"
                                                        fill="#6066FF" fillOpacity="0.6"/>
                                                    <path
                                                        d="M10.6071 10.5278C10.6071 11.0801 10.1594 11.5278 9.60709 11.5278C9.0548 11.5278 8.60709 11.0801 8.60709 10.5278C8.60709 9.97555 9.0548 9.52783 9.60709 9.52783C10.1594 9.52783 10.6071 9.97555 10.6071 10.5278Z"
                                                        fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Отправить предложение</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M9.34818 6.55636C9.85057 5.77635 10.1333 4.83189 10.1333 4.18245C10.1333 3.00425 9.17821 2.04912 8 2.04912C6.8218 2.04912 5.86667 3.00425 5.86667 4.18245C5.86667 4.83189 6.14944 5.77635 6.65183 6.55636C7.18398 7.38258 7.70297 7.64912 8 7.64912C8.29704 7.64912 8.81603 7.38258 9.34818 6.55636ZM8 8.84912C9.84095 8.84912 11.3333 6.0234 11.3333 4.18245C11.3333 2.34151 9.84095 0.849121 8 0.849121C6.15906 0.849121 4.66667 2.34151 4.66667 4.18245C4.66667 6.0234 6.15906 8.84912 8 8.84912Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M2.06667 13.5159C2.06667 11.3435 3.82768 9.58252 6 9.58252H10C12.1723 9.58252 13.9333 11.3435 13.9333 13.5159C13.9333 14.2154 13.3662 14.7825 12.6667 14.7825H10.6667C10.3353 14.7825 10.0667 14.5139 10.0667 14.1825C10.0667 13.8511 10.3353 13.5825 10.6667 13.5825H12.6667C12.7035 13.5825 12.7333 13.5527 12.7333 13.5159C12.7333 12.0063 11.5096 10.7825 10 10.7825H6C4.49042 10.7825 3.26667 12.0063 3.26667 13.5159C3.26667 13.5527 3.29651 13.5825 3.33333 13.5825H8C8.33137 13.5825 8.6 13.8511 8.6 14.1825C8.6 14.5139 8.33137 14.7825 8 14.7825H3.33333C2.63377 14.7825 2.06667 14.2154 2.06667 13.5159Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Профиль</span>
                                            </li>
                                            <li>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M11.3333 2.66659H4.66666C3.56209 2.66659 2.66666 3.56202 2.66666 4.66659V11.3333C2.66666 12.4378 3.56209 13.3333 4.66666 13.3333H11.3333C12.4379 13.3333 13.3333 12.4378 13.3333 11.3333V4.66659C13.3333 3.56202 12.4379 2.66659 11.3333 2.66659ZM4.66666 1.33325C2.82571 1.33325 1.33333 2.82564 1.33333 4.66659V11.3333C1.33333 13.1742 2.82571 14.6666 4.66666 14.6666H11.3333C13.1743 14.6666 14.6667 13.1742 14.6667 11.3333V4.66659C14.6667 2.82564 13.1743 1.33325 11.3333 1.33325H4.66666ZM4.66666 7.99992C4.66666 8.36811 4.96514 8.66659 5.33333 8.66659C5.70152 8.66659 5.99999 8.36811 5.99999 7.99992C5.99999 7.63173 5.70152 7.33325 5.33333 7.33325C4.96514 7.33325 4.66666 7.63173 4.66666 7.99992ZM5.33333 5.99992C4.22876 5.99992 3.33333 6.89535 3.33333 7.99992C3.33333 9.10449 4.22876 9.99992 5.33333 9.99992C6.4379 9.99992 7.33333 9.10449 7.33333 7.99992C7.33333 6.89535 6.4379 5.99992 5.33333 5.99992ZM8.66666 7.33325C8.29847 7.33325 7.99999 7.63173 7.99999 7.99992C7.99999 8.36811 8.29847 8.66659 8.66666 8.66659H11.6667C12.0349 8.66659 12.3333 8.36811 12.3333 7.99992C12.3333 7.63173 12.0349 7.33325 11.6667 7.33325H8.66666Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                                <span>Статус</span>
                                            </li>
                                        </List>
                                    </Stack>
                                    <HiddenInSystem>
                                        {
                                            content?.main_number % 2 ? (
                                                <>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M14.4484 2.26804C14.6686 2.51571 14.6463 2.89495 14.3986 3.1151L2.39862 13.7818C2.15095 14.0019 1.77171 13.9796 1.55156 13.7319C1.3314 13.4843 1.35371 13.105 1.60138 12.8849L3.62757 11.0838C2.49198 10.2524 1.6342 9.27865 1.14146 8.65125C0.834468 8.26036 0.820963 7.73086 1.11356 7.32907C2.05392 6.03778 4.44744 3.33333 8 3.33333C9.22701 3.33333 10.3471 3.70749 11.3225 4.24391L13.6014 2.21821C13.8491 1.99806 14.2283 2.02037 14.4484 2.26804ZM5.73589 9.20976L4.49651 10.3114C4.45288 10.2812 4.40954 10.2506 4.36648 10.2197C3.3215 9.46908 2.51713 8.56537 2.04823 7.97089C2.96235 6.72372 5.05127 4.46667 8 4.46667C8.85412 4.46667 9.66502 4.69121 10.4148 5.05075L9.46668 5.8935C9.0511 5.60372 8.54526 5.43333 8 5.43333C6.58247 5.43333 5.43333 6.58247 5.43333 8C5.43333 8.43712 5.54293 8.84924 5.73589 9.20976ZM6.62805 8.41673L8.5747 6.68638C8.39876 6.6093 8.20445 6.56666 8 6.56666C7.20839 6.56666 6.56666 7.20839 6.56666 8C6.56666 8.14508 6.5881 8.28495 6.62805 8.41673ZM6.40752 11.2776C6.9172 11.4401 7.44963 11.5333 8 11.5333C10.9487 11.5333 13.0377 9.27628 13.9518 8.02911C13.5629 7.5361 12.9433 6.83041 12.1471 6.17573L13.0035 5.41445C13.8282 6.11369 14.4618 6.84351 14.8585 7.34875C15.1655 7.73964 15.179 8.26914 14.8865 8.67093C13.9461 9.96222 11.5526 12.6667 8 12.6667C7.08477 12.6667 6.22902 12.4585 5.44845 12.1301L6.40752 11.2776Z"
                                                              fill="#161633" fillOpacity="0.54"/>
                                                    </svg>
                                                    <span className={'closed'}>Скрыт в системе.</span>
                                                    <span className={'open'}>Открыть</span>
                                                </>
                                            ) : (
                                                <>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M14.4484 2.26804C14.6686 2.51571 14.6463 2.89495 14.3986 3.1151L2.39862 13.7818C2.15095 14.0019 1.77171 13.9796 1.55156 13.7319C1.3314 13.4843 1.35371 13.105 1.60138 12.8849L3.62757 11.0838C2.49198 10.2524 1.6342 9.27865 1.14146 8.65125C0.834468 8.26036 0.820963 7.73086 1.11356 7.32907C2.05392 6.03778 4.44744 3.33333 8 3.33333C9.22701 3.33333 10.3471 3.70749 11.3225 4.24391L13.6014 2.21821C13.8491 1.99806 14.2283 2.02037 14.4484 2.26804ZM5.73589 9.20976L4.49651 10.3114C4.45288 10.2812 4.40954 10.2506 4.36648 10.2197C3.3215 9.46908 2.51713 8.56537 2.04823 7.97089C2.96235 6.72372 5.05127 4.46667 8 4.46667C8.85412 4.46667 9.66502 4.69121 10.4148 5.05075L9.46668 5.8935C9.0511 5.60372 8.54526 5.43333 8 5.43333C6.58247 5.43333 5.43333 6.58247 5.43333 8C5.43333 8.43712 5.54293 8.84924 5.73589 9.20976ZM6.62805 8.41673L8.5747 6.68638C8.39876 6.6093 8.20445 6.56666 8 6.56666C7.20839 6.56666 6.56666 7.20839 6.56666 8C6.56666 8.14508 6.5881 8.28495 6.62805 8.41673ZM6.40752 11.2776C6.9172 11.4401 7.44963 11.5333 8 11.5333C10.9487 11.5333 13.0377 9.27628 13.9518 8.02911C13.5629 7.5361 12.9433 6.83041 12.1471 6.17573L13.0035 5.41445C13.8282 6.11369 14.4618 6.84351 14.8585 7.34875C15.1655 7.73964 15.179 8.26914 14.8865 8.67093C13.9461 9.96222 11.5526 12.6667 8 12.6667C7.08477 12.6667 6.22902 12.4585 5.44845 12.1301L6.40752 11.2776Z"
                                                              fill="#6066FF" fillOpacity="0.54"/>
                                                    </svg>
                                                    <span className={'open'}>Скрыть в системе</span>
                                                </>
                                            )
                                        }
                                    </HiddenInSystem>
                                </AccordionDetailsStyled>
                            </AccordionStyled>
                        </Stack>
                    </CardBody>
                </ClientCardStyled>
            )}
        </Draggable>
    )
}

export default CustomerCard