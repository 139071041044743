import {styled} from "@mui/material/styles";
import {FormControlLabel, Switch} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {DotLoader} from "../../components/Base/DotLoader";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
), {name: 'Switch'})(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const Rule = ({state, dispatch}) => {

    return (
        <Stack direction="column"
               justifyContent="center"
               alignItems="center"
               spacing={0}
               id={'auth-Rule'}
               sx={{
                   width: '100%',
                   position: 'absolute',
                   height: '100%',

                   backdropFilter: 'blur(33.5px)',

                   zIndex: 22
               }}>
            <Box sx={{
                width: '100%',

                overflowY: 'auto',
                overflowX: 'hidden',
                scrollbarColor: '#d4aa70 #e4e4e4',
                scrollbarWidth: 'thin',

                '&::-webkit-scrollbar': {
                    width: '0px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#e4e4e4',
                    borderRadius: '100px',
                },

                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    border: '2px solid transparent',
                    backgroundClip: 'content-box',
                    backgroundColor: '#0382F6',
                },
            }}>
                <Box sx={{
                    margin: 'auto',
                    width: '416px',
                    padding: '0px 16px',
                }}>
                    <Stack direction="column"
                           justifyContent="center"
                           alignItems="center"
                           spacing={0}
                    >
                        <img width={416} height={418} src={process.env.PUBLIC_URL + '/static/images/coll_1.png'} alt={'coll'}/>
                    </Stack>
                    <p style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '510',
                        fontSize: '22px',
                        lineHeight: '32px',

                        textAlign: 'center',

                        color: '#FFFFFF',
                    }}>
                        Общая история звонков и список клиентов
                    </p>
                    <p style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '15px',
                        lineHeight: '22px',

                        textAlign: 'center',

                        color: 'rgba(233, 233, 255, 0.6)',

                        padding: '0px 16px',
                    }}>
                        Приложение объединяет рабочую группу в единое пространство. Для этого ему необходим доступ к контактам. Они останутся приватными в рамках группы и вы
                        сможете
                        ими управлять.
                    </p>
                    <Stack direction="row"
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={'22px'}
                    >
                        <div style={{width: 34, height: 34}}>
                            <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.625" y="6.375" width="24.375" height="26.875" rx="8" fill="url(#paint0_linear_151_20237)"/>
                                <g filter="url(#filter0_b_151_20237)">
                                    <rect x="5" y="0.75" width="24.375" height="28.125" rx="8" fill="url(#paint1_linear_151_20237)" fillOpacity="0.4"/>
                                    <rect x="5.5" y="1.25" width="23.375" height="27.125" rx="7.5" stroke="url(#paint2_linear_151_20237)" strokeOpacity="0.54"/>
                                </g>
                                <path
                                    d="M19.925 11.1146C19.925 11.7322 19.6383 12.5971 19.137 13.3106C18.6257 14.0382 18.0279 14.425 17.5 14.425C16.9721 14.425 16.3743 14.0382 15.863 13.3106C15.3617 12.5971 15.075 11.7322 15.075 11.1146C15.075 9.97521 16.1021 8.95 17.5 8.95C18.8979 8.95 19.925 9.97521 19.925 11.1146Z"
                                    stroke="white" strokeWidth="1.4" strokeLinecap="round"/>
                                <path d="M12.9346 20.1251V20.1251C12.9346 18.2341 14.4675 16.7012 16.3585 16.7012H18.6411C20.5321 16.7012 22.065 18.2341 22.065 20.1251V20.1251"
                                      stroke="white" strokeWidth="1.4" strokeLinecap="round"/>
                                <defs>
                                    <filter id="filter0_b_151_20237" x="-6" y="-10.25" width="46.375" height="50.125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.5"/>
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_151_20237"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_151_20237" result="shape"/>
                                    </filter>
                                    <linearGradient id="paint0_linear_151_20237" x1="17.5" y1="12.5" x2="2.74664" y2="30.4367" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#323AF7"/>
                                        <stop offset="1" stopColor="#628EFF"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_151_20237" x1="12.5446" y1="30.9258" x2="20.3906" y2="1.30028" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#7F93FF"/>
                                        <stop offset="1" stopColor="#CAD2FF" stopOpacity="0.8"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_151_20237" x1="17.1875" y1="0.75" x2="24.1175" y2="28.3426" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white" stopOpacity="0.5"/>
                                        <stop offset="1" stopColor="#4053FC" stopOpacity="0.86"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <p style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '15px',
                            lineHeight: '22px',

                            textAlign: 'justify',

                            color: 'rgba(233, 233, 255, 0.6)',
                        }}>
                            <b style={{color: '#FFFFFF'}}>Доступ к контактам</b> нужен для функционирования общего контакт-листа клиентов рабочей группы (вы сами выбираете, какие
                            контакты видны другим участникам).
                        </p>
                    </Stack>
                    <p style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',

                        textAlign: 'justify',

                        color: ' rgba(233, 233, 255, 0.6)',
                    }}>
                        В соответствии с Правилами и требованиями норм конфиденциальности, абсолютно никто и ни при каких условиях, без ведома и отдельного явного разрешения
                        Пользователя не получает какой-либо возможности узнать о содержимом раздела контакты в смартфоне.
                    </p>
                    <p style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',

                        textAlign: 'justify',

                        color: ' rgba(233, 233, 255, 0.6)',
                    }}>
                        Это разрешение не дает приложению прав публикации и передачи содержимого контактов вовне замкнутой системы приложения, в соответствии с Правилами и
                        Условиями
                        конфиденциальности.
                    </p>
                </Box>
                <Stack sx={{
                    width: '100%',
                    background: 'rgba(126, 126, 158, 0.08)',
                    border: '1px solid rgba(233, 233, 255, 0.1)',
                    boxShadow: '0px 4px 29px rgba(26, 22, 67, 0.85)',
                    backdropFilter: 'blur(33.5px)',

                    borderRadius: '0px',
                    paddingBottom: '30px',
                }} id={'rule-footer'}
                >
                    {
                        state.loading ? (
                            <div style={{padding: 40}}>
                                <DotLoader style={{height: 48}}/>
                                <p style={{
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                }}>Подождите, идет загрузка…</p>
                            </div>
                        ) : (
                            <>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <IOSSwitch checked={state.agreementRuleFirst}
                                                   onChange={() => {
                                                       dispatch({type: 'first'})
                                                   }}
                                        />
                                    }
                                    label={<FormControlLabelStyledP>Соглашаюсь с описанными принципами и разрешаю доступ к данным</FormControlLabelStyledP>}
                                    sx={{
                                        maxWidth: '416px',
                                        margin: 'auto',
                                        justifyContent: "space-between",
                                        display: 'flex',
                                    }}
                                />
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <IOSSwitch checked={state.agreementRuleSecond}
                                                   onChange={() => {
                                                       dispatch({type: 'second'})
                                                   }}
                                        />
                                    }
                                    label={<FormControlLabelStyledP>Я прочитал и согласен с правилами и условиями <a href="https://web.tos.api.evernum.ru">Пользовательского
                                        соглашения Evernum</a></FormControlLabelStyledP>}
                                    sx={{
                                        maxWidth: '416px',
                                        margin: 'auto',
                                        justifyContent: "space-between",
                                        display: 'flex',

                                    }}
                                />
                            </>
                        )
                    }
                </Stack>
            </Box>
        </Stack>
    )
}

const FormControlLabelStyledP = styled('p', {name: 'label'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',

    textAlign: 'left',

    color: '#FFFFFF',
    '& a': {
        color: '#6066FF',
    }

}))
