import {useEffect, useReducer} from "react";
import {styled} from "@mui/material/styles";
import {MouseParallaxContainer, MouseParallaxChild} from "react-parallax-mouse";
import {AuthInit} from "./init";
import {Start} from "./start";
import {Rule} from "./rule";
import {useLazyGetAuthMethodQuery} from "../../redux/services/authGet";


const AuthStyled = styled('div', {name: 'main-AuthStyled'})(() => ({
    background: 'radial-gradient(123.08% 56.89% at 54.69% 51.78%, #0B1788 0%, #0B0914 100%)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'fixed',
    scrollbarColor: '#d4aa70 #e4e4e4 !important',
    scrollbarWidth: 'thin !important',

    '&::-webkit-scrollbar': {
        width: 0
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#e4e4e4',
        borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 100,
        border: '2px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: '#0382F6',
    },

    '& .parallax': {
        height: `100% !important`,

        overflowY: 'scroll !important',
        scrollbarColor: '#d4aa70 #e4e4e4 !important',
        scrollbarWidth: 'thin !important',

        '&::-webkit-scrollbar': {
            width: 0
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '100px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 100,
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            backgroundColor: '#0382F6',
        },
    }
}))

const Login = () => {
    const [trigger, {data, isLoading}] = useLazyGetAuthMethodQuery()

    const [authState, authDispatch] = useReducer((state, action) => {

        switch (action?.type) {
            case 'start-off':
                return {...state, start: false};
            case 'start-on':
                return {...state, start: true};
            case 'rule-off':
                return {...state, rule: false};
            case 'rule-on':
                return {...state, rule: true};
            case 'first':
                return {...state, agreementRuleFirst: !state.agreementRuleFirst};
            case 'second':
                return {...state, agreementRuleSecond: !state.agreementRuleSecond};
            case 'runAuthCheck':
                return {...state, check: true}
            case 'cancelAuthCheck':
                return {...state, check: false}
            case 'loadingOn':
                return {...state, loading: true}
            case 'loadingOff':
                return {...state, loading: false}
            default:
                console.log({action})
                throw new Error();
        }
    }, {
        start: true,
        rule: true,
        agreementRuleFirst: false,
        agreementRuleSecond: false,
        check: false,
        loading: false,
    })

    useEffect(() => {
        if (authState.agreementRuleFirst && authState.agreementRuleSecond) {
            authDispatch({type: 'loadingOn'})
            trigger()
        }
    }, [authState.agreementRuleFirst, authState.agreementRuleSecond, trigger])

    useEffect(() => {
        if (authState.agreementRuleFirst && authState.agreementRuleSecond && !isLoading && data) {
            authDispatch({type: 'rule-off'})
        }
    }, [authState.agreementRuleFirst, authState.agreementRuleSecond, isLoading, data])

    useEffect(() => {
        if (isLoading) {
            authDispatch({type: 'loadingOn'})
        } else {
            authDispatch({type: 'loadingOff'})
        }
    }, [isLoading])

    return (
        <AuthStyled>
            <MouseParallaxContainer
                className="parallax"
                containerStyles={{
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto auto"
                }}
                sx={{position: 'fixed', top: 0}}
                resetOnLeave
            >
                <MouseParallaxChild factorX={0.15}
                                    factorY={0.1}
                                    updateStyles={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        backfaceVisibility: "hidden",
                                        display: 'flex',
                                        placeContent: 'center',
                                        left: '-170px',
                                    }}
                >
                    <svg width="367" height="367" viewBox="0 0 367 367" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_iif_9_24010)">
                            <circle cx="183.5" cy="183.5" r="151.5" fill="url(#paint0_radial_9_24010)"/>
                            <circle cx="183.5" cy="183.5" r="151.5" fill="url(#paint1_radial_9_24010)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_iif_9_24010" x="0" y="0" width="367" height="367" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.408333 0 0 0 0 0.475952 0 0 0 0 1 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_24010"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.370833 0 0 0 0 0.483887 0 0 0 0 1 0 0 0 0.5 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_9_24010" result="effect2_innerShadow_9_24010"/>
                                <feGaussianBlur stdDeviation="16" result="effect3_foregroundBlur_9_24010"/>
                            </filter>
                            <radialGradient id="paint0_radial_9_24010" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(260.131 72.5174) rotate(106.275) scale(273.44)">
                                <stop stopColor="#867BFF"/>
                                <stop offset="0.558765" stopColor="#3A4ED1"/>
                                <stop offset="0.968769" stopColor="#0C1449"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_9_24010" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(172.93 276.866) rotate(-71.9772) scale(193.589 470.602)">
                                <stop stopColor="#000C2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#0047FF" stopOpacity="0"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                <MouseParallaxChild factorX={0.05}
                                    factorY={0.05}
                                    updateStyles={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: '100%',
                                        height: "100%",

                                        position: 'absolute',
                                        top: '30%',

                                        placeContent: 'center',
                                        left: '170px',
                                    }}

                >
                    <svg width="260" height="260" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_iif_9_24009)">
                            <circle cx="130" cy="130" r="106" fill="url(#paint0_radial_9_24009)"/>
                            <circle cx="130" cy="130" r="106" fill="url(#paint1_radial_9_24009)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_iif_9_24009" x="0" y="0" width="260" height="260" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.408333 0 0 0 0 0.475952 0 0 0 0 1 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_24009"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.370833 0 0 0 0 0.483887 0 0 0 0 1 0 0 0 0.5 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_9_24009" result="effect2_innerShadow_9_24009"/>
                                <feGaussianBlur stdDeviation="12" result="effect3_foregroundBlur_9_24009"/>
                            </filter>
                            <radialGradient id="paint0_radial_9_24009" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(183.616 52.3488) rotate(106.275) scale(191.318)">
                                <stop stopColor="#7E7BFF"/>
                                <stop offset="0.558765" stopColor="#3A4ED1"/>
                                <stop offset="0.968769" stopColor="#0C1449"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_9_24009" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(122.605 195.326) rotate(-71.9772) scale(135.448 329.266)">
                                <stop stopColor="#000C2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#0047FF" stopOpacity="0"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                {authState.start ?
                    <Start dispatch={authDispatch}/> :
                    authState.rule ?
                        <Rule state={authState} dispatch={authDispatch}/> :
                        <AuthInit authState={authState} authDispatch={authDispatch} data={data}/>
                }

                <MouseParallaxChild factorX={-0.05}
                                    factorY={-0.05}
                                    updateStyles={{
                                        zIndex: 10,
                                        display: "flex",
                                        position: 'absolute',

                                        width: '149px',

                                        top: -30,
                                        right: 'calc(50% - 252px)',
                                    }}

                >
                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ii_9_24012)">
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint0_radial_9_24012)"/>
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint1_radial_9_24012)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_ii_9_24012" x="0" y="0" width="159" height="173" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.408333 0 0 0 0 0.475952 0 0 0 0 1 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_24012"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.370833 0 0 0 0 0.483887 0 0 0 0 1 0 0 0 0.5 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_9_24012" result="effect2_innerShadow_9_24012"/>
                            </filter>
                            <radialGradient id="paint0_radial_9_24012" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(112.183 19.9244) rotate(106.275) scale(134.464)">
                                <stop stopColor="#7E7BFF"/>
                                <stop offset="0.558765" stopColor="#3A4ED1"/>
                                <stop offset="0.968769" stopColor="#0C1449"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_9_24012" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(69.3023 120.413) rotate(-71.9772) scale(95.1971 231.418)">
                                <stop stopColor="#000C2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#0047FF" stopOpacity="0"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                <MouseParallaxChild factorX={-0.05}
                                    factorY={-0.05}
                                    updateStyles={{
                                        zIndex: 10,
                                        display: "flex",
                                        position: 'absolute',

                                        width: '149px',

                                        bottom: -50,
                                        right: 'calc(50% - 256px)',
                                    }}

                >
                    <svg className={'bottom-front-bubble'} width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ii_9_24012)">
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint0_radial_9_24012)"/>
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint1_radial_9_24012)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_ii_9_24012" x="0" y="0" width="159" height="173" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.408333 0 0 0 0 0.475952 0 0 0 0 1 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_24012"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.370833 0 0 0 0 0.483887 0 0 0 0 1 0 0 0 0.5 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_9_24012" result="effect2_innerShadow_9_24012"/>
                            </filter>
                            <radialGradient id="paint0_radial_9_24012" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(112.183 19.9244) rotate(106.275) scale(134.464)">
                                <stop stopColor="#7E7BFF"/>
                                <stop offset="0.558765" stopColor="#3A4ED1"/>
                                <stop offset="0.968769" stopColor="#0C1449"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_9_24012" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(69.3023 120.413) rotate(-71.9772) scale(95.1971 231.418)">
                                <stop stopColor="#000C2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#0047FF" stopOpacity="0"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
            </MouseParallaxContainer>
        </AuthStyled>
    )
}

export default Login