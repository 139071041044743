import {gql} from "@apollo/client";
import {CUSTOMERS_FIELDS} from "../Fragments/Customer";

export const COLUMNS_CUSTOMERS_SUBSCRIPTION = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILE_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILE_IDS($profileIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_TAG_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_TAG_IDS($tagIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_tags: {tag_id: {_in: $tagIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_STATUS_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_STATUS_IDS($statusIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;
export const COLUMNS_CUSTOMERS_SUBSCRIPTION_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_STATUS_IDS($statusIds: [Int!], $statusOptionIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILES_IDS_TAGS_IDS($profileIds: [Int!], $tagIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}, clients_tags: {tag_id: {_in: $tagIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILES_IDS_STATUS_IDS($profileIds: [Int!], $statusIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}, clients_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;
export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILES_IDS_STATUS_IDS($profileIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}, clients_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_TAGS_IDS_STATUS_IDS($tagIds: [Int!], $statusIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_tags: {tag_id: {_in: $tagIds}}, clients_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;
export const COLUMNS_CUSTOMERS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_TAGS_IDS_STATUS_IDS($tagIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_tags: {tag_id: {_in: $tagIds}}, clients_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;

export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILES_IDS_TAGS_IDS_STATUS_IDS($profileIds: [Int!], $tagIds: [Int!], $statusIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}, clients_tags: {tag_id: {_in: $tagIds}}, clients_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;
export const COLUMNS_CUSTOMERS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${CUSTOMERS_FIELDS}
    subscription getCustomers_PROFILES_IDS_TAGS_IDS_STATUS_IDS($profileIds: [Int!], $tagIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        clients (order_by: { name: asc_nulls_last, main_number: asc_nulls_last}, where: {clients_assignees: {profile_id: {_in: $profileIds}}, clients_tags: {tag_id: {_in: $tagIds}}, clients_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...CustomersFields
        }
    }
`;
