import {useQuery} from "@apollo/client";
import {ALL_GROUP_QUERY} from "../../components/GQL/Query/Group";
import {useEffect} from "react";
import {selectorGroupId, setGroupId} from "../../redux/auth/Reducer";
import {useDispatch, useSelector} from "react-redux";
import {Avatar} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {IconSquare} from "../../components/Base/IconComponents";
import {styled} from "@mui/material/styles";

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            backgroundColor: stringToColor(name),
            width: 24,
            height: 24,
            fontSize: '10px !important',
            margin: 'auto'
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const Groups = styled(Stack)(({style})=>({
    ...style,
    padding: '5px 14px',
    width: 64,
}))

const GroupList = ({hidden, handleDrawerClose}) => {
    const dispatch = useDispatch()
    const groupId = useSelector(selectorGroupId)
    const {data} = useQuery(ALL_GROUP_QUERY)

    useEffect(() => {
        if (data && !groupId) {
            dispatch(setGroupId(data?.groups[0]?.id))
        }
    }, [data, dispatch, groupId])

    return (
        <Groups
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={'16px'}
            sx={{display: hidden ? 'none' : '', paddingTop: '15px'}}
        >
            <IconSquare size={32} style={{backgroundColor: 'rgba(0,0,0,0)'}} onClick={handleDrawerClose}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.6666 9.16668H6.49998L11.1666 4.50001L9.99998 3.33334L3.33331 10L9.99998 16.6667L11.1666 15.5L6.49998 10.8333H16.6666V9.16668Z" fill="#161633" fillOpacity="0.54"/>
                </svg>
            </IconSquare>

            {
                data?.groups?.map((g, index) => {
                    return (
                        <IconSquare size={32} style={{background: '#7707f9', position: 'relative',}} key={index} onClick={() => {
                            dispatch(setGroupId(g.id))
                        }}>
                            <Avatar {...stringAvatar(g.name)}/>
                        </IconSquare>
                    )
                })
            }
            <IconSquare size={32} style={{background: 'rgba(72, 72, 141, 0.06)', position: 'relative',}}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.99999 0.666672C5.6318 0.666672 5.33332 0.96515 5.33332 1.33334V5.33334H1.33332C0.965133 5.33334 0.666656 5.63182 0.666656 6.00001C0.666656 6.3682 0.965135 6.66667 1.33332 6.66667H5.33332V10.6667C5.33332 11.0349 5.6318 11.3333 5.99999 11.3333C6.36818 11.3333 6.66666 11.0349 6.66666 10.6667V6.66667H10.6667C11.0348 6.66667 11.3333 6.3682 11.3333 6.00001C11.3333 5.63182 11.0348 5.33334 10.6667 5.33334H6.66666V1.33334C6.66666 0.965149 6.36818 0.666672 5.99999 0.666672Z" fill="#161633" fillOpacity="0.54"/>
                </svg>
            </IconSquare>
        </Groups>
    )
}

export default GroupList