import {gql} from "@apollo/client";
import {TASKS_FIELDS} from "../Fragments/Tasks";

export const COLUMNS_TASKS_SUBSCRIPTION = gql`
    ${TASKS_FIELDS}
    subscription getTasks {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILE_IDS($profileIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_TAG_IDS($tagIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_tags: {tag_id: {_in: $tagIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_STATUS_IDS($statusIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_STATUS_IDS($statusIds: [Int!], $statusOptionIds: [Int!]) {
        tasks(order_by: {reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILES_IDS_TAGS_IDS($profileIds: [Int!], $tagIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}, tasks_tags: {tag_id: {_in: $tagIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILES_IDS_STATUS_IDS($profileIds: [Int!], $statusIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}, tasks_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILES_IDS_STATUS_IDS($profileIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}, tasks_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_TAGS_IDS_STATUS_IDS($tagIds: [Int!], $statusIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_tags: {tag_id: {_in: $tagIds}}, tasks_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_TAGS_IDS_STATUS_IDS($tagIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_tags: {tag_id: {_in: $tagIds}}, tasks_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILES_IDS_TAGS_IDS_STATUS_IDS($profileIds: [Int!], $tagIds: [Int!], $statusIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}, tasks_tags: {tag_id: {_in: $tagIds}}, tasks_statuses: {status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;

export const COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS = gql`
    ${TASKS_FIELDS}
    subscription getTasks_PROFILES_IDS_TAGS_IDS_STATUS_IDS($profileIds: [Int!], $tagIds: [Int!], $statusIds: [Int!], $statusOptionIds: [Int!]) {
        tasks (order_by: { reminder_date: asc_nulls_last, due_date: asc_nulls_last, is_pinned: desc}, where: {tasks_assignees: {profile_id: {_in: $profileIds}}, tasks_tags: {tag_id: {_in: $tagIds}}, tasks_statuses: {status_option_id: {_in: $statusOptionIds}, status_list_id: {_in: $statusIds}}}) {
            ...TasksFields
        }
    }
`;
