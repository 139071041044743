import {CCButton} from "./CreateConnectGroup";
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import {styled} from "@mui/material/styles";

const Header = styled('h1', {name: 'modalHeader'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',

    color: '#0C0C13'
}))

const DialogText = styled('p', {name: 'dialog-text'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',

    color: 'rgba(22, 22, 51, 0.82)'
}))

const ConnectInstruction = () => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <CCButton className={`btn-login`}
                      onClick={handleClickOpen}
            >
                <span className="btn-login--txt">{t('Как присоединиться?')}</span>
            </CCButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    background: 'rgba(0, 0, 0, 0.6)'
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '18px',
                        width: '416px',
                    }
                }}
            >
                <img width={'100%'} height={200} src={process.env.PUBLIC_URL + '/static/images/Rectangle286.png'} alt={'coll'}/>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        background: 'rgba(233, 233, 255, 0.6)'
                        ,
                    }}
                >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.70089 0.874956C1.47308 0.64715 1.10374 0.64715 0.875932 0.874956C0.648127 1.10276 0.648127 1.47211 0.875933 1.69991L4.17576 4.99975L0.875932 8.29958C0.648127 8.52738 0.648127 8.89673 0.875933 9.12454C1.10374 9.35234 1.47308 9.35234 1.70089 9.12454L5.00072 5.8247L8.30055 9.12454C8.52836 9.35234 8.89771 9.35234 9.12551 9.12454C9.35332 8.89673 9.35332 8.52738 9.12551 8.29958L5.82568 4.99975L9.12551 1.69991C9.35332 1.47211 9.35332 1.10276 9.12551 0.874957C8.89771 0.647151 8.52836 0.647151 8.30055 0.874957L5.00072 4.17479L1.70089 0.874956Z"
                              fill="#161633" fillOpacity="0.82"/>
                    </svg>
                </IconButton>
                <DialogContent sx={{padding: '16px'}}>
                    <Header>Как присоединиться к рабочей группе?</Header>
                    <DialogText>Администратор рабочей группы должен выслать вамм ссылку-приглашение, найдите её и откройте на телефоне. Группа подключится
                        автометически.</DialogText>
                    <br/>
                    <DialogText>Администратор может найти эту ссылку зайдя в Evernum / Консоль / Участники.</DialogText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ConnectInstruction