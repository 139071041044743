import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {stringify} from "query-string";

// Define a service using a base URL and expected endpoints
export const taskEdit = createApi({
    reducerPath: 'taskEdit',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://task-edit.v202208.api.evernum.ru/',
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        setTaskEditMoveMethod: builder.query({
            query: (params) => ({
                url: `edit-move?group_id=${localStorage.getItem('groupId')}&access_token=${localStorage.getItem('token')}`,
                method: 'POST',
                body: stringify(params, {arrayFormat: 'index'})
            }),
        }),
    }),
})

export const {useLazySetTaskEditMoveMethodQuery} = taskEdit
