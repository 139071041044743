import React from "react";
import {styled} from "@mui/material/styles";

const IconSquareStyled = styled('div')(({size, style}) => ({
    ...style, display: 'flex', flexDirection: 'row', alignItems: 'center', // padding: '4px',
    gap: '10px',

    width: size, height: size,

    borderRadius: '7px',

    flex: 'none', order: '0', flexGrow: '0',

    '& svg': {
        marginLeft: 'auto', marginRight: 'auto',
    }
}));

export const IconSquare = ({
                               style = {}, size = 24, children, className = '', onClick = () => {
    }
                           }) => {

    return (<IconSquareStyled
        style={style}
        className={`${className}`}
        onClick={onClick}
        size={size}
    >
        {children}
    </IconSquareStyled>)
}

const IconCircleStyled = styled('div')(({backgroundColor, size, style}) => ({
    ...style, backgroundColor: backgroundColor, width: size, height: size,

    border: '1px solid rgba(0, 0, 0, 0.04)', borderRadius: size,

    padding: 4, paddingLeft: 5,

    display: 'flex', alignItems: 'center', textAlign: 'center',


    '& span': {
        marginLeft: 'auto', marginRight: 'auto',
    }, '& svg': {
        marginLeft: 'auto', marginRight: 'auto',
    }
}))

export const IconCircle = ({
                               style = {}, size = 24, children, className = '', backgroundColor = 'rgba(96, 102, 255, 0.1)', onClick = () => {
    }
                           }) => {
    return (<IconCircleStyled className={`${className}`}
                              onClick={onClick}
                              backgroundColor={backgroundColor}
                              style={style}
                              size={size}
    >
        {children}
    </IconCircleStyled>)
}
