import {Avatar, AvatarGroup} from "@mui/material";
import React from "react";

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {IconSquare} from "../../../../components/Base/IconComponents";


const NewTaskButton = styled('button')(() => ({
    boxSizing: 'border-box',

    width: '100%',
    display: 'flex',

    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 10,
    gap: '8px',

    /* Main/4LightTheme */
    border: '1px solid rgba(72, 72, 141, 0.06)',
    borderRadius: '16px',

    backgroundColor: 'rgba(0,0,0,0)',

    flex: 'none',
    order: '0',
    flexGrow: '0',

    alignSelf: 'stretch',
    '& span': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        /* identical to box height, or 143% */


        /* Main/40LightTheme */

        color: 'rgba(22, 22, 51, 0.54)',

        /* Inside auto layout */

        flex: 'none',
        order: '0',
        alignSelf: 'stretch',
        flexGrow: '0',
    },
}));

const Title = styled('span')(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',


    display: 'flex',
    alignItems: 'center',

    /* Main/40LightTheme */
    color: 'rgba(22, 22, 51, 0.54)',

}));

const AvatarGroupStyled = styled(AvatarGroup)(({size}) => ({
    '& .MuiAvatar-colorDefault': {
        width: size,
        height: size,

        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '24px',
        color: 'rgba(22, 22, 51, 0.54)',
    },
    '& .MuiAvatar-root ': {
        background: '#DFDEE7',
        border: '1px solid #F0EFF6',
    }
}));

const ColumnHeader = ({title, count = 0, avatars = []}) => {
    return (
        <Stack spacing={2} style={{paddingBottom: 8}}>
            <div style={{
                display: 'flex',
                float: 'left',
                gap: '8px',
            }}>
                <div style={{
                    display: 'flex'
                }}>
                    <AvatarGroupStyled max={3} spacing={4} size={24}>
                        {
                            avatars?.map((item, index) => (
                                <Avatar key={index} alt="Remy Sharp" src={item} sx={{width: 24, height: 24}}/>
                            ))
                        }
                    </AvatarGroupStyled>
                </div>
                <Title>
                    {title}
                    {count > 0 ? <span style={{fontWeight: "600", color: '#0C0C13'}}> {count}</span> : ''}
                </Title>
                <IconSquare style={{
                    marginRight: '0px',
                    marginLeft: 'auto',
                }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M6 0.666626C5.63181 0.666626 5.33333 0.965104 5.33333 1.33329V5.33329H1.33333C0.965141 5.33329 0.666664 5.63177 0.666664 5.99996C0.666664 6.36815 0.965142 6.66663 1.33333 6.66663H5.33333V10.6666C5.33333 11.0348 5.63181 11.3333 6 11.3333C6.36819 11.3333 6.66666 11.0348 6.66666 10.6666V6.66663H10.6667C11.0349 6.66663 11.3333 6.36815 11.3333 5.99996C11.3333 5.63177 11.0349 5.33329 10.6667 5.33329H6.66666V1.33329C6.66666 0.965103 6.36819 0.666626 6 0.666626Z"
                              fill="#161633" fillOpacity="0.54"/>
                    </svg>
                </IconSquare>
                <IconSquare>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_31_11537)">
                            <path d="M6.66667 12H9.33333V10.6667H6.66667V12ZM2 4V5.33333H14V4H2ZM4 8.66667H12V7.33333H4V8.66667Z" fill="#161633" fillOpacity="0.54"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_31_11537">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </IconSquare>
                <IconSquare>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.33333 3.33333C9.33333 4.06971 8.73638 4.66667 8 4.66667C7.26362 4.66667 6.66667 4.06971 6.66667 3.33333C6.66667 2.59695 7.26362 2 8 2C8.73638 2 9.33333 2.59695 9.33333 3.33333ZM9.33333 8C9.33333 8.73638 8.73638 9.33333 8 9.33333C7.26362 9.33333 6.66667 8.73638 6.66667 8C6.66667 7.26362 7.26362 6.66667 8 6.66667C8.73638 6.66667 9.33333 7.26362 9.33333 8ZM8 14C8.73638 14 9.33333 13.403 9.33333 12.6667C9.33333 11.9303 8.73638 11.3333 8 11.3333C7.26362 11.3333 6.66667 11.9303 6.66667 12.6667C6.66667 13.403 7.26362 14 8 14Z"
                              fill="#161633" fillOpacity="0.54"/>
                    </svg>
                </IconSquare>
            </div>
            <NewTaskButton>
                <IconSquare>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.00008 2.6665C7.63189 2.6665 7.33342 2.96498 7.33342 3.33317V7.33317H3.33341C2.96522 7.33317 2.66675 7.63165 2.66675 7.99984C2.66675 8.36803 2.96523 8.6665 3.33342 8.6665H7.33342V12.6665C7.33342 13.0347 7.63189 13.3332 8.00008 13.3332C8.36827 13.3332 8.66675 13.0347 8.66675 12.6665V8.6665H12.6667C13.0349 8.6665 13.3334 8.36803 13.3334 7.99984C13.3334 7.63165 13.0349 7.33317 12.6667 7.33317H8.66675V3.33317C8.66675 2.96498 8.36827 2.6665 8.00008 2.6665Z"
                              fill="#161633" fillOpacity="0.54"/>
                    </svg>
                </IconSquare>
                <span>Новая задача</span>
            </NewTaskButton>
        </Stack>
    )
}

export default ColumnHeader