import {configureStore} from '@reduxjs/toolkit'
import auth from './auth/Reducer'
import {authGetApi} from './services/authGet'
import {authCheckApi} from './services/authCheck'
import {setupListeners} from "@reduxjs/toolkit/query";
import themeReducer from "./theme/Reducer";
import {columnsApi} from "./services/columns";
import {taskEdit} from "./services/taskEdit";
import {clientEdit} from "./services/clientEdit";

const store = configureStore({
    reducer: {
        theme: themeReducer,
        auth: auth,
        [authGetApi.reducerPath]: authGetApi.reducer,
        [authCheckApi.reducerPath]: authCheckApi.reducer,
        [columnsApi.reducerPath]: columnsApi.reducer,
        [taskEdit.reducerPath]: taskEdit.reducer,
        [clientEdit.reducerPath]: taskEdit.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(columnsApi.middleware)
            .concat(taskEdit.middleware)
            .concat(clientEdit.middleware)
            .concat(authGetApi.middleware)
            .concat(authCheckApi.middleware),
})

export default store

setupListeners(store.dispatch)

