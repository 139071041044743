import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import {IconSquare as IcSq} from "../../components/Base/IconComponents";
import Stack from "@mui/material/Stack";
import GroupList from "./groupList";
import ControlList from "./controlList";
import {useSelector} from "react-redux";
import {selectorGroupId, selectorIsAuthorized} from "../../redux/auth/Reducer";
import {useQuery} from "@apollo/client";
import {GROUP_QUERY} from "../../components/GQL/Query/Group";
import {ProfileItem} from "./profile";
import {useEffect} from "react";
import {useNavigate} from "react-router";

const Search = styled('div', {name: 'search'})(() => ({
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px 8px 18px',
    gap: '8px',

    width: '480px',
    height: '52px',

    margin: 'auto',

    /* Main/100DarkTheme */
    background: '#FFFFFF',
    /* Main/4LightTheme */

    border: '1px solid rgba(72, 72, 141, 0.06)',
    borderRadius: '17px',

    /* Inside auto layout */

    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',

    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    // [theme.breakpoints.up('sm')]: {
    //     marginLeft: theme.spacing(1),
    //     width: 'auto',
    // },

    filter: 'drop-shadow(0px 2px 16px rgba(34, 37, 112, 0.12))',
}));

const StyledInputBase = styled(InputBase, {name: 'styled-input-base'})(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const drawerWidth = 267;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 58,
    [theme.breakpoints.up('sm')]: {
        width: 58,
    },
});

const DrawerHeader = styled('div', {name: 'drawer-header'})(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: 68,
    paddingLeft: '12px',
}));

const AppBar = styled(MuiAppBar, {
    name: 'app-bar',
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    backgroundColor: 'rgba(0,0,0,0) !important',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    height: 68,
    padding: '8px 0px',
}));

const Drawer = styled(Stack, {name: 'drawer', shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        zIndex: 1202,
        height: '100%',
        position: 'fixed',

        background: '#F5F4FA',
    }),
);

const IconSquare = styled(IcSq, {name: 'icon-square'})(({background = null}) => ({
    background: background ?? 'rgba(72, 72, 141, 0.0)',
    position: 'relative',
}))

const GroupName = styled("h1", {name: 'group-name'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '14px',

    display: 'flex',
    alignItems: 'center',

    color: '#000000',

    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: 0,
}))

const GroupSubHead = styled('p', {name: 'group-sub-head'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',

    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',

    color: 'rgba(22, 22, 51, 0.54)',

    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: 0,
}))

export default function Layout({children}) {
    const [open, setOpen] = React.useState(true);
    const groupId = useSelector(selectorGroupId)
    const isAuthorized = useSelector(selectorIsAuthorized)
    const navigate = useNavigate()
    const {data} = useQuery(GROUP_QUERY, {
        variables: {
            groupId: groupId
        }
    })

    useEffect(() => {
        if (!isAuthorized) {
            navigate('/login/')
        }
    }, [isAuthorized, navigate])
    console.log(isAuthorized)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Search>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14 7.33332C14 8.87392 13.4775 10.2925 12.5999 11.4214L16.9923 15.8138C17.3178 16.1392 17.3178 16.6669 16.9923 16.9923C16.6669 17.3177 16.1393 17.3177 15.8138 16.9923L11.4214 12.5999C10.2925 13.4774 8.87395 14 7.33335 14C3.65146 14 0.666687 11.0152 0.666687 7.33332C0.666687 3.65142 3.65146 0.666656 7.33335 0.666656C11.0153 0.666656 14 3.65142 14 7.33332ZM7.33335 12.3333C10.0948 12.3333 12.3334 10.0947 12.3334 7.33332C12.3334 4.5719 10.0948 2.33332 7.33335 2.33332C4.57193 2.33332 2.33335 4.5719 2.33335 7.33332C2.33335 10.0947 4.57193 12.3333 7.33335 12.3333Z"
                                  fill="#161633" fillOpacity="0.54"/>
                        </svg>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search'}}
                        />
                        <IconSquare style={{
                            background: 'rgba(72, 72, 141, 0.06)',
                            marginRight: '0px',
                            marginLeft: 'auto',
                        }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 4.00001C14 5.47277 12.8061 6.66668 11.3333 6.66668C9.86053 6.66668 8.66663 5.47277 8.66663 4.00001C8.66663 2.52725 9.86053 1.33334 11.3333 1.33334C12.8061 1.33334 14 2.52725 14 4.00001Z"
                                    fill="#FF1F62"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.3333 7.33333C11.6817 7.33333 12.0176 7.27988 12.3333 7.18073V9.72386L13.1299 10.5205C13.6759 11.0665 13.2892 12 12.5171 12H3.61071C2.9399 12 2.56696 11.2241 2.98601 10.7002L3.99996 9.43281V8.66667C3.99996 8.29848 4.29843 8 4.66662 8C5.03481 8 5.33329 8.29848 5.33329 8.66667V9.43281C5.33329 9.73557 5.23025 10.0293 5.04111 10.2657L4.72037 10.6667H11.3905L11.2955 10.5717C11.1063 10.3825 11 10.1258 11 9.85815V7.31688C11.1096 7.32776 11.2208 7.33333 11.3333 7.33333ZM8.16662 2C8.32878 2 8.48877 2.00926 8.6461 2.02729C8.3652 2.40927 8.1638 2.85333 8.06628 3.33508C6.54794 3.38793 5.33329 4.63545 5.33329 6.16667V6.33333C5.33329 6.70152 5.03481 7 4.66662 7C4.29843 7 3.99996 6.70152 3.99996 6.33333V6.16667C3.99996 3.86548 5.86544 2 8.16662 2ZM7.66662 12.6667C7.29843 12.6667 6.99995 12.9651 6.99995 13.3333C6.99995 13.7015 7.29843 14 7.66662 14H8.99995C9.36814 14 9.66662 13.7015 9.66662 13.3333C9.66662 12.9651 9.36814 12.6667 8.99995 12.6667H7.66662Z"
                                      fill="#6066FF"/>
                            </svg>
                        </IconSquare>
                        <IconSquare style={{
                            background: 'radial-gradient(85.48% 130.4% at 92.67% 100%, #8267EE 0%, rgba(94, 232, 202, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(95.19% 204.41% at 4.09% 6.81%, #02EDB5 0%, #000AFF 100%)',
                            marginRight: '0px',
                            marginLeft: '0px',
                        }}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0.883012 6.59436C0.483118 5.35501 1.73704 4.22593 2.92782 4.75314C3.21852 4.88185 3.55956 4.77304 3.722 4.49975L6.00031 0.666656L8.27862 4.49975C8.44106 4.77304 8.78209 4.88185 9.0728 4.75314C10.2636 4.22593 11.5175 5.35501 11.1176 6.59436L10.7522 7.72689C9.62401 7.01886 7.91427 6.56737 6.00039 6.56737C4.08643 6.56737 2.37664 7.0189 1.24847 7.72697L0.883012 6.59436ZM6.00035 11.3333C8.54826 11.3333 10.6138 10.6221 10.6138 9.74467C10.6138 8.86728 8.54826 8.15602 6.00035 8.15602C3.45243 8.15602 1.38694 8.86728 1.38694 9.74467C1.38694 10.6221 3.45243 11.3333 6.00035 11.3333Z"
                                      fill="white"/>
                            </svg>
                        </IconSquare>
                    </Search>
                </Toolbar>
            </AppBar>
            <Drawer open={open}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
            >
                <GroupList hidden={!open} handleDrawerClose={handleDrawerClose}/>
                <Box width={203} bgcolor={'#FFFFFF'} sx={{marginLeft: '0px !important'}}>
                    <DrawerHeader>
                        {
                            open ? (
                                <div
                                    style={{
                                        ...(!open && {display: 'none'}),
                                    }}
                                    id={'is-open'}
                                >
                                    <Box sx={{gap: '8px', display: 'flex', flexDirection: 'column',}}>
                                        <GroupName>{data?.groups_by_pk?.name}</GroupName>
                                        <GroupSubHead>Рабочая группа</GroupSubHead>
                                    </Box>
                                </div>
                            ) : (
                                <IconButton
                                    id={'not-open'}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    // edge="start"
                                    sx={{
                                        margin: 'auto',
                                        ...(open && {display: 'none'}),
                                    }}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 6.41667H2.5V5H17.5V6.41667Z" fill="#161633" fillOpacity="0.54"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 10.5833H2.5V9.16666H17.5V10.5833Z" fill="#161633" fillOpacity="0.54"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 14.75H2.5V13.3333H17.5V14.75Z" fill="#161633" fillOpacity="0.54"/>
                                    </svg>
                                </IconButton>
                            )
                        }
                    </DrawerHeader>
                    <ControlList mini={!open}/>
                    <ProfileItem/>
                </Box>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3, overflowY: 'hidden', minHeight: '100vh', padding: '16px', marginLeft: open ? '267px' : '58px'}}>
                <DrawerHeader/>
                {children}
            </Box>
        </Box>
    );
}
