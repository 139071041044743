import {createBrowserRouter} from "react-router-dom";
import TasksBoard from "./screens/Boards/tasks/tasksBoard";
import Layout from "./screens/layout/layout";
import CreateProfile from "./screens/CreateProfile/CreateProfile";
import CreateConnectGroup from "./screens/CreateConnectGroup/CreateConnectGroup";
import Login from "./screens/Login/login";
import CallsBoard from "./screens/Boards/calls/callsBoard";
import CustomersBoard from "./screens/Boards/customers/customersBoard";
import Profile from "./screens/profile/profile";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout><h1>Hello World</h1></Layout>,
    },
    {
        path: "login",
        element: <Login/>,
    },
    {
        path: "create/profile",
        element: <CreateProfile/>,
    },
    {
        path: "create-connect/group",
        element: <CreateConnectGroup/>,
    },
    {
        path: "tasks",
        element: <Layout><TasksBoard/></Layout>,
    },
    {
        path: "calls",
        element: <Layout><CallsBoard/></Layout>
    },
    {
        path: "customers",
        element: <Layout><CustomersBoard/></Layout>
    },
    {
        path: "settings",
        element: <Layout><h1>settings</h1></Layout>
    },
    {
        path: "members",
        element: <Layout><h1>members</h1></Layout>
    },
    {
        path: "cutaways",
        element: <Layout><h1>cutaways</h1></Layout>
    },
    {
        path: "tags",
        element: <Layout><h1>tags</h1></Layout>
    },
    {
        path: "statuses",
        element: <Layout><h1>statuses</h1></Layout>
    },
    {
        path: "profile",
        element: <Layout><Profile/></Layout>
    },
]);

export default router