import {styled} from "@mui/material/styles";
import {MouseParallaxContainer, MouseParallaxChild} from "react-parallax-mouse";
import CustomizedSteppers from "../../components/Base/steppers";
import {Container, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

const RedditTextField = styled((props) => (
    <TextField InputProps={{disableUnderline: true}} {...props} sx={{
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(12px, 7px) scale(1)'
        }
    }}/>
), {name: 'create-profile-text-field'})(() => ({

    width: '100%',

    '& label': {
        paddingLeft: 5,
        paddingRight: 5,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(233, 233, 255, 0.6)',
        flex: 'none',
        order: '0',
        flexGrow: '0',


        '&.MuiInputLabel-shrink': {
            fontSize: '12px',
            lineHeight: '16px',
            color: 'rgba(233, 233, 255, 0.4)',
        },
    },

    '& .MuiFilledInput-root': {

        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 4,
        paddingRight: 4,
        gap: '10px',
        width: '100%',
        background: 'rgba(233, 233, 255, 0.08)',
        border: '1px solid rgba(233, 233, 255, 0.08)',
        borderRadius: '16px',
        flex: 'none',
        order: '0',
        alignSelf: 'stretch',
        flexGrow: '0',

        color: '#FFFFFF',
        '& textarea, input': {
            paddingLeft: 12,
            paddingRight: 12,
        },

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const CreateProfileContainer = styled(Container, {name: 'CreateProfileContainer'})(() => ({

    position: 'absolute',
    width: '352px',
    // height: 'calc(calc(100% - 86px) - 50px)',
    // maxHeight: 'calc(100% - 100px)',
    left: 'calc(50% - 352px/2)',
    top: '86px',

    background: 'rgba(126, 126, 158, 0.08)',
    border: '1px solid rgba(233, 233, 255, 0.1)',
    boxShadow: '0px 4px 29px 0px #05130824',
    backdropFilter: 'blur(33.5px)',

    borderRadius: '18px',
    padding: '19px !important',
}))

const CreateProfileHeader = styled('h3')(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '28px',

    textAlign: 'center',

    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '28px',
}))

const CreateProfileStyled = styled('div', {name: 'main-CreateProfileStyled'})(({parallaxHeight}) => ({
    background: 'radial-gradient(123.08% 56.89% at 54.69% 51.78%, #04323C 0%, #090C14 100%)',
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% + ${parallaxHeight}px) !important`,
    // height: `100%`,
    width: '100%',
    position: 'fixed',
    scrollbarColor: '#d4aa70 #e4e4e4 !important',
    scrollbarWidth: 'thin !important',

    '&::-webkit-scrollbar': {
        width: 0
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#e4e4e4',
        borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 100,
        border: '2px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: '#0382F6',
    },

    '& .parallax': {
        height: `calc(100% + ${parallaxHeight}px) !important`,

        overflowY: 'scroll !important',
        scrollbarColor: '#d4aa70 #e4e4e4 !important',
        scrollbarWidth: 'thin !important',

        '&::-webkit-scrollbar': {
            width: 0
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e4e4e4',
            borderRadius: '100px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 100,
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            backgroundColor: '#0382F6',
        },
    }
}))

const CreateButton = styled('button')(() => ({
    boxSizing: 'border-box',

    marginTop: 32,

    width: '100%',
    height: '60px',

    borderRadius: '16px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 18px 20px 18px',
    gap: '10px',

    background: 'none',
    boxShadow: 'none',
    border: '1px solid rgba(233, 233, 255, 0.14)',

    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',

    position: 'relative',

    color: '#FFFFFF',
    textDecoration: 'none',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',

    textAlign: 'center',

    '& .btn-login--icon': {
        position: 'absolute',
        left: '18px',
    },

    '&.enable': {
        cursor: 'pointer',
        background: 'linear-gradient(0deg, rgba(233, 233, 255, 0.14), rgba(233, 233, 255, 0.14)), linear-gradient(0deg, rgba(255, 46, 0, 0), rgba(255, 46, 0, 0)), radial-gradient(95.19% 204.41% at 4.09% 6.81%, #00BF86 0%, #4200FF 100%), radial-gradient(85.48% 130.4% at 92.67% 100%, #2E43AF 0%, rgba(94, 232, 202, 0) 100%)',
        boxShadow: '0px 2px 14px rgba(94, 53, 255, 0.32)',
        backgroundOrigin: 'border-box',
    }
}))

const ImageUploader = () => {
    const [, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const fileInput = useRef(null);

    const handleFile = file => {
        //you can carry out any file validations here...
        setImage(file);
        setPreviewUrl(URL.createObjectURL(file));
    }

    const handleOnDragOver = (event) => {
        event.preventDefault();
    }
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    }
    return (
        <>
            <div className="wrapper">
                <div className="drop_zone"
                     onDragOver={handleOnDragOver}
                     onDrop={handleOnDrop}
                     onClick={() => fileInput.current.click()}
                     style={{
                         boxSizing: 'border-box',

                         width: '92px',
                         height: '92px',

                         marginLeft: 'auto',
                         marginRight: 'auto',

                         borderRadius: '50%',

                         border: previewUrl ? '' : '1px solid rgba(233, 233, 255, 0.14)',

                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',

                         overflow: 'hidden',
                         marginBottom: 32,
                     }}
                >
                    {
                        previewUrl === '' ? (
                            <svg width="26"
                                 height="26"
                                 viewBox="0 0 26 26"
                                 style={{
                                     marginLeft: 'auto',
                                     marginRight: 'auto',
                                 }}
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_860_124737)">
                                    <path
                                        d="M22.75 20.5833V5.41667C22.75 4.225 21.775 3.25 20.5833 3.25H5.41667C4.225 3.25 3.25 4.225 3.25 5.41667V20.5833C3.25 21.775 4.225 22.75 5.41667 22.75H20.5833C21.775 22.75 22.75 21.775 22.75 20.5833ZM9.20833 14.625L11.9167 17.8858L15.7083 13L20.5833 19.5H5.41667L9.20833 14.625Z"
                                        fill="url(#paint0_radial_860_124737)"/>
                                </g>
                                <defs>
                                    <radialGradient id="paint0_radial_860_124737" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                                    gradientTransform="translate(13 6.175) rotate(90) scale(16.575)">
                                        <stop stopColor="white" stopOpacity="0.17"/>
                                        <stop offset="1" stopColor="white" stopOpacity="0.3"/>
                                    </radialGradient>
                                    <clipPath id="clip0_860_124737">
                                        <rect width="26" height="26" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        ) : <img src={previewUrl} alt='upload-preview' style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}/>
                    }
                    <input
                        type="file"
                        accept='image/*'
                        ref={fileInput} hidden
                        onChange={e => handleFile(e.target.files[0])}
                    />
                </div>
            </div>

        </>
    )
}

const CreateProfile = () => {
    const {t} = useTranslation()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const [parallaxHeight, setParallaxHeight] = useState(23)

    const [button, setButton] = useState(false)

    useEffect(() => {
        if (name !== '' || description !== '') {
            setButton(true)
        } else {
            setButton(false)
        }
    }, [name, description])

    return (
        <CreateProfileStyled parallaxHeight={parallaxHeight}>
            <MouseParallaxContainer
                className='parallax'
                containerStyles={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto auto"
                }}
                sx={{position: 'fixed', top: 0 }}
                resetOnLeave
            >
                <MouseParallaxChild factorX={0.15}
                                    factorY={0.1}
                                    updateStyles={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        backfaceVisibility: "hidden",
                                        display: 'flex',
                                        placeContent: 'center',
                                        left: '170px',
                                    }}
                >
                    <svg width="367" height="367" viewBox="0 0 367 367" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_iif_853_127461)">
                            <circle cx="183.5" cy="183.5" r="151.5" fill="url(#paint0_radial_853_127461)"/>
                            <circle cx="183.5" cy="183.5" r="151.5" fill="url(#paint1_radial_853_127461)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_iif_853_127461" x="0" y="0" width="367" height="367" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.0782343 0 0 0 0 0.557264 0 0 0 0 0.708537 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_853_127461"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.1365 0 0 0 0 0.82937 0 0 0 0 0.663081 0 0 0 0.2 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_853_127461" result="effect2_innerShadow_853_127461"/>
                                <feGaussianBlur stdDeviation="16" result="effect3_foregroundBlur_853_127461"/>
                            </filter>
                            <radialGradient id="paint0_radial_853_127461" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(260.131 72.5174) rotate(106.275) scale(273.44)">
                                <stop stopColor="#26A999"/>
                                <stop offset="0.558765" stopColor="#0B5148"/>
                                <stop offset="0.968769" stopColor="#063328"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_853_127461" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(172.93 276.866) rotate(-71.9772) scale(193.589 470.602)">
                                <stop stopColor="#002A2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                <MouseParallaxChild factorX={0.05}
                                    factorY={0.05}
                                    updateStyles={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: '100%',
                                        height: "100%",

                                        position: 'absolute',
                                        top: '30%',

                                        placeContent: 'center',
                                        left: '-170px',
                                    }}
                >
                    <svg width="276" height="276" viewBox="0 0 276 276" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_iif_853_127460)">
                            <circle cx="138" cy="138" r="106" fill="url(#paint0_radial_853_127460)"/>
                            <circle cx="138" cy="138" r="106" fill="url(#paint1_radial_853_127460)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_iif_853_127460" x="0" y="0" width="276" height="276" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.0782343 0 0 0 0 0.557264 0 0 0 0 0.708537 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_853_127460"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.1365 0 0 0 0 0.82937 0 0 0 0 0.663081 0 0 0 0.2 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_853_127460" result="effect2_innerShadow_853_127460"/>
                                <feGaussianBlur stdDeviation="16" result="effect3_foregroundBlur_853_127460"/>
                            </filter>
                            <radialGradient id="paint0_radial_853_127460" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(191.616 60.3488) rotate(106.275) scale(191.318)">
                                <stop stopColor="#26A999"/>
                                <stop offset="0.558765" stopColor="#0B5148"/>
                                <stop offset="0.968769" stopColor="#063328"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_853_127460" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(130.605 203.326) rotate(-71.9772) scale(135.448 329.266)">
                                <stop stopColor="#002A2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                <>
                    <CustomizedSteppers step={2}/>
                    <CreateProfileContainer>
                        <div style={{padding: '31px 31px 40px 31px'}}>
                            <svg width="242" height="74" viewBox="0 0 242 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M44.3638 28.3745C46.567 28.3745 48.5377 28.8203 50.2758 29.7118C52.0139 30.6033 53.3603 31.8416 54.315 33.4265C55.2943 35.0114 55.7839 36.8192 55.7839 38.8499C55.7839 40.8806 55.2943 42.7008 54.315 44.3105C53.3603 45.9202 52.0139 47.1708 50.2758 48.0624C48.5377 48.9539 46.567 49.3996 44.3638 49.3996C42.3564 49.3996 40.5571 49.0653 38.9658 48.3967C37.3746 47.7033 36.0527 46.7251 35 45.4621L39.0025 41.7474C40.4224 43.3323 42.1116 44.1248 44.07 44.1248C45.2451 44.1248 46.2488 43.8648 47.0811 43.3447C47.9379 42.8246 48.5622 42.0941 48.9538 41.153H41.5363V36.4725H48.9171C48.5254 35.581 47.9012 34.8876 47.0444 34.3923C46.212 33.8722 45.2206 33.6122 44.07 33.6122C42.1116 33.6122 40.4224 34.4047 39.0025 35.9896L35 32.3121C36.0527 31.0491 37.3746 30.0833 38.9658 29.4146C40.5571 28.7212 42.3564 28.3745 44.3638 28.3745Z"
                                    fill="white"/>
                                <path
                                    d="M75.0846 38.4042C77.3613 39.1719 78.4996 40.7568 78.4996 43.159C78.4996 44.9668 77.8019 46.3908 76.4065 47.4309C75.0111 48.4462 72.9548 48.9539 70.2375 48.9539H59.0377V28.8203H69.7234C72.3183 28.8203 74.3257 29.2784 75.7455 30.1947C77.1899 31.111 77.9121 32.3988 77.9121 34.058C77.9121 35.0238 77.6673 35.8782 77.1777 36.6211C76.688 37.3641 75.9904 37.9584 75.0846 38.4042ZM65.2802 36.9183H69.0991C70.7148 36.9183 71.5227 36.3116 71.5227 35.0981C71.5227 33.9342 70.7148 33.3522 69.0991 33.3522H65.2802V36.9183ZM69.6132 44.3848C71.3024 44.3848 72.1469 43.7657 72.1469 42.5275C72.1469 41.2892 71.3268 40.6701 69.6866 40.6701H65.2802V44.3848H69.6132Z"
                                    fill="white"/>
                                <path
                                    d="M92.5627 43.9019C93.5175 43.9019 94.3376 43.7657 95.023 43.4933C95.7329 43.2209 96.4429 42.7751 97.1528 42.156L100.788 46.0193C98.9032 48.1986 96.0879 49.2882 92.3424 49.2882C90.0168 49.2882 87.9727 48.8424 86.2101 47.9509C84.4475 47.0594 83.0766 45.8212 82.0974 44.2362C81.1426 42.6513 80.6652 40.8559 80.6652 38.8499C80.6652 36.8688 81.1426 35.0981 82.0974 33.5379C83.0521 31.953 84.3618 30.7148 86.0265 29.8232C87.7156 28.9317 89.6006 28.486 91.6814 28.486C93.6154 28.486 95.378 28.8946 96.9692 29.7118C98.5849 30.5043 99.8701 31.6806 100.825 33.2408C101.78 34.7762 102.257 36.6087 102.257 38.7385L88.0461 41.4874C88.805 43.0971 90.3105 43.9019 92.5627 43.9019ZM91.7182 33.5008C90.5186 33.5008 89.5394 33.8846 88.7805 34.6523C88.0216 35.3953 87.5932 36.4725 87.4953 37.8841L95.5738 36.2868C95.329 35.4448 94.8639 34.7762 94.1784 34.2809C93.493 33.7608 92.6729 33.5008 91.7182 33.5008Z"
                                    fill="#6066FF"/>
                                <path
                                    d="M116.233 28.486C118.412 28.486 120.309 28.907 121.925 29.7489C123.54 30.5909 124.777 31.8044 125.633 33.3893C126.515 34.9495 126.955 36.7821 126.955 38.8871C126.955 40.9673 126.551 42.7999 125.744 44.3848C124.936 45.945 123.797 47.1585 122.329 48.0252C120.86 48.8672 119.158 49.2882 117.224 49.2882C114.948 49.2882 113.161 48.5824 111.863 47.1708V56.1604H104.886V38.9614C104.886 36.8811 105.351 35.0486 106.282 33.4636C107.212 31.8787 108.534 30.6529 110.247 29.7861C111.961 28.9193 113.956 28.486 116.233 28.486ZM115.902 43.679C117.151 43.679 118.155 43.2456 118.914 42.3789C119.697 41.4874 120.089 40.3234 120.089 38.8871C120.089 37.4507 119.697 36.2992 118.914 35.4324C118.155 34.5409 117.151 34.0951 115.902 34.0951C114.654 34.0951 113.65 34.5409 112.891 35.4324C112.132 36.2992 111.753 37.4507 111.753 38.8871C111.753 40.3482 112.12 41.5121 112.855 42.3789C113.613 43.2456 114.629 43.679 115.902 43.679Z"
                                    fill="white"/>
                                <path d="M130.327 28.8203H137.267V36.2868H144.428V28.8203H151.405V48.9539H144.428V41.896H137.267V48.9539H130.327V28.8203Z"
                                      fill="white"/>
                                <path
                                    d="M176.931 28.8203V45.2763C176.931 49.0158 175.94 51.8142 173.957 53.6715C171.998 55.5536 169.171 56.4947 165.474 56.4947C163.54 56.4947 161.729 56.2718 160.04 55.8261C158.375 55.3803 156.955 54.724 155.78 53.8573L158.314 48.9167C159.097 49.5854 160.076 50.1054 161.252 50.4769C162.451 50.8731 163.638 51.0713 164.813 51.0713C166.576 51.0713 167.873 50.6626 168.706 49.8454C169.538 49.0282 169.954 47.8147 169.954 46.205V45.7593C168.461 47.1956 166.6 47.9138 164.373 47.9138C161.802 47.9138 159.746 47.1461 158.204 45.6107C156.686 44.0753 155.927 41.7722 155.927 38.7013V28.8203H162.904V37.5498C162.904 39.0852 163.185 40.1996 163.749 40.893C164.312 41.5864 165.132 41.9331 166.209 41.9331C167.31 41.9331 168.204 41.5369 168.889 40.7444C169.599 39.952 169.954 38.7633 169.954 37.1783V28.8203H176.931Z"
                                    fill="white"/>
                                <path
                                    d="M200.85 48.9539V38.367L195.746 47.1708H192.772L187.815 38.2184V48.9539H181.535V28.8203H188.879L194.424 39.3328L200.52 28.8203H207.093L207.13 48.9539H200.85Z"
                                    fill="white"/>
                                <path
                                    d="M210.806 24.5902C210.071 24.5902 209.408 24.4365 208.819 24.1293C208.237 23.8154 207.779 23.3813 207.445 22.8269C207.11 22.2726 206.943 21.6448 206.943 20.9435C206.943 20.2422 207.11 19.6144 207.445 19.0601C207.779 18.5057 208.237 18.075 208.819 17.7677C209.408 17.4538 210.071 17.2969 210.806 17.2969C211.449 17.2969 212.027 17.4104 212.543 17.6375C213.058 17.8646 213.486 18.1918 213.827 18.6193L212.563 19.7614C212.108 19.2137 211.556 18.9399 210.907 18.9399C210.526 18.9399 210.184 19.0234 209.883 19.1903C209.589 19.3573 209.358 19.5944 209.191 19.9016C209.03 20.2022 208.95 20.5495 208.95 20.9435C208.95 21.3376 209.03 21.6882 209.191 21.9954C209.358 22.296 209.589 22.5297 209.883 22.6967C210.184 22.8637 210.526 22.9472 210.907 22.9472C211.556 22.9472 212.108 22.6733 212.563 22.1257L213.827 23.2678C213.486 23.6952 213.058 24.0225 212.543 24.2495C212.027 24.4766 211.449 24.5902 210.806 24.5902Z"
                                    fill="#EDF3FF"/>
                                <path
                                    d="M217.784 22.5865H216.7V24.4499H214.713V17.4371H217.924C218.56 17.4371 219.112 17.544 219.58 17.7577C220.049 17.9648 220.41 18.2653 220.664 18.6594C220.918 19.0467 221.045 19.5042 221.045 20.0319C221.045 20.5394 220.925 20.9836 220.684 21.3643C220.45 21.7383 220.112 22.0322 219.671 22.2459L221.186 24.4499H219.058L217.784 22.5865ZM219.038 20.0319C219.038 19.7046 218.935 19.4508 218.727 19.2705C218.52 19.0901 218.212 19 217.804 19H216.7V21.0537H217.804C218.212 21.0537 218.52 20.9669 218.727 20.7932C218.935 20.6129 219.038 20.3591 219.038 20.0319Z"
                                    fill="#EDF3FF"/>
                                <path
                                    d="M230.361 17.2969C231.19 17.2969 231.836 17.544 232.297 18.0382C232.766 18.5258 233 19.2337 233 20.1621V24.4499H231.013V20.2222C231.013 19.8014 230.903 19.4842 230.682 19.2705C230.461 19.0501 230.167 18.9399 229.799 18.9399C229.404 18.9399 229.089 19.0567 228.855 19.2905C228.628 19.5176 228.514 19.8615 228.514 20.3224V24.4499H226.527V20.2222C226.527 19.8014 226.423 19.4842 226.216 19.2705C226.015 19.0501 225.738 18.9399 225.383 18.9399C225.002 18.9399 224.697 19.0567 224.47 19.2905C224.242 19.5176 224.129 19.8615 224.129 20.3224V24.4499H222.142V17.4371H224.068V18.2186C224.282 17.918 224.557 17.6909 224.891 17.5373C225.233 17.377 225.627 17.2969 226.075 17.2969C226.537 17.2969 226.939 17.3804 227.28 17.5473C227.628 17.7143 227.909 17.9581 228.123 18.2787C228.364 17.9581 228.678 17.7143 229.066 17.5473C229.454 17.3804 229.886 17.2969 230.361 17.2969Z"
                                    fill="#EDF3FF"/>
                            </svg>
                        </div>

                        <CreateProfileHeader>Профиль</CreateProfileHeader>

                        <ImageUploader/>

                        <RedditTextField
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            label="Имя (понятное для других участников)"
                            id="name-input"
                            variant="filled"
                            style={{marginTop: 0}}
                        />

                        <RedditTextField
                            value={description}
                            onChange={(e) => {
                                console.log(e)
                                setParallaxHeight(e?.target?.clientHeight ?? 23)
                                setDescription(e.target.value)
                            }}
                            label="О себе (должность, контакты и т.п.)"
                            id="description-input"
                            variant="filled"
                            multiline
                            style={{marginTop: 8}}
                        />

                        <CreateButton className={`btn-login ${button ? 'enable' : ''}`}
                                      onClick={() => {
                                      }}
                        >
                            <span className="btn-login--txt">{t('Создать')}</span>
                        </CreateButton>
                    </CreateProfileContainer>
                </>
                <MouseParallaxChild factorX={-0.05}
                                    factorY={-0.05}
                                    updateStyles={{
                                        zIndex: 10,
                                        display: "flex",
                                        width: '149px',
                                        position: 'absolute',

                                        top: -45,
                                        right: 'calc(50% + 24px)',
                                    }}
                >
                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ii_853_127465)">
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint0_radial_853_127465)"/>
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint1_radial_853_127465)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_ii_853_127465" x="0" y="0" width="159" height="173" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.0782343 0 0 0 0 0.557264 0 0 0 0 0.708537 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_853_127465"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.1365 0 0 0 0 0.82937 0 0 0 0 0.663081 0 0 0 0.2 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_853_127465" result="effect2_innerShadow_853_127465"/>
                            </filter>
                            <radialGradient id="paint0_radial_853_127465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(112.183 19.9244) rotate(106.275) scale(134.464)">
                                <stop stopColor="#26A999"/>
                                <stop offset="0.558765" stopColor="#0B5148"/>
                                <stop offset="0.968769" stopColor="#063328"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_853_127465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(69.3023 120.413) rotate(-71.9772) scale(95.1971 231.418)">
                                <stop stopColor="#002A2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
                <MouseParallaxChild factorX={-0.05}
                                    factorY={-0.1}
                                    updateStyles={{
                                        zIndex: 10,
                                        display: "flex",
                                        position: 'absolute',
                                        width: '149px',

                                        bottom: -40,
                                        right: 'calc(50% + 120px)',
                                    }}
                >
                    <svg className={'bottom-front-bubble'} width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ii_853_127465)">
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint0_radial_853_127465)"/>
                            <circle cx="74.5" cy="74.5" r="74.5" fill="url(#paint1_radial_853_127465)" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_ii_853_127465" x="0" y="0" width="159" height="173" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="14"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.0782343 0 0 0 0 0.557264 0 0 0 0 0.708537 0 0 0 0.3 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_853_127465"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="10" dy="30"/>
                                <feGaussianBlur stdDeviation="12"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.1365 0 0 0 0 0.82937 0 0 0 0 0.663081 0 0 0 0.2 0"/>
                                <feBlend mode="normal" in2="effect1_innerShadow_853_127465" result="effect2_innerShadow_853_127465"/>
                            </filter>
                            <radialGradient id="paint0_radial_853_127465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(112.183 19.9244) rotate(106.275) scale(134.464)">
                                <stop stopColor="#26A999"/>
                                <stop offset="0.558765" stopColor="#0B5148"/>
                                <stop offset="0.968769" stopColor="#063328"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_853_127465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(69.3023 120.413) rotate(-71.9772) scale(95.1971 231.418)">
                                <stop stopColor="#002A2D" stopOpacity="0.8"/>
                                <stop offset="1" stopColor="#000C2D" stopOpacity="0"/>
                            </radialGradient>
                        </defs>
                    </svg>
                </MouseParallaxChild>
            </MouseParallaxContainer>
        </CreateProfileStyled>
    )
}

export default CreateProfile