import React from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {styled} from "@mui/material";
import {useApolloClient} from "@apollo/client";
import {useLazySetTaskEditMoveMethodQuery} from "../../redux/services/taskEdit";
import {useLazySetClientEditMoveMethodQuery} from "../../redux/services/clientEdit";

const Board = styled('div')({
    background: '#F5F4FA',
    display: 'flex',
});

const Container = styled('div', {name: 'Board-Container'})({
    minHeight: 'calc(100vh - 120px)',
    minWidth: '100vw',
    display: 'inline-flex',
    '&::after': {
        content: '"-"',
        opacity: 0,
    }
})

export function BoardComponent({board, setNewSortBoard, Column}) {
    const cache = useApolloClient().cache
    const [taskEditMoveTrigger,] = useLazySetTaskEditMoveMethodQuery()
    const [clientEditMoveTrigger,] = useLazySetClientEditMoveMethodQuery()

    function onDragEnd(result) {
        if (result.type === 'COLUMN') {
            let diff = Math.abs(result.source.index - result.destination.index)
            setNewSortBoard(
                (result.source.index > result.destination.index) ? -diff : diff,
                result.draggableId
            )
        }

        if (result.type === 'TASK_CARD') {
            let sourceColumnId = result.source.droppableId
            let targetColumnId = result.destination.droppableId
            let cardId = result.draggableId.split('|')[1]

            if (sourceColumnId !== targetColumnId) {
                const tasks = cache.cache.get(`tasks:${cardId}`)
                const sourceColumn = cache.cache.get(sourceColumnId)
                const targetColumn = cache.cache.get(targetColumnId)
                console.log({tasks, sourceColumn, targetColumn})

                const columns_tasks_assignees = []
                const columns_tasks_tags = []
                const columns_tasks_statuses = []
                const columns_tasks_statuses_options = []

                for (const [, value] of Object.entries(targetColumn)) {
                    if (Array.isArray(value)) {
                        value.forEach((item) => {
                             if (item.typename === "columns_tasks_assignees") {
                                 columns_tasks_assignees.push(cache.cache.get(item.id)?.profile_id)
                             }
                             if (item.typename === "columns_tasks_tags") {
                                 columns_tasks_tags.push(cache.cache.get(item.id).tag_id)
                             }
                             if (item.typename === "columns_tasks_statuses") {
                                 // split : -> [1] split | -> get 0 & 1
                                 const statuses = item.id.split(':')[1].split('|')

                                 columns_tasks_statuses.push(cache.cache.get(`statuses_list:${statuses[0]}`).id)
                                 if (statuses[1]) {
                                    columns_tasks_statuses_options.push(cache.cache.get(`statuses_options:${statuses[1]}`).id)
                                 }
                             }
                        })
                    }
                }

                const move = {
                    id: tasks.id,
                    profile_ids: columns_tasks_assignees?.length === 0 ? null : columns_tasks_assignees,
                    tag_ids: columns_tasks_tags?.lineHeight === 0 ? null : columns_tasks_tags,
                    status_list_ids: columns_tasks_statuses?.lineHeight === 0 ? null : columns_tasks_statuses,
                    status_option_ids: columns_tasks_statuses_options?.lineHeight === 0 ? null : columns_tasks_statuses_options,
                }
                console.log(move)

                taskEditMoveTrigger(move)
            }
        }
        if (result.type === 'CUSTOMERS_CARD') {
            console.log({result})
            let sourceColumnId = result.source.droppableId
            let targetColumnId = result.destination.droppableId
            let cardId = result.draggableId.split('|')[1]

            if (sourceColumnId !== targetColumnId) {
                const client = cache.cache.get(`clients:${cardId}`)
                const targetColumn = cache.cache.get(targetColumnId)
                // console.log({clients, targetColumn})

                const columns_clients_assignees = []
                const columns_clients_tags = []
                const columns_clients_statuses = []
                const columns_clients_statuses_options = []

                for (const [, value] of Object.entries(targetColumn)) {
                    if (Array.isArray(value)) {
                        value.forEach((item) => {
                            console.log({item})
                             if (item.typename === "columns_clients_assignees") {
                                 columns_clients_assignees.push(cache.cache.get(item.id)?.profile_id)
                             }
                             if (item.typename === "columns_clients_tags") {
                                 columns_clients_tags.push(cache.cache.get(item.id).tag_id)
                             }
                             if (item.typename === "columns_clients_statuses") {
                                 // split : -> [1] split | -> get 0 & 1
                                 const statuses = item.id.split(':')[1].split('|')

                                 console.log(`statuses_list:${statuses[0]}`)

                                 columns_clients_statuses.push(cache.cache.get(`statuses_list:${statuses[0]}`).id)
                                 if (statuses[1]) {
                                    columns_clients_statuses_options.push(cache.cache.get(`statuses_options:${statuses[1]}`).id)
                                 }
                             }
                        })
                    }
                }

                const move = {
                    id: client.id,
                    profile_ids: columns_clients_assignees?.length === 0 ? null : columns_clients_assignees,
                    tag_ids: columns_clients_tags?.lineHeight === 0 ? null : columns_clients_tags,
                    status_list_ids: columns_clients_statuses?.lineHeight === 0 ? null : columns_clients_statuses,
                    status_option_ids: columns_clients_statuses_options?.lineHeight === 0 ? null : columns_clients_statuses_options,
                }
                console.log(move)

                clientEditMoveTrigger(move)
            }
        }
    }

    return (
        <Board id={'board'}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="board"
                    type="COLUMN"
                    direction="horizontal"
                >
                    {(provided) => (
                        <Container ref={provided.innerRef} {...provided.droppableProps}>
                            {board?.columns?.map((columnData, index) => (
                                <Column
                                    key={index}
                                    index={index}
                                    columnData={columnData}
                                />
                            ))}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
        </Board>
    );
}

