import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const authCheckApi = createApi({
    reducerPath: 'authApiCheck',
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({baseUrl: 'https://auth-check.v202207.api.evernum.ru/'}),
    endpoints: (builder) => ({
        checkAuthMethod: builder.query({
            query: (code) => `check?code=${code}`,
            keepUnusedDataFor: 1,
        }),
    }),
})

export const {useCheckAuthMethodQuery} = authCheckApi
