import {useDispatch} from "react-redux";
import {useCheckAuthMethodQuery} from "../../redux/services/authCheck";
import {useNavigate} from "react-router";
import {useEffect} from "react";
import {setAccessToken, setIsNewUser, setPhoneNumber, setProfileId} from "../../redux/auth/Reducer";
import {Wait} from "./init";
import {DotLoader} from "../../components/Base/DotLoader";

export const AuthCheck = ({code, authDispatch}) => {
    const dispatch = useDispatch()
    const {data, error, isLoading} = useCheckAuthMethodQuery(code)
    const navigate = useNavigate()

    useEffect(() => {
        if (data?.data?.status) {
            dispatch(setAccessToken(data.data.accessToken))
            dispatch(setIsNewUser(data.data.isNewUser))
            dispatch(setPhoneNumber(data.data.phoneNumber))
            dispatch(setProfileId(data.data.profileId))
            navigate('/tasks/')
        }
    }, [data, dispatch, navigate])

    return (
        <>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <DotLoader style={{height: 48}}/>
            ) : data ? (
                <>
                    <Wait dispatch={authDispatch}/>
                </>
            ) : null}
        </>
    )
}

