import {Draggable, Droppable} from "react-beautiful-dnd";
import React, {useEffect, useState} from "react";
import {useSubscription} from "@apollo/client";
import {
    COLUMNS_TASKS_SUBSCRIPTION,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS, COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS_WITH_OPTION_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS, COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS, COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS_WITH_OPTION_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS, COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS, COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS
} from "../../../components/GQL/Subscription/Tasks";
import {styled} from "@mui/material/styles";
import ColumnHeader from "./headers/columnHeader";
import TaskCard from "./taskCard";

const TasksList = styled('div', {name: 'column-tasks-list'})(({isDraggingOver}) => ({
    background: isDraggingOver ? "rgba(240, 238, 246, 0.94)" : "",
    border: isDraggingOver ? '1px dashed #6066FF' : '',

    minHeight: isDraggingOver ? 200 : 250,
    maxHeight: 'calc(100vh - 252px)',
    borderRadius: 12,
    overflowX: 'hidden',
    overflowY: 'scroll',

    scrollbarColor: '#d4aa70 #e4e4e4',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
        width: 0
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#e4e4e4',
        borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 100,
        border: '2px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: '#0382F6',
    },
}))

const ColumnStyled = styled('div', {name: 'column'})(({isDragging}) => ({
    boxSizing: 'border-box',

    /* Auto layout */

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 8,
    gap: 8,

    width: 355,
    // height: 896,
    left: 21,
    top: 148,


    /* Main/2LightTheme */
    background: 'rgba(22, 22, 51, 0.02)',

    /* Main/2LightTheme */
    border: isDragging ? '1px solid rgba(22, 22, 51, 1)' : '1px solid rgba(22, 22, 51, 0.02)',
    borderRadius: 24,
    margin: '5px',
}))

const getSubscription = (columnsTasksAssignees, columnsTasksStatuses, columnsTasksTags, columnsTasksStatusesOptions) => {

    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS, {variables: {tagIds: columnsTasksTags}}];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS, {variables: {statusIds: columnsTasksStatuses}}];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0 && columnsTasksStatusesOptions?.length > 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS_WITH_OPTION_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS_WITH_OPTION_IDS, {variables: {statusIds: columnsTasksStatuses, statusOptionIds: columnsTasksStatusesOptions,}}];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length === 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS, {variables: {profileIds: columnsTasksAssignees}}];
    }

    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                tagIds: columnsTasksTags
            }
        }];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                statusIds: columnsTasksStatuses
            }
        }];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0 && columnsTasksStatusesOptions?.length > 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS_WITH_OPTION_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS_WITH_OPTION_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                statusIds: columnsTasksStatuses,
                statusOptionIds: columnsTasksStatusesOptions,
            }
        }];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS, {
            variables: {
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses
            }
        }];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length > 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS, {
            variables: {
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses,
                statusOptionIds: columnsTasksStatusesOptions,
            }
        }];
    }

    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length === 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses
            }
        }];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0 && columnsTasksStatusesOptions?.length > 0) {
        // console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS_WITH_OPTION_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses,
                statusOptionIds: columnsTasksStatusesOptions,
            }
        }];
    }

    // console.log('COLUMNS_TASKS_SUBSCRIPTION')
    return [COLUMNS_TASKS_SUBSCRIPTION, {}]
}

export const TasksColumn = ({columnData, index}) => {
    const columnsTasksAssignees = columnData.columns_tasks_assignees?.map(i => i.id)
    const columnsTasksTags = columnData.columns_tasks_tags?.map(i => i.id)
    const columnsTasksStatuses = columnData.columns_tasks_statuses?.map(i => i?.statuses_list?.id)
    const columnsTasksStatusesOptions = columnData.columns_tasks_statuses?.map(i => i?.statuses_option?.id).filter(i => i !== null && i !== undefined)

    const {loading, error, data} = useSubscription(...getSubscription(columnsTasksAssignees, columnsTasksStatuses, columnsTasksTags, columnsTasksStatusesOptions));

    const [cards, setCards] = useState(columnData.cards)

    // console.log({columnData})

    useEffect(() => {
        // console.log({data})
        if (data) {
            setCards(data.tasks)
        }
    }, [data])

    return (
        <Draggable draggableId={columnData.id} index={index}>
            {(provided, snapshot) => (
                <ColumnStyled ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging}>
                    <ColumnHeader title={columnData.title} count={cards.length} avatars={['']} provided={provided} snapshot={snapshot}/>
                    {loading ? <p>Loading ...</p> : ''}
                    {error ? <pre> Error in TASKS {JSON.stringify({error, query: getSubscription(columnsTasksAssignees, columnsTasksStatuses, columnsTasksTags, columnsTasksStatusesOptions)}, null, 2)} </pre> : ''}
                    {data ? (
                        <Droppable
                            droppableId={`columns_tasks:${columnData.id}`}
                            type="TASK_CARD"
                            // direction="horizontal"
                        >
                            {(provided, snapshot) => (
                                <TasksList
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                    {...provided.droppableProps}
                                >
                                    {cards?.map((item, index) => (
                                        <TaskCard content={item} key={index} index={index} columnsTasksId={`columns_tasks:${columnData.id}`}/>
                                    ))}
                                    {
                                        cards?.length === 0 ? (
                                            <TaskCard content={null} index={0} columnsTasksId={`columns_tasks:${columnData.id}`}/>
                                        ) : ''
                                    }
                                    {provided.placeholder}
                                </TasksList>
                            )}
                        </Droppable>
                    ) : ''}
                </ColumnStyled>
            )}
        </Draggable>
    )
}