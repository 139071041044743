import {gql} from "@apollo/client";
import {PROFILE_FIELDS} from "../Fragments/Profile";


export const BOARDS_COLUMNS_TASKS_QUERY = gql`
    ${PROFILE_FIELDS}
    query getBoardsColumnsTasks {
        boards_columns_tasks(where: {column_task_id: {_is_null: false}}, order_by: {position: asc}) {
            id: column_task_id
            column_task_id
            position
            columns_task {
                id
                title
                columns_tasks_assignees(where: {profile_id: {_gt: 0}}) {
                    id: profile_id
                    profile_id
                }
                columns_tasks_statuses(where: {status_option_id: {_gt: 0}}) {
                    id: status_option_id
                    status_option_id
                }
                columns_tasks_tags(where:{ tag_id: {_gt: 0}}) {
                    id: tag_id
                    tag_id
                }
                #colors_column {
                #    id
                #    weight
                #    code
                #}
                profile {
                    ...ProfileFields
                }
            }
        }
    }
`;
