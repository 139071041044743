import {Draggable, Droppable} from "react-beautiful-dnd";
import React, {useEffect, useState} from "react";
import {useSubscription} from "@apollo/client";
import {
    COLUMNS_TASKS_SUBSCRIPTION,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS,
    COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS, COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS
} from "../../../components/GQL/Subscription/Tasks";
import {styled} from "@mui/material/styles";

const ColumnDropZone = styled('div', {name: 'column-drop-zone'})(({isDraggingOver}) => ({
    background: isDraggingOver ? "rgba(240, 238, 246, 0.94)" : "",
    border: isDraggingOver ? '1px dashed #6066FF' : '',
    borderRadius: isDraggingOver ? '24px' : '',
    minHeight: 50,
}))

const ColumnStyled = styled('div', {name: 'column'})(() => ({
    boxSizing: 'border-box',

    /* Auto layout */

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 8,
    gap: 8,

    width: 355,
    // height: 896,
    left: 21,
    top: 148,


    /* Main/2LightTheme */
    background: 'rgba(22, 22, 51, 0.02)',

    /* Main/2LightTheme */
    border: '1px solid rgba(22, 22, 51, 0.02)',
    borderRadius: 24,
    margin: '5px',
}))

const getSubscription = (columnsTasksAssignees, columnsTasksStatuses, columnsTasksTags) => {

    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length > 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_TAG_IDS, {variables: {tagIds: columnsTasksTags}}];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_STATUS_IDS, {variables: {statusIds: columnsTasksStatuses}}];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length === 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILE_IDS, {variables: {profileIds: columnsTasksAssignees}}];
    }

    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length === 0 && columnsTasksTags?.length > 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                tagIds: columnsTasksTags
            }
        }];
    }
    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length === 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_STATUS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                statusIds: columnsTasksStatuses
            }
        }];
    }
    if (columnsTasksAssignees?.length === 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_TAGS_IDS_STATUS_IDS, {
            variables: {
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses
            }
        }];
    }

    if (columnsTasksAssignees?.length > 0 && columnsTasksStatuses?.length > 0 && columnsTasksTags?.length > 0) {
        console.log('COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS')
        return [COLUMNS_TASKS_SUBSCRIPTION_PROFILES_IDS_TAGS_IDS_STATUS_IDS, {
            variables: {
                profileIds: columnsTasksAssignees,
                tagIds: columnsTasksTags,
                statusIds: columnsTasksStatuses
            }
        }];
    }

    console.log('COLUMNS_TASKS_SUBSCRIPTION')
    return [COLUMNS_TASKS_SUBSCRIPTION, {}]
}

export const CallsColumn = ({columnData, ind, ColumnHeader, Card}) => {
    const columnsTasksAssignees = columnData.columns_tasks_assignees?.map(i => i.id)
    const columnsTasksStatuses = columnData.columns_tasks_statuses?.map(i => i.id)
    const columnsTasksTags = columnData.columns_tasks_tags?.map(i => i.id)

    const {loading, error, data} = useSubscription(...getSubscription(columnsTasksAssignees, columnsTasksStatuses, columnsTasksTags));

    const [cards, setCards] = useState(columnData.cards)

    useEffect(() => {
        console.log({data})
        if (data) {
            setCards(data.tasks)
        }
    }, [data])

    return (
        <ColumnStyled>
            <ColumnHeader title={columnData.title} count={cards.length} avatars={['']}/>
            {
                loading ? <p>Loading ...</p> : ''
            }
            {
                error ? (
                    <pre>
                      Error in TASKS
                        {JSON.stringify(error, null, 2)}
                    </pre>
                ) : ''
            }
            {data ? (
                <Droppable droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                        <ColumnDropZone
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                        >
                            {cards?.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <Card provided={provided} snapshot={snapshot} content={item}/>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ColumnDropZone>
                    )}
                </Droppable>
            ) : ''}
        </ColumnStyled>
    )
}