import React, {useEffect, useState} from "react";
import {useApolloClient, useLazyQuery} from "@apollo/client";
import {BOARDS_COLUMNS_TASKS_QUERY} from "../../../components/GQL/Query/BoardsColumnsTasks";
import {BoardComponent} from "../../../components/Board/boardComponent";
import {useSelector} from "react-redux";
import {selectorGroupId} from "../../../redux/auth/Reducer";
import {TasksColumn} from "./tasksColumn";
import {useLazySetTaskColumnPositionMethodQuery} from "../../../redux/services/columns";

const TasksBoard = () => {
    const [board, setBoard] = useState({columns: []});

    const cache = useApolloClient().cache

    const lastGroupID = localStorage.getItem('lastG')
    const groupId = useSelector(selectorGroupId)

    const [getBoardsColumns, {loading, error, data}] = useLazyQuery(BOARDS_COLUMNS_TASKS_QUERY);
    const [taskColumnPositionTrigger] = useLazySetTaskColumnPositionMethodQuery()

    useEffect(() => {
        if (lastGroupID === groupId) {
            getBoardsColumns({
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-only'
            })
        } else {
            localStorage.setItem('lastG', groupId)
            getBoardsColumns({
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network'
            })
        }
        // eslint-disable-next-line
    }, [groupId])

    useEffect(() => {
        if (data) {
            let columns = data?.boards_columns_tasks?.map((item) => {
                return {
                    id: item.id,
                    title: null,
                    ...item.columns_task,
                    position: item.position,
                    cards: [],
                }
            })
            columns = columns.filter((i) => i?.title !== null).sort((a, b) => a.position - b.position)

            setBoard({columns})
        }
    }, [data, loading, error])

    if (loading) {
        return <p>Loading ...</p>;
    }
    if (error) {
        return (
            <pre>
              Error in TASKS
                {JSON.stringify(error, null, 2)}
            </pre>
        );
    }

    const setNewSortBoard = (diff, columnId) => {
        let position = 0;
        let columns = board.columns.map((item) => {
            if (item.id === columnId) {
                position = item.position + diff
                return {
                    ...item,
                    position: position
                }
            } else {
                return item
            }
        })

        columns = columns.filter((i) => i?.title !== null).sort((a, b) => a.position - b.position)

        setBoard({columns})

        // дергаем апи изменения позиции колонки.
        taskColumnPositionTrigger({groupId: localStorage.getItem('groupId'), columnId, position})
        // обновление кеша.
        const object = cache.cache.get(`boards_columns_tasks:${columnId}`)
        cache.cache.set(`boards_columns_tasks:${columnId}`, {...object, position: position})
    }

    return (
        <BoardComponent board={board} setNewSortBoard={setNewSortBoard} Column={TasksColumn}/>
    )
}

export default TasksBoard