import {useEffect} from "react";

export const DotLoader = ({style}) => {
    useEffect(() => {
        const params = {
            container: document.getElementById('lottie'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: window.animationData
        };
        window.anim = window.lottie.loadAnimation(params);
        return () => {
            window.anim = null
        }
    }, [])

    return (
        <div id="lottie" style={{
            ...style
        }}/>
    )
}