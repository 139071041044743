import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const columnsApi = createApi({
    reducerPath: 'columnsPosition',
    baseQuery: fetchBaseQuery({baseUrl: 'https://column-position.v202211.api.evernum.ru/',}),
    endpoints: (builder) => ({
        setTaskColumnPositionMethod: builder.query({
            query: ({groupId, columnId, position}) => ({
                url: `task/set?group_id=${groupId}&access_token=${localStorage.getItem('token')}&id=${columnId}&position=${position}`,
                method: 'POST',
            }),
        }),
        setClientColumnPositionMethod: builder.query({
            query: ({groupId, columnId, position}) => ({
                url: `client/set?group_id=${groupId}&access_token=${localStorage.getItem('token')}&id=${columnId}&position=${position}`,
                method: 'POST',
            }),
        }),
    }),
})

export const {useLazySetTaskColumnPositionMethodQuery, useLazySetClientColumnPositionMethodQuery} = columnsApi
