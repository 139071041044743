import {gql} from "@apollo/client";
import {COLOR_FIELDS} from "./Color";
import {PROFILE_FIELDS} from "./Profile";

export const TASKS_FIELDS = gql`
    ${COLOR_FIELDS}
    ${PROFILE_FIELDS}
    fragment TasksFields on tasks {
        title
        id
        due_date
        is_completed
        is_deleted
        is_pinned
        reminder_date
        description
        deleted_at
        created_at
        creator_profile_id
        has_assignees
        has_comments
        has_events
        has_statuses
        has_tags
        tasks_tags{
            tags_list {
                id
                value
                color {
                    ...ColorFields
                }
            }
        }
        tasks_statuses(where: {status_option_id: {_is_null: false}}) {
            statuses_option {
                id
                value
                color {
                    ...ColorFields
                }
            }
        }
        tasks_assignees {
            profile {
                ...ProfileFields
            }
        }
        clients_tasks {
            client {
                id
                name
                is_client
                description
                clients_contacts {
                    name
                    number
                }
            }
        }
        profile {
            ...ProfileFields
        }
    }
`;