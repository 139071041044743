import {gql} from "@apollo/client";


export const PROFILE_QUERY = gql`
    query profile_by_pk($profileId: Int!) {
        profiles(where: {id: {_eq: $profileId}}) {
            id
            name,
            number,
            avatar_id
        }
    }
`;