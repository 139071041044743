import {createSlice} from "@reduxjs/toolkit";

const initialState  = {
    themeName: localStorage.getItem('theme') ?? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
    direction: localStorage.getItem('direction') ?? 'ltr',
    locale: localStorage.getItem('locale')
}

export const theme = createSlice({
    name: 'theme',
    initialState ,
    reducers: {
        darkAction: (state) => {
            state.themeName = 'dark'
        },
        lightAction: (state) => {
            state.themeName = 'light'
        },
        toggleThemeAction: (state) => {
            state.themeName = state.themeName === 'dark' ? 'light' : 'dark'
        },
        toggleDirectionAction: (state) => {
            state.direction = state.direction === 'ltr' ? 'rtl' : 'ltr'
        },
        rtlDirectionAction: (state) => {
            state.direction = 'rtl'
        },
        ltrDirectionAction: (state) => {
            state.direction = 'ltr'
        },
        setLocale: (state, action) => {
            state.locale = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {darkAction, lightAction, toggleThemeAction, toggleDirectionAction, rtlDirectionAction, ltrDirectionAction, setLocale,} = theme.actions

export default theme.reducer
