import React from "react";
import {IconSquare} from "../../../components/Base/IconComponents";
import {styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import {Avatar} from "@mui/material";
import moment from 'moment';
import 'moment/locale/ru';
import {Draggable} from "react-beautiful-dnd";

moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'L'
    }
})

moment.updateLocale('ru', {
    calendar: {
        lastDay: '[Вчера] HH:mm',
        sameDay: '[Сегодня] HH:mm',
        nextDay: '[Завтра] HH:mm',
        lastWeek: 'DD MMMM HH:mm',
        nextWeek: 'DD MMMM HH:mm',
        sameElse: 'L'
    }
})

const CardBody = styled('div', {name: 'card-body'})(() => ({
    width: '100%'
}));

const TaskTitle = styled('span', {name: 'task-title'})(() => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',

    /* Main/100LightTheme */
    color: '#0C0C13',

    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
}))

const ContactAvatar = styled(Avatar, {name: 'contact-avatar'})(() => ({
    width: 28,
    height: 28,
    color: '#6066FF',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px',

    background: 'rgba(96, 102, 255, 0.1)',
    border: '1px solid rgba(0, 0, 0, 0.04)',
}))

const ContactRow = styled("div", {name: 'contact-row'})(() => ({
    display: 'flex',
    float: 'left',
    gap: '8px',
    '& .text': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',

        display: 'flex',
        alignItems: 'center',

        /* Main/40LightTheme */
        color: 'rgba(22, 22, 51, 0.54)',

        flex: 'none',
        order: 0,
        flexGrow: '0',
    }
}))

const TagsRow = styled('div', {name: 'tags-row'})(() => ({
    display: 'flex',
    float: 'left',
    gap: '4px',
}))

const TagItem = styled('div', {name: 'tag-item'})(({bgColor, isMax}) => ({
    backgroundColor: bgColor === '#undefined' ? 'rgba(72,72,141, 0.06)' : bgColor,
    borderRadius: 13,

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',

    color: '#FFFFFF',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 10px',
    gap: '5px',

    height: '22px',
    maxWidth: isMax ? '110px' : '',

    '& span': {
        maxWidth: 121,
        display: 'inline',

        whiteSpace: 'nowrap', /* Отменяем перенос текста */
        overflow: 'hidden', /* Обрезаем содержимое */
        textOverflow: 'ellipsis',
    }
}))

const TaskCardStyled = styled("div", {name: 'task-card'})(({isDragging, draggableStyle, hidden}) => ({
    width: 336,

    opacity: hidden ? 0 : 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 10,
    gap: '4px',
    background: '#FFFFFF',
    borderRadius: '16px',

    /* Inside auto layout */

    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',

    marginBottom: 8,

    userSelect: "none",

    border: isDragging ? '1px solid #6066FF' : '',

    // styles we need to apply on draggables
    ...draggableStyle
}))

const TaskMomentumStyled = styled('div', {name: 'task-momentum'})(({color}) => ({

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',

    display: 'flex',
    alignItems: 'center',

    color: color ?? 'rgba(22, 22, 51, 0.54)',
}))

const StatusItem = styled('div', {name: 'status-item'})(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '2px',

    height: '16px',

    background: 'rgba(72, 72, 141, 0.06)',
    borderRadius: '10px',

    '& span': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '12px',

        color: 'rgba(22, 22, 51, 0.54)',
    }
}))

const TaskCard = ({content, index, columnsTasksId}) => {
    const mainTags = content?.tasks_tags?.slice(0, 2)
    const tags = content?.tasks_tags?.slice(2)

    return (
        <Draggable
            draggableId={`${columnsTasksId}|${content?.id}`}
            index={index}
        >
            {(provided, snapshot) => (
                <TaskCardStyled
                    id={content?.id}
                    hidden={content === null}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    draggableStyle={provided.draggableProps.style}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={'8px'}
                        sx={{width: '100%'}}
                    >
                        <IconSquare style={{
                            backgroundColor: content?.is_completed ? '#6066FF' : 'rgba(72, 72, 141, 0.06)',
                            borderColor: content?.is_completed ? 'rgba(22, 22, 51, 0.02)' : 'rgba(22, 22, 51, 0.02)',
                            color: content?.is_completed ? "#FFFFFF" : "rgba(22, 22, 51, 0.08)"
                        }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.9945 10.7294L3.65273 8.44238C3.38932 8.18512 2.96724 8.19012 2.70998 8.45353C2.45273 8.71694 2.45772 9.13902 2.72113 9.39627L5.53424 12.1436C5.79545 12.3987 6.21327 12.3962 6.47145 12.1381L13.4714 5.13807C13.7318 4.87772 13.7318 4.45561 13.4714 4.19526C13.2111 3.93491 12.789 3.93491 12.5286 4.19526L5.9945 10.7294Z"
                                      fill="currentColor"
                                />
                            </svg>
                        </IconSquare>
                        <CardBody>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                spacing={2}
                            >
                                <TaskTitle>{content?.title}</TaskTitle>
                                {
                                    content?.clients_tasks?.map((item, index) => {
                                        let client = item?.client
                                        return (
                                            <ContactRow key={index}>
                                                {
                                                    client?.clients_contacts?.map((contact, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <ContactAvatar
                                                                    alt={contact.name ?? client.number}
                                                                    src={''}
                                                                >
                                                                    {contact.name ?? client.number}
                                                                </ContactAvatar>
                                                                <span className={'text'}>{contact.name ?? client.number}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ContactRow>
                                        )
                                    })
                                }
                                <TagsRow>
                                    {
                                        mainTags?.slice(0, 2)?.map((tag) => {
                                            return (
                                                <TagItem bgColor={`#${tag?.tags_list?.color?.background}`} key={tag?.tags_list?.id} isMax={tags?.length > 2}>
                                                    <span style={{
                                                        color: tag?.tags_list?.color?.foreground === undefined ? 'rgba(22,22,51, 0.54)' : `#${tag?.tags_list?.color?.foreground}`
                                                    }}>{tag?.tags_list?.value}</span>
                                                </TagItem>
                                            )
                                        })
                                    }
                                    {
                                        tags?.length > 2 ? (
                                            <TagItem bgColor={'rgba(72, 72, 141, 0.06)'}>
                                                <span style={{color: 'rgba(22, 22, 51, 0.54)'}}>+{content?.tasks_tags?.length - 2}</span>
                                                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    {
                                                        tags?.map((tag, index) => {
                                                            if (index === 0) return (
                                                                <circle key={index} cx="3" cy="5" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                            if (index === 1) return (
                                                                <circle key={index} cx="3" cy="13" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                            if (index === 2) return (
                                                                <circle key={index} cx="11" cy="5" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                            if (index === 3) return (
                                                                <circle key={index} cx="11" cy="13" r="3" fill={`#${tag?.tags_list?.color?.background ?? 'cccccc'}`}/>);
                                                            return ''
                                                        })
                                                    }
                                                </svg>
                                            </TagItem>
                                        ) : ''
                                    }
                                </TagsRow>
                                <div style={{
                                    display: 'flex',
                                    float: 'left',
                                    gap: '4px',
                                    alignItems: 'center'
                                }}>
                                    {
                                        content?.reminder_date === null ?
                                            content?.due_date === null ? '' : (
                                                <>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                                              fill={moment(content?.due_date).isSameOrBefore(new Date()) ? '#FF1F62' : '#161633'}
                                                              fillOpacity={moment(content?.due_date).isSameOrBefore(new Date()) ? '' : '0.54'}
                                                        />
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M6 3C6.27614 3 6.5 3.22386 6.5 3.5V6H8.5C8.77614 6 9 6.22386 9 6.5C9 6.77614 8.77614 7 8.5 7H6.5C5.94772 7 5.5 6.55228 5.5 6V3.5C5.5 3.22386 5.72386 3 6 3Z"
                                                              fill={moment(content?.due_date).isSameOrBefore(new Date()) ? '#FF1F62' : '#161633'}
                                                              fillOpacity={moment(content?.due_date).isSameOrBefore(new Date()) ? '' : '0.54'}
                                                        />
                                                    </svg>
                                                    <TaskMomentumStyled color={moment(content?.due_date).isSameOrBefore(new Date()) ? '#FF1F62' : 'rgba(22, 22, 51, 0.54)'}>
                                                        {moment(content?.due_date).calendar()}
                                                    </TaskMomentumStyled>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_64_10189)">
                                                            <path fillRule="evenodd"
                                                                  clipRule="evenodd"
                                                                  d="M5.90766 0.5C3.98874 0.5 2.44334 2.07266 2.44334 4V5.87033C2.44334 6.53643 2.18493 7.17478 1.72552 7.64833C1.24482 8.14382 1.57962 9 2.29315 9H9.71545C10.4568 9 10.7686 8.08109 10.225 7.6142C9.68487 7.15022 9.37199 6.46789 9.37199 5.74754V4C9.37199 2.07266 7.82658 0.5 5.90766 0.5ZM3.44334 4C3.44334 2.61363 4.55227 1.5 5.90766 1.5C7.26305 1.5 8.37199 2.61363 8.37199 4V5.74754C8.37199 6.57954 8.6697 7.37799 9.20152 8H2.73707C3.19329 7.38943 3.44334 6.64236 3.44334 5.87033V4ZM5.5 10C5.22386 10 5 10.2239 5 10.5C5 10.7761 5.22386 11 5.5 11H6.5C6.77614 11 7 10.7761 7 10.5C7 10.2239 6.77614 10 6.5 10H5.5Z"
                                                                  fill={moment(content?.reminder_date).isSameOrBefore(new Date()) ? '#FF1F62' : '#161633'}
                                                                  fillOpacity={moment(content?.reminder_date).isSameOrBefore(new Date()) ? '' : '0.54'}
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_64_10189">
                                                                <rect width="12" height="12" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <TaskMomentumStyled color={moment(content?.reminder_date).isSameOrBefore(new Date()) ? '#FF1F62' : 'rgba(22, 22, 51, 0.54)'}>
                                                        {
                                                            moment(content?.reminder_date).calendar()
                                                        }
                                                    </TaskMomentumStyled>
                                                </>
                                            )
                                    }
                                    <div style={{
                                        marginRight: '0px',
                                        marginLeft: 'auto',
                                    }}/>
                                    {
                                        content?.tasks_statuses?.length > 0 ? (
                                            <StatusItem>
                                                {
                                                    content?.tasks_statuses?.slice(0, 2)?.map((item, index) => {
                                                        return (
                                                            <svg key={index} width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="3.5" cy="4" r="3.5" fill={`#${item?.statuses_option?.color?.background ?? 'cccccc'}`}/>
                                                            </svg>
                                                        )
                                                    })
                                                }
                                                {
                                                    content?.tasks_statuses?.length > 2 ? (
                                                        <span>{`+ ${content?.tasks_statuses?.slice(2)?.length}`}</span>
                                                    ) : ''
                                                }
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M7.5 1H2.5C1.67157 1 1 1.67157 1 2.5V7.5C1 8.32843 1.67157 9 2.5 9H7.5C8.32843 9 9 8.32843 9 7.5V2.5C9 1.67157 8.32843 1 7.5 1ZM2.5 0C1.11929 0 0 1.11929 0 2.5V7.5C0 8.88071 1.11929 10 2.5 10H7.5C8.88071 10 10 8.88071 10 7.5V2.5C10 1.11929 8.88071 0 7.5 0H2.5ZM2.5 5C2.5 5.27614 2.72386 5.5 3 5.5C3.27614 5.5 3.5 5.27614 3.5 5C3.5 4.72386 3.27614 4.5 3 4.5C2.72386 4.5 2.5 4.72386 2.5 5ZM3 3.5C2.17157 3.5 1.5 4.17157 1.5 5C1.5 5.82843 2.17157 6.5 3 6.5C3.82843 6.5 4.5 5.82843 4.5 5C4.5 4.17157 3.82843 3.5 3 3.5ZM5.5 4.5C5.22386 4.5 5 4.72386 5 5C5 5.27614 5.22386 5.5 5.5 5.5H7.75C8.02614 5.5 8.25 5.27614 8.25 5C8.25 4.72386 8.02614 4.5 7.75 4.5H5.5Z"
                                                          fill="#6066FF" fillOpacity="0.6"/>
                                                </svg>
                                            </StatusItem>
                                        ) : ''
                                    }
                                    {
                                        content?.has_comments ? (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M8.59256 7.8439L9.52564 8.52925V3.99225C9.52564 3.16639 8.87129 2.4969 8.0641 2.4969H3.4359C2.62871 2.4969 1.97436 3.16639 1.97436 3.99225V6.34855C1.97436 7.17441 2.62871 7.8439 3.4359 7.8439H8.59256ZM8.27903 8.8408L9.72871 9.90558C10.051 10.1423 10.5 9.90664 10.5 9.50067V3.99225C10.5 2.61582 9.40941 1.5 8.0641 1.5H3.4359C2.09059 1.5 1 2.61582 1 3.99225V6.34855C1 7.72499 2.09059 8.8408 3.4359 8.8408H8.27903Z"
                                                      fill="#6066FF" fillOpacity="0.6"/>
                                            </svg>
                                        ) : ''
                                    }
                                    {
                                        content?.description ? (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M7.5 2H5C3.89543 2 3 2.89543 3 4V8C3 9.10457 3.89543 10 5 10H7.5C8.60457 10 9.5 9.10457 9.5 8V4C9.5 2.89543 8.60457 2 7.5 2ZM5 1C3.34315 1 2 2.34315 2 4V8C2 9.65685 3.34315 11 5 11H7.5C9.15685 11 10.5 9.65685 10.5 8V4C10.5 2.34315 9.15685 1 7.5 1H5Z"
                                                      fill="#6066FF" fillOpacity="0.6"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M4 5C4 4.72386 4.22386 4.5 4.5 4.5H7.5C7.77614 4.5 8 4.72386 8 5C8 5.27614 7.77614 5.5 7.5 5.5H4.5C4.22386 5.5 4 5.27614 4 5Z"
                                                      fill="#6066FF" fillOpacity="0.6"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M4 7C4 6.72386 4.22386 6.5 4.5 6.5H6C6.27614 6.5 6.5 6.72386 6.5 7C6.5 7.27614 6.27614 7.5 6 7.5H4.5C4.22386 7.5 4 7.27614 4 7Z"
                                                      fill="#6066FF" fillOpacity="0.6"/>
                                            </svg>
                                        ) : ''
                                    }
                                    {/*<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path fillRule="evenodd" clipRule="evenodd"*/}
                                    {/*          d="M6.25 10.08C4.73122 10.08 3.5 8.89807 3.5 7.44004L3.5 3.72004C3.5 2.72593 4.33947 1.92004 5.375 1.92004C6.41053 1.92004 7.25 2.72593 7.25 3.72004L7.25 7.20004C7.25 7.73023 6.80228 8.16004 6.25 8.16004C5.69772 8.16004 5.25 7.73023 5.25 7.20004L5.25 3.84004C5.25 3.57494 5.02614 3.36004 4.75 3.36004C4.47386 3.36004 4.25 3.57494 4.25 3.84004L4.25 7.20004C4.25 8.26043 5.14543 9.12004 6.25 9.12004C7.35457 9.12004 8.25 8.26043 8.25 7.20004L8.25 3.72004C8.25 2.19573 6.96282 0.960039 5.375 0.960039C3.78718 0.960039 2.5 2.19573 2.5 3.72004L2.5 7.44004C2.5 9.42826 4.17893 11.04 6.25 11.04C8.32107 11.04 10 9.42826 10 7.44004L10 5.04004C10 4.77494 9.77614 4.56004 9.5 4.56004C9.22386 4.56004 9 4.77494 9 5.04004L9 7.44004C9 8.89807 7.76878 10.08 6.25 10.08Z"*/}
                                    {/*          fill="#6066FF" fillOpacity="0.6"/>*/}
                                    {/*</svg>*/}
                                    {
                                        content?.is_pinned ? (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_64_10213)">
                                                    <path d="M1.22694 10.8828L5 6L6.24997 7.24998L1.36721 11.0231C1.27437 11.0948 1.1552 10.9756 1.22694 10.8828Z"
                                                          fill="url(#paint0_linear_64_10213)"/>
                                                    <path d="M1.22694 10.8828L5 6L6.24997 7.24998L1.36721 11.0231C1.27437 11.0948 1.1552 10.9756 1.22694 10.8828Z"
                                                          fill="url(#paint1_linear_64_10213)"/>
                                                    <g filter="url(#filter0_f_64_10213)">
                                                        <path d="M1.32412 11.0597L10.4499 7.5498L18.45 10.0498L1.36704 11.2528C1.25245 11.2609 1.2169 11.101 1.32412 11.0597Z"
                                                              fill="url(#paint2_linear_64_10213)"/>
                                                    </g>
                                                    <path d="M1.32412 11.0597L10.4499 7.5498L18.45 10.0498L1.36704 11.2528C1.25245 11.2609 1.2169 11.101 1.32412 11.0597Z"
                                                          fill="url(#paint3_linear_64_10213)"/>
                                                    <path
                                                        d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664L7.12491 8.125L6.24991 7.25L6.24991 6.7571C6.24991 6.64605 6.22331 6.53281 6.17924 6.43087C6.11254 6.27655 5.195 5.80491 5.07612 5.92379L4.99991 6L4.12491 5.125L7.39327 1.85664L7.66149 0.809088C7.83395 0.540812 8.21011 0.500397 8.43563 0.725914L11.5626 3.85285Z"
                                                        fill="url(#paint4_linear_64_10213)"/>
                                                    <path
                                                        d="M8.0105 9.11011C8.31556 9.41517 8.83786 9.2166 8.86319 8.78592L9.0142 6.2188C7.74988 5.74992 6.24988 4.49992 6.06959 3.27418L3.50246 3.42519C3.07178 3.45053 2.87321 3.97282 3.17827 4.27788L8.0105 9.11011Z"
                                                        fill="url(#paint5_linear_64_10213)"/>
                                                    <path
                                                        d="M8.0105 9.11011C8.31556 9.41517 8.83786 9.2166 8.86319 8.78592L9.0142 6.2188C7.74988 5.74992 6.24988 4.49992 6.06959 3.27418L3.50246 3.42519C3.07178 3.45053 2.87321 3.97282 3.17827 4.27788L8.0105 9.11011Z"
                                                        fill="url(#paint6_linear_64_10213)"/>
                                                    <path
                                                        d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664C9.24991 3.85285 8.24991 3 7.39327 1.85664L7.66149 0.809088C7.83395 0.540811 8.21011 0.500396 8.43563 0.725914L11.5626 3.85285Z"
                                                        fill="url(#paint7_linear_64_10213)"/>
                                                    <path
                                                        d="M11.5626 3.85285C11.7881 4.07837 11.7477 4.45453 11.4794 4.62699L10.3933 4.85664C9.24991 3.85285 8.24991 3 7.39327 1.85664L7.66149 0.809088C7.83395 0.540811 8.21011 0.500396 8.43563 0.725914L11.5626 3.85285Z"
                                                        fill="url(#paint8_linear_64_10213)"/>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_f_64_10213" x="0.259766" y="6.5498" width="19.1902" height="5.70312" filterUnits="userSpaceOnUse"
                                                            colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                                        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_64_10213"/>
                                                    </filter>
                                                    <linearGradient id="paint0_linear_64_10213" x1="2.99997" y1="7.5" x2="4.49997" y2="9" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#616A80"/>
                                                        <stop offset="0.552083" stopColor="#C9D4F0"/>
                                                        <stop offset="1" stopColor="#AEBBDC"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_64_10213" x1="5.49997" y1="6.75" x2="4.24997" y2="7.75" gradientUnits="userSpaceOnUse">
                                                        <stop stopOpacity="0.37"/>
                                                        <stop offset="1" stopOpacity="0"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_64_10213" x1="0.699983" y1="10.7998" x2="11.1773" y2="11.4183" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#C5CAD7"/>
                                                        <stop offset="1" stopColor="#AEBBDC" stopOpacity="0"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_64_10213" x1="-1.80005" y1="11.7998" x2="3.93717" y2="11.8288" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#C5CAD7"/>
                                                        <stop offset="1" stopColor="#AEBBDC" stopOpacity="0"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint4_linear_64_10213" x1="9.73881" y1="6.384" x2="5.90449" y2="2.54967" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0885417" stopColor="#FF92B2"/>
                                                        <stop offset="0.3125" stopColor="#FF1F62"/>
                                                        <stop offset="0.604167" stopColor="#FF879D"/>
                                                        <stop offset="0.776042" stopColor="#FF1F62"/>
                                                        <stop offset="0.90625" stopColor="#FF7EA5"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint5_linear_64_10213" x1="8.74988" y1="9.24992" x2="4.24988" y2="2.24992" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0885417" stopColor="#FF92B2"/>
                                                        <stop offset="0.3125" stopColor="#FF1F62"/>
                                                        <stop offset="0.604167" stopColor="#FF879D"/>
                                                        <stop offset="0.776042" stopColor="#FF1F62"/>
                                                        <stop offset="0.937972" stopColor="#FF7EA5"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint6_linear_64_10213" x1="8.99988" y1="5.99992" x2="3.99988" y2="5.74992" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CE003E"/>
                                                        <stop offset="1" stopColor="#FF4779" stopOpacity="0"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint7_linear_64_10213" x1="9.73881" y1="6.384" x2="5.90449" y2="2.54967" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#FF92B2"/>
                                                        <stop offset="0.3125" stopColor="#FF1F62"/>
                                                        <stop offset="0.604167" stopColor="#FF879D"/>
                                                        <stop offset="0.776042" stopColor="#FF1F62"/>
                                                        <stop offset="1" stopColor="#FF7EA5"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint8_linear_64_10213" x1="8.99991" y1="3.75" x2="11.2499" y2="3" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#DB1551" stopOpacity="0.49"/>
                                                        <stop offset="1" stopColor="#FFB6C4" stopOpacity="0.22"/>
                                                    </linearGradient>
                                                    <clipPath id="clip0_64_10213">
                                                        <rect width="12" height="12" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        ) : ''
                                    }
                                    <Avatar alt={content?.profile?.name} src={content?.profile?.avatar_id} sx={{width: 24, height: 24, marginLeft: '4px'}}/>
                                </div>
                            </Stack>
                        </CardBody>
                    </Stack>
                </TaskCardStyled>
            )}
        </Draggable>
    )
}

export default TaskCard