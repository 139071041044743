import {useEffect} from "react";
import Stack from "@mui/material/Stack";

export const Start = ({dispatch}) => {

    useEffect(() => {
        setTimeout(() => {
            dispatch({type: 'start-off'})
        }, 1000)
    // eslint-disable-next-line
    }, [])

    return (
        <Stack direction="column"
               justifyContent="center"
               alignItems="center"
               spacing={0}
               id={'auth-Start'}
               sx={{
                   width: '100%',
                   position: 'absolute',
                   height: '100%',
               }}
        >
            <img width={242} height={229} src={process.env.PUBLIC_URL + '/static/images/logo.png'} alt={'logo'}/>
        </Stack>
    )
}