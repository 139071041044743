/* eslint-disable camelcase */
import enUS from "./resources/en-US.json"
import ruRU from "./resources/ru-RU.json"

export const resources = {
    'en-US': {
        tech: {langcode: 'en-US'},
        common: enUS
    },
    'ru-RU': {
        tech: {langcode: 'ru-RU'},
        common: ruRU
    },
};
