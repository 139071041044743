import {Avatar} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import {IconSquare} from "../../components/Base/IconComponents";
import Stack from "@mui/material/Stack";
import {useQuery} from "@apollo/client";
import {PROFILE_QUERY} from "../../components/GQL/Query/Profile";
import {useSelector} from "react-redux";
import {selectorProfileId} from "../../redux/auth/Reducer";
import {stringToColor} from "./groupList";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";

function stringAvatar(name) {
    return {
        sx: {
            backgroundColor: stringToColor(name),
            width: 28,
            height: 28,
            fontSize: '10px !important',
        },
        children: name.split(' ').length > 1 ? `${name.split(' ')[0][0] ?? name[0]}${name.split(' ')[1][0] ?? name[1]}` : `${name[0]}${name[1]}`,
    };
}

const PItem = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 12px 24px 14px',
    gap: '8px',

    position: 'absolute',
    bottom: '0px',
    width: '203px',

    cursor: 'pointer',
}))

export const ProfileItem = () => {
    const profileId = useSelector(selectorProfileId)
    const {data} = useQuery(PROFILE_QUERY, {variables: {profileId: profileId}})

    const navigate = useNavigate()

    const [name, setName] = useState('profile')
    const [avatar, setAvatar] = useState('')

    useEffect(() => {
        let profile = data?.profiles[0];
        if (profile?.avatar_id) {
            setAvatar(profile?.avatar_id)
        }
        if (profile?.name) {
            setName(profile?.name)
        } else if (profile?.number) {
            setName(`${(((profile?.number % 1000000) - (profile?.number % 1000))/1000).toString()} ${(profile?.number % 1000).toString()}`)
        }
    }, [data])


    if (data) {
        return (
            <PItem
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
                onClick={()=>{navigate('/profile/')}}
            >
                {
                    avatar ? (
                        <Avatar alt={name} src={avatar}/>
                    ) : (
                        <Avatar {...stringAvatar(name)}/>
                    )
                }
                Профиль
                <IconSquare size={28} style={{backgroundColor: 'rgba(72, 72, 141, 0.06)'}}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.7601 8.62667C12.7867 8.42667 12.8001 8.22001 12.8001 8.00001C12.8001 7.78667 12.7867 7.57334 12.7534 7.37334L14.1067 6.32001C14.2267 6.22667 14.2601 6.04667 14.1867 5.91334L12.9067 3.70001C12.8267 3.55334 12.6601 3.50667 12.5134 3.55334L10.9201 4.19334C10.5867 3.94001 10.2334 3.72667 9.84005 3.56667L9.60005 1.87334C9.57339 1.71334 9.44005 1.60001 9.28005 1.60001H6.72005C6.56005 1.60001 6.43339 1.71334 6.40672 1.87334L6.16672 3.56667C5.77339 3.72667 5.41339 3.94667 5.08672 4.19334L3.49339 3.55334C3.34672 3.50001 3.18005 3.55334 3.10005 3.70001L1.82672 5.91334C1.74672 6.05334 1.77339 6.22667 1.90672 6.32001L3.26005 7.37334C3.22672 7.57334 3.20005 7.79334 3.20005 8.00001C3.20005 8.20667 3.21339 8.42667 3.24672 8.62667L1.89339 9.68001C1.77339 9.77334 1.74005 9.95334 1.81339 10.0867L3.09339 12.3C3.17339 12.4467 3.34005 12.4933 3.48672 12.4467L5.08005 11.8067C5.41339 12.06 5.76672 12.2733 6.16005 12.4333L6.40005 14.1267C6.43339 14.2867 6.56005 14.4 6.72005 14.4H9.28005C9.44005 14.4 9.57339 14.2867 9.59339 14.1267L9.83339 12.4333C10.2267 12.2733 10.5867 12.06 10.9134 11.8067L12.5067 12.4467C12.6534 12.5 12.8201 12.4467 12.9001 12.3L14.1801 10.0867C14.2601 9.94001 14.2267 9.77334 14.1001 9.68001L12.7601 8.62667ZM8.00005 10.4C6.68005 10.4 5.60005 9.32001 5.60005 8.00001C5.60005 6.68001 6.68005 5.60001 8.00005 5.60001C9.32005 5.60001 10.4001 6.68001 10.4001 8.00001C10.4001 9.32001 9.32005 10.4 8.00005 10.4Z"
                            fill="#161633" fillOpacity="0.24"/>
                    </svg>
                </IconSquare>
            </PItem>
        )
    }
    return ('')
}
